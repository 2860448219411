import { gql } from "@apollo/client";
import {
  NCExtensionFormData,
  NCExtensionFormResult,
  NC_EXTENSION_DATA,
  TAX_FORM_METADATA_FRAGMENT,
} from "@taxscribe/gql-queries";

export const GET_FORM = gql`
  ${NC_EXTENSION_DATA}
  ${TAX_FORM_METADATA_FRAGMENT}
  query GetForm($formUuid: String!) {
    form(uuid: $formUuid) {
      ...TaxFormMetadataFragment
      data {
        formType
        ... on NCExtensionData {
          ...NCExtensionFormData
        }
      }
    }
  }
`;

export const GET_NEW_FORM_FOR_BUSINESS = gql`
  ${NC_EXTENSION_DATA}
  query GetNewFormForBusiness(
    $formType: NCFormType!
    $accountNumberPrimary: String!
    $jurisdictionId: Int!
  ) {
    ncptsFormData(
      formType: $formType
      accountNumberPrimary: $accountNumberPrimary
      jurisdictionId: $jurisdictionId
    ) {
      data {
        ... on NCExtensionData {
          ...NCExtensionFormData
        }
      }
    }
  }
`;

export interface NewFormForBusinessResults {
  ncptsFormData: {
    data: NCExtensionFormData;
  };
}

export interface FormQueryResults {
  form: NCExtensionFormResult;
}
