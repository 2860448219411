import { PrivateLogoImage } from "components/AppImages";
import Col from "react-bootstrap/Col";
import { useJurisdictionContext } from "@taxscribe/ui";

const CountyMessage = () => {
  const { fullSlug, sponsorsBppListing, name } = useJurisdictionContext();

  if (sponsorsBppListing === true) {
    return (
      <>
        <Col
          md={8}
          lg={5}
          className="justify-content-center d-flex flex-column align-items-center mb-3 mb-lg-0"
        >
          <div
            id="login-county"
            className="mt-4 mt-lg-0"
          >
            <PrivateLogoImage
              labelPath={fullSlug}
              width="200"
              height="auto"
            />
            <h2 className="text-center mb-4">Important Message</h2>
            <p className="mx-sm-5 mx-md-2 mx-lg-0">
              TaxScribe is {name}'s web portal for filing business personal
              property extension requests and listings. Online listing forms
              make the listing process easy and allow for multiple revisions or
              reviews prior to submission. Files can be readily attached to
              listing forms or as supplemental documentation. Data validation
              means fewer errors which expedites our review and acceptance.
              TaxScribe provides real time status, includes a dedicated message
              system and maintains a full history of your filing. TaxScribe
              supports all modern browsers.
            </p>
          </div>
        </Col>
        <Col
          lg={2}
          className="d-none d-lg-flex justify-content-center align-items-center"
        >
          <div className="border border-white border-2 h-50"></div>
        </Col>
      </>
    );
  }

  return null;
};

export default CountyMessage;
