"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ownerInfo = {
    type: "object",
    required: ["name", "address", "city", "zipCode", "stateAbbr"],
    properties: {
        name: {
            $ref: "#/$defs/nonEmptyString",
        },
        address: {
            $ref: "#/$defs/nonEmptyString",
        },
        city: {
            $ref: "#/$defs/nonEmptyString",
        },
        zipCode: {
            $ref: "#/$defs/zipCode",
        },
        stateAbbr: {
            $ref: "#/$defs/stateAbbr",
        },
    },
};
const tenantInfo = {
    type: "object",
    anyOf: [
        {
            required: [
                "name",
                "address",
                "city",
                "zipCode",
                "stateAbbr",
                "landlordOwned",
                "ownerInfo",
            ],
            properties: {
                landlordOwned: {
                    enum: [false],
                },
                name: {
                    $ref: "#/$defs/nonEmptyString",
                },
                address: {
                    $ref: "#/$defs/nonEmptyString",
                },
                city: {
                    $ref: "#/$defs/nonEmptyString",
                },
                zipCode: {
                    $ref: "#/$defs/zipCode",
                },
                stateAbbr: {
                    $ref: "#/$defs/stateAbbr",
                },
                ownerInfo,
            },
        },
        {
            required: [
                "name",
                "address",
                "city",
                "zipCode",
                "stateAbbr",
                "landlordOwned",
            ],
            properties: {
                landlordOwned: {
                    enum: [true],
                },
                name: {
                    $ref: "#/$defs/nonEmptyString",
                },
                address: {
                    $ref: "#/$defs/nonEmptyString",
                },
                city: {
                    $ref: "#/$defs/nonEmptyString",
                },
                zipCode: {
                    $ref: "#/$defs/zipCode",
                },
                stateAbbr: {
                    $ref: "#/$defs/stateAbbr",
                },
            },
        },
    ],
};
exports.default = tenantInfo;
