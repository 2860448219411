import React, { FC } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// NOTE: Main is about to become something else. Leaving it here until then so
// that it doesn't break the app for our QA users in the mean time.
import Home from "pages/StateLandingPage/StateSelectorLandingPage";
import VerifyPage from "pages/VerifyPage";
import TermsOfService from "components/TermsOfService";
import StateRoutes from "./StateRoutes";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/shared/ErrorBoundary";

const AppRoutes: FC = () => {
  return (
    <ErrorBoundary fallback={<ErrorFallback />}>
      <Routes>
        <Route
          path="/home"
          element={<Home />}
        />
        <Route
          path="/verify"
          element={<VerifyPage />}
        />
        <Route
          path="/terms-of-service"
          element={<TermsOfService />}
        />

        <Route
          path="/:stateAbbr/*"
          element={<StateRoutes />}
        />
        <Route
          path="/*"
          element={
            <Navigate
              replace
              to="/home"
            />
          }
        />
      </Routes>
    </ErrorBoundary>
  );
};

export default AppRoutes;
