"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resizeSvg = void 0;
const openingTagMatcher = /^<svg.*>/;
const resizeSvg = ({ svgString, maxHeight, maxWidth }) => {
    const openingTag = (svgString.match(openingTagMatcher) || [""])[0];
    if (!openingTag)
        return svgString;
    const newOpeningTag = openingTag
        .replace(/width="\d*"/, `width="${maxWidth}"`)
        .replace(/height="\d*"/, `height="${maxHeight}"`);
    return svgString.replace(openingTag, newOpeningTag);
};
exports.resizeSvg = resizeSvg;
