"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    type: "object",
    anyOf: [
        {
            required: ["leaseEquipment"],
            properties: {
                leaseEquipment: {
                    enum: [false, "no"],
                },
            },
        },
        {
            required: ["leaseEquipment", "j1Attachment"],
            properties: {
                leaseEquipment: {
                    enum: [true, "yes"],
                },
                j1Attachment: {
                    $ref: "#/$defs/attachment",
                },
            },
        },
        {
            required: ["leaseEquipment", "j1s"],
            properties: {
                leaseEquipment: {
                    enum: [true, "yes"],
                },
                j1s: {
                    type: "array",
                    minItems: 1,
                    items: {
                        type: "object",
                        properties: {
                            taxingDistrict: {
                                type: "string",
                            },
                            lesseeName: {
                                type: "string",
                            },
                            lesseeAddress: {
                                type: "string",
                            },
                            propertyAddress: {
                                type: "string",
                            },
                            assetNumber: {
                                type: "string",
                            },
                            leaseNumber: {
                                type: "string",
                            },
                            assessorAcctNumber: {
                                type: "string",
                            },
                            itemDescription: {
                                type: "string",
                            },
                            acquisitionYear: {
                                type: "string",
                                format: "year",
                            },
                            installedCost: {
                                type: ["string", "number"],
                                format: "numeric",
                            },
                            propertyClassification: {
                                type: "string",
                            },
                        },
                        // required: [
                        //   "taxingDistrict",
                        //   "lesseeName",
                        //   "lesseeAddress",
                        //   "propertyAddress",
                        //   "assetNumber",
                        //   "leaseNumber",
                        //   "assessorAcctNumber",
                        //   "itemDescription",
                        //   "acquisitionYear",
                        //   "installedCost",
                        //   "propertyClassification",
                        // ],
                    },
                },
            },
        },
    ],
};
