"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ippSample06_10_2023 = void 0;
exports.ippSample06_10_2023 = {
    results: [
        {
            AbstractNumber: "0008280082202320230000",
            Value: {
                OwnershipInformation: {
                    Owners: [
                        {
                            OwnerPk: "12005432",
                            COACode: "",
                            OwnerOrder: "PRIMARY",
                            OwnerOrderNCPTSCode: "OWORPRI",
                            OwnershipType: "INDIVIDUAL",
                            OwnershipTypeNCPTSCode: "OWTYIND",
                            PercentOwnership: "100",
                            SendMail: "Y",
                            Confidential: "False",
                            EligibleForTaxRelief: "False",
                            GroupNumber: "1",
                            OwnerNames: [
                                {
                                    IsDefaultName: "Y",
                                    IsNameOnAbstract: "Y",
                                    FirstName: "GENE",
                                    MiddleName: "FRANKLIN",
                                    LastName: "GAGE",
                                    Suffix: "JR",
                                    Additional: "",
                                },
                            ],
                            OwnerAddresses: [
                                {
                                    IsDefaultAddress: "Y",
                                    IsAddressOnAbstract: "Y",
                                    InCareOf: "",
                                    AddressLine1: "8515 SHADETREE ST",
                                    AddressLine2: "",
                                    AddressLine3: "",
                                    City: "HUNTERSVILLE",
                                    StateNCPTSCode: "STATNC",
                                    State: "NC",
                                    PostalCode: "28078",
                                    PostalCodeExtension: "",
                                    CountyNCPTSLookupCode: "",
                                    County: "",
                                },
                            ],
                            OwnerPhoneNumbers: [
                                {
                                    IsDefaultPhone: "Y",
                                    AreaCode: "619",
                                    Phone1: "818",
                                    Phone2: "4243",
                                    Extension: "",
                                    TypeNCPTSLookupCode: "PHTYEPAY",
                                    Type: "E-PAYER",
                                },
                            ],
                        },
                    ],
                },
                SitusInformation: {
                    IsDetailed: "Y",
                    SitusPk: "566682",
                    City: "HUNTERSVILLE",
                    CityNCPTSCode: "JURSHUNT",
                    State: "NC",
                    StateNCPTSCode: "STATNC",
                    PostalCode: "28078",
                    PostalCodeExtension: "",
                    County: "MECKLENBURG",
                    CountyNCPTSCode: "JURSMECK",
                    Address1: "8515 SHADETREE STRT",
                    Address2: "",
                    Address3: "",
                    TaxDistricts: [
                        {
                            TaxPercentage: "100",
                            CountyNCPTSLookupCode: "JURSMECK",
                            County: "MECKLENBURG",
                        },
                        {
                            TaxPercentage: "100",
                            CountyNCPTSLookupCode: "JURSHUNT",
                            County: "HUNTERSVILLE",
                        },
                    ],
                },
                AbstractInfo: {
                    ListingDate: "1/11/2023 12:00:00 AM",
                    ExtensionRequestDate: "",
                    ExtensionDate: "",
                    Status: "ABSSBILLED",
                },
                ContactInfo: {
                    ContactName: {
                        BusinessName: "GENE GAGE ACCOUNTING",
                        BusinessTypeNCPTSLookupCode: "",
                        FirstName: "GENE",
                        MiddleName: "FRANKLIN",
                        LastName: "GAGE",
                        Suffix: "JR",
                        Additional: "",
                    },
                    ContactAddress: {
                        InCareOf: null,
                        AddressLine1: null,
                        AddressLine2: null,
                        AddressLine3: null,
                        City: null,
                        StateNCPTSCode: null,
                        State: null,
                        PostalCode: null,
                        PostalCodeExtension: null,
                        CountyNCPTSLookupCode: null,
                        County: null,
                    },
                    ContactEmail: "genegagejr@yahoo.com",
                    ContactPhone: {
                        AreaCode: "619",
                        Phone1: "818",
                        Phone2: "4243",
                        Extension: "",
                        TypeNCPTSLookupCode: "PHTYCEL",
                        Type: "CELLULAR",
                    },
                },
                PropertyInfo: {
                    UnregisteredVehicles: [
                        {
                            Model: "",
                            Make: "",
                            ModelYear: "",
                            Body: "",
                            VIN: "",
                            PropertyPk: "123123123",
                            Length: null,
                            Width: null,
                            YearAcquired: "",
                            AcquisitionCost: "",
                        },
                    ],
                    PermanentPlateVehicles: [
                        {
                            TitleNumber: "*********457052",
                            LicensePlateNumber: "DA28289",
                            RegistrationIssueDate: "05/25/2022",
                            Model: "",
                            Body: "TL-Trailer",
                            VIN: "***********000680",
                            Length: null,
                            Width: null,
                            PropertyPk: "18477195",
                            YearAcquired: "2022",
                            AcquisitionCost: "7995",
                            ModelYear: "2022",
                            Make: "patriot",
                        },
                    ],
                    VehicleValidValues: {
                        BodyTypes: [
                            null,
                            "2D-2 Door",
                            "2H-2 Door Hardtop",
                            "2S-2 Door Sedan",
                            "2T-2 Door Hardtop",
                            "3D-3 Door",
                            "3S-3 Door Sedan (HthBk)",
                            "4D-4 Door",
                            "4H-4 Door Hardtop",
                            "4S-4 Door Sedan",
                            "4T-4 Door Hardtop",
                            "5S-5 Door Sedan (HthBk)",
                            "AM-Ambulance",
                            "AT-Horse/Cattle Trailer",
                            "BC-Bottle Carrier",
                            "BT-Boat Trailer",
                            "BU-Bus",
                            "CM-Concrete Mixer",
                            "CN-Convertible",
                            "CP-Coupe",
                            "CT-Camping Trailer",
                            "DP-Dump Body",
                            "GC-Golf Cart",
                            "HB-House Boat",
                            "HC-House Car",
                            "HR-Hearse",
                            "JP-Jeep",
                            "LG-Lift Gate",
                            "LM-Limousine",
                            "LT-Light Truck",
                            "MC-Motorcycle",
                            "MH-Motor Home",
                            "MP-Personal Utility Veh",
                            "NA-No Added Value",
                            "OT-Other",
                            "PG-Plate Glass",
                            "PT-Propane Tanker",
                            "RD-Roadster",
                            "RF-Refrigerated",
                            "RV-Recreational Vehicle",
                            "SD-Sedan",
                            "SF-Stake/Flat",
                            "SP-SP Mobile Equp",
                            "SW-Station Wagon",
                            "TC-Trash Compactor",
                            "TD-Tandem Dump",
                            "TG-Touring Car",
                            "TK-Truck",
                            "TL-Trailer",
                            "TN-Tanker",
                            "TR-Truck Tractor",
                            "TT-Travel Trailer",
                            "UK-Unknown",
                            "UL-Utility Trailer",
                            "UT-Utility Trailer",
                            "VN-Van",
                            "WC-Wheel Chair",
                            "WK-Wrecker",
                            "WL-Wrecker Large",
                            "WM-Wrecker Mini",
                            "WT-Wrecker Tilt",
                        ],
                    },
                    Watercraft: [
                        {
                            Model: "",
                            Make: "",
                            Marina: "",
                            ModelYear: "",
                            Feet: "",
                            Inches: "",
                            FuelCode: "",
                            ModelCode: "",
                            PropulsionCode: "",
                            PrimaryUseCode: "",
                            SlipNumber: "",
                            SlipNumberExtension: "",
                            MotorMake1: "",
                            MotorMake2: "",
                            MotorHP1: "",
                            MotorHP2: "",
                            MotorSerialNum1: "",
                            MotorSerialNum2: "",
                            YearAcquired: "",
                            AcquisitionCost: "",
                            PropertyPk: "",
                        },
                    ],
                    WatercraftValidValues: {
                        ModelTypes: [
                            null,
                            "CB-Cabin",
                            "HB-House Boat",
                            "OP-Open",
                            "OT-Other",
                            "PT-Pontoon",
                            "PW-Personal Watercraft",
                        ],
                        PrimaryUseCodes: [
                            null,
                            "CF-Commercial Fishing",
                            "CP-Commercial Passenger",
                            "DM-Demonstration",
                            "LI-Livery",
                            "OC-Other Commercial",
                            "OT-Other",
                            "PL-Pleasure",
                        ],
                        FuelCodes: [
                            null,
                            "DSL-Diesel",
                            "ELC-Electric",
                            "GAS-Gas",
                            "OTH-Other",
                        ],
                        PropulsionCodes: [
                            null,
                            "IB-Inboard",
                            "IO-Inboard/Outboard",
                            "JS-Jetdrive Engine",
                            "OB-Outboard",
                            "OP-Outboard Package",
                            "OT-Other",
                            "SB-Sailboat Only",
                            "SI-Aux Sail Inboard",
                            "SO-Aux Sail Outboard",
                        ],
                        Marinas: [
                            null,
                            "2211 : MA01 - JOYNER'S MARINA",
                            "2212 : MA02 - CHARLOTTE YACHT CLUB",
                            "2213 : MA03 - HOLIDAY HARBOR MARINA",
                            "2214 : MA04 - SAFE HARBOR POINT",
                            "2215 : MA05 - LONG COVE MARINA",
                            "2216 : MA06 - THE PENINSULA YACHT CLUB",
                            "2217 : MA07 - TERRYS MARINA",
                            "2218 : MA08 - NORTH HARBOR",
                            "2219 : MA09 - SOUTH HARBOR",
                            "2220 : MA10 - VINEYARD POINT HARBR",
                            "2221 : MA11 - ADMIRAL QUARTERS MARINA DBA COMMODORE'S LANDING",
                            "2222 : MA12 - 100 NORMAN PLACE",
                            "2223 : MA13 - EMERALD POINT CONDOMINIUMS",
                            "2224 : MA14 - MARINER VILLAS BOAT DOCK",
                            "2225 : MA16 - STONE HARBOR INC",
                            "2226 : MA17 - BLUE STONE HARBOR MARINA",
                            "2227 : MA18 - HARBORGATE YACHT CLUB",
                            "2228 : MA19 - YACHTSMAN LLC (THE)",
                            "2229 : MA20 - SAILPOINTE AT LAKE NORMAN",
                            "2230 : MA21 - PENINSULA ISLAND MARINA",
                            "2231 : MA22 - WATERSTREET SEAPORT HARBOR",
                            "2232 : MA23 - RIVERPOINTE MARINA",
                            "2233 : MA24 - WHER-RENA BOATLAND",
                            "2234 : MA25 - CROWN HARBOR BOAT SLIPS",
                            "2235 : MA26 - CATAWBA YACHT CLUB INC",
                            "2236 : MA27 - MT ISLAND LAKE MARINA/OVERLOOK OWNER'S ASSOC. (CO)",
                            "2316 : MA27 - MT ISLAND LAKE MARINA/OVERLOOK OWNER'S ASSOC. (CO)",
                            "2237 : MA28 - MT ISLAND LAKE MARINA/OVERLOOK  DRY STORAGE",
                            "2238 : MA29 - LANDCRAFT",
                            "2239 : MA30 - SAIL POINT BOATSLIP ASSOCIATION",
                            "2240 : MA31 - CROWN HARBOR DRY BOAT DOCK STORAGE",
                            "2304 : MA32-UNKNOWN LOCATION-RESEARCH",
                            "2319 : MA33- VINYARDS MARINA - LAKE WYLIE",
                            "2288 : ST69 - U HAUL STORAGE BALLANTYNE",
                            "2289 : ST70 - PUBLIC STORAGE",
                            "2290 : ST71 - U HAUL STORAGE SAFE HARBOR",
                            "2291 : ST72 - U HAUL CHARLOTTE",
                            "2292 : ST73 - EXTRA SPACE STORAGE #7966",
                            "2293 : ST74 - HARBOR MINI STORAGE",
                            "2294 : ST75 - LAKESIDE MINI STORAGE",
                            "2301 : ST76-MORNINGSTAR",
                            "2305 : ST77- CUBESMART STORE 0408",
                            "2306 : ST78- LAKE NORMAN STORAGE(LOC 2- H M JUNKER DR)",
                            "2307 : ST79- LAKE NORMAN STORAGE (LOC. 1/OFFICE)",
                            "2308 : ST80- SAFENEST STORAGE - METROLINA",
                            "2309 : ST81-WEST HUNTERSVILLE STORAGE CENTER",
                            "2310 : ST82- STORAGE CENTRAL",
                            "2311 : ST83- HUNTERSVILLE MINI STORAGE (STATESVILLE RD)",
                            "2312 : ST84- UHAUL HUNTERSVILLE MINISTORAGE (HUNTERS RD)",
                            "2314 : ST85- MORNINGSTAR STORAGE",
                            "2317 : ST86- CUBESMART - PSI ATLANTIC PINEVILLE NC LLC",
                            "2318 : ST87 - SAFENEST STORAGE - HUNTERSVILLE",
                        ],
                    },
                    ManufacturedHomes: [
                        {
                            Model: "",
                            Make: "",
                            ModelYear: "",
                            ParcelID: "",
                            PlateNumber: "",
                            Park: "",
                            LotNumber: "",
                            TitleNumber: "",
                            Occupant: "",
                            TitleName1: "",
                            TitleName2: "",
                            MovingPermitNumber: "",
                            MovingPermitIssueDate: "",
                            BuildingPermitNumber: "",
                            BuildingPermitIssueDate: "",
                            Body: "",
                            VIN: "",
                            Length: "",
                            Width: "",
                            YearAcquired: "",
                            AcquisitionCost: "",
                            PropertyPK: "",
                        },
                    ],
                    ManufacturedHomeValidValues: {
                        Parks: [
                            null,
                            "2241 : MH01 - BAUCOM'S MOBILE HOME PARK- MELYNDA RD",
                            "2242 : MH05 - COUNTRYSIDE MH PARK",
                            "2243 : MH06 - WEST BLOOMFIELD ACRES",
                            "2244 : MH07 - ELMORE MOBILE HM PK",
                            "2246 : MH10 - INTERSTATE MOBILE HOME PARK",
                            "2247 : MH11 - CHARLOTTE HILLS MOBILE HOME PARK",
                            "2248 : MH12 - SHADY GROVE MOBILE HOME PARK",
                            "2249 : MH13 - METTS MOBILE HM PARK",
                            "2250 : MH14 - NORTH TRYON MOBILE HOME PARK",
                            "2251 : MH16 - PECAN GROVE MHP LLC",
                            "2252 : MH18 - KINGSWOOD MOBILE HOME PARK",
                            "2253 : MH19 - QUEENS GRANT MOBILE HOME PARK",
                            "2254 : MH21 - ALPINE VILLAGE MH PK",
                            "2255 : MH23 - MARTIN MHP RENTALS",
                            "2256 : MH26 - ARROWOOD MH PARK",
                            "2258 : MH28 - MOUNTAIN ISLAND PARK JOINT VENTURE",
                            "2259 : MH29 - CHARLOTTE VILLAGE MOBILE HP",
                            "2260 : MH30 - BELHAVEN ESTATES",
                            "2261 : MH32 - BERRYHILL MH PARK",
                            "2262 : MH34 - CARRAWAY MH PARK",
                            "2263 : MH36- MAGNOLIA ESTATES MOBILE HOME PARK (FKA COOKS",
                            "2264 : MH38 - CRISP MOBILE HM PARK",
                            "2265 : MH39 - REEDY CREEK MHP",
                            "2266 : MH40 - RIVENDELL MOBILE HOME PARK",
                            "2267 : MH41 - DRIFTWOOD MH PARK",
                            "2268 : MH43 - FOREST PARK MH PARK",
                            "2269 : MH45 - GLADDEN'S MH PARK",
                            "2270 : MH46 -PARSONS MHP",
                            "2271 : MH47 - HOLIDAY MOBILE HM PK",
                            "2272 : MH48 - HUNT LYNN MH PARK",
                            "2273 : MH50 - CICI & POULOS LLC - SHOWSTAR PROPERTIES",
                            "2274 : MH52 - WOODWARD VILLAGE MHP",
                            "2275 : MH53 - RUSSELL'S MOBILE HOME PARK",
                            "2276 : MH54 - MEADOWBROOK MOBILE HOME PARK",
                            "2277 : MH55 - ALBEMARLE RD MHP",
                            "2278 : MH56 - OAKHAVEN MH PARK",
                            "2279 : MH58 - RHYNE ROAD MH PARK",
                            "2280 : MH60 - SUNSET GROVE MH PARK",
                            "2281 : MH61 - FIELDRIDGE ACRES",
                            "2282 : MH63 - WESTWOOD MH PARK",
                            "2283 : MH64 - WILGROVE MH PARK",
                            "2284 : MH65 - RIVERS EDGE MH PARK",
                            "2285 : MH66 - KEYS MOBILE HM PARK",
                            "2286 : MH67 - RED FEZ SHRINE CLUB",
                            "2287 : MH68 -OAKLAND HILLS MHP",
                            "2295 : MH70- OAKGROVE MOBILE HOME PARK",
                            "2296 : MH71-COUNTRYWOODS MOBILE HOME PARK",
                            "2297 : MH72 - METROLINA NORTH",
                            "2298 : MH73-TA-HA MOBILE HOME PARK",
                            "2299 : MH74 - HAVELOCK MHP LL",
                            "2303 : MH75-UNKNOWN LOCATION-RESEARCH",
                            "2313 : MH76- MINERAL SPRINGS PARK",
                            "2315 : MH77- CATAWBA MOBILE HOME PARK",
                            "2320 : MH78 - LONG COVE RESORT",
                            "2321 : MH79 - NIXON PAT MHP",
                            "2322 : MH79 - NIXON PAT MHP",
                        ],
                        BodyTypes: [
                            null,
                            "CT-Camping Trailer",
                            "HC-House Car",
                            "LT-Light Truck",
                            "MH-Motor Home",
                            "OT-Other",
                            "TT-Travel Trailer",
                        ],
                    },
                    Aircraft: [
                        {
                            Model: "",
                            Make: "",
                            ModelYear: "",
                            TailNumber: "",
                            Airport: "",
                            HangarNumber: "",
                            Body: "",
                            YearAcquired: "",
                            AcquisitionCost: "",
                            PropertyPk: "",
                        },
                    ],
                    AircraftValidValues: {
                        Airports: [
                            null,
                            "2208 : AP01 - WILSON AIR CENTER",
                            "2209 : AP02 - BRADFORD AIRPORT",
                            "2210 : AP03 - HOME SITUS",
                            "2302 : AP04 - CITY OF CHARLOTTE",
                        ],
                        BodyTypes: [
                            null,
                            "2D-2 Door",
                            "4D-4 Door",
                            "AC-Airplane",
                            "EX-Experimental",
                            "GL-Glider",
                            "HB-House Boat",
                            "HE-Helicopter",
                            "NA-No Added Value",
                            "OT-Other",
                            "PG-Plate Glass",
                            "PT-Propane Tanker",
                            "RF-Refrigerated",
                            "SF-Stake/Flat",
                            "UY-UY",
                        ],
                    },
                    Misc: [],
                },
            },
        },
    ],
};
