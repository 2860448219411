import { merge } from "lodash";
import { gql } from "@apollo/client";
import { MIExemption, MIExemptionData } from "@taxscribe/form";
import { cleanFormData, deepPurgeValues } from "@taxscribe/utils";

import fetchPrePopData from "utils/fetchPrePopData";
import createFormPage, { FormDataInjector } from "components/shared/createFormPage";
import createFormView from "components/shared/createFormView";

const CREATE_FORM = gql`
  mutation CreateMIExemption(
    $source: String!
    $formData: MIExemptionDataInput!
    $businessName: String
    $jurisdiction: String!
  ) {
    createMIExemption(
      source: $source
      formData: $formData
      businessName: $businessName
      jurisdiction: $jurisdiction
    )
  }
`;

const formType = "Exemption";
const ExemptionView = createFormView({
  Form: MIExemption,
  formType,
  noPdf: true,
  formName: "MIExemption",
  header: "MI Exemption",
  creationQuery: CREATE_FORM,
});

const injectFormData: FormDataInjector = ({
  formData,
  formUuid,
  currentSubmission,
}) => {
  if (!formData) return formData;
  if (!currentSubmission?.id) {
    const extraState: Record<string, any> = {};
    return deepPurgeValues(null, merge(cleanFormData(formData), extraState));
  }

  return deepPurgeValues(null, cleanFormData(formData));
};

const MIExemptionPage = createFormPage({
  formType,
  injectFormData,
  ViewForm: ExemptionView,
  prePopForm: fetchPrePopData<MIExemptionData>(formType),
});

export default MIExemptionPage;
