"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    type: "object",
    anyOf: [
        {
            // If _all_ are false, nothing else is required
            properties: {
                ownsLeasedProperty: {
                    enum: [false, "no"],
                },
                usesOthersProperty: {
                    enum: [false, "no"],
                },
                operatesAsFacility: {
                    enum: [false, "no"],
                },
            },
            required: [
                "ownsLeasedProperty",
                "usesOthersProperty",
                "operatesAsFacility",
            ],
        },
        {
            properties: {
                ownsLeasedProperty: {
                    type: ["string", "boolean"],
                    format: "conditional",
                },
                usesOthersProperty: {
                    type: ["string", "boolean"],
                    format: "conditional",
                },
                operatesAsFacility: {
                    type: ["string", "boolean"],
                    format: "conditional",
                },
                c1Attachment: {
                    $ref: "#/$defs/attachment",
                },
            },
            required: [
                "c1Attachment",
                "ownsLeasedProperty",
                "usesOthersProperty",
                "operatesAsFacility",
            ],
        },
        {
            properties: {
                ownsLeasedProperty: {
                    type: ["string", "boolean"],
                    format: "conditional",
                },
                usesOthersProperty: {
                    type: ["string", "boolean"],
                    format: "conditional",
                },
                operatesAsFacility: {
                    type: ["string", "boolean"],
                    format: "conditional",
                },
                c1s: {
                    type: "array",
                    minItems: 1,
                    items: {
                        type: ["null", "object"],
                        properties: {
                            ownerName: {
                                type: "string",
                            },
                            ownerAddress: {
                                type: "string",
                            },
                            description: {
                                type: "string",
                            },
                            leaseDate: {
                                type: "string",
                                format: "optionalCalendar",
                            },
                            monthlyPayment: {
                                type: ["number", "string"],
                            },
                            lengthOfLease: {
                                type: ["number", "string"],
                            },
                            accountOrLeaseNumber: {
                                type: "string",
                            },
                            sellingPriceNew: {
                                type: "number",
                            },
                        },
                    },
                },
            },
            required: [
                "c1s",
                "ownsLeasedProperty",
                "usesOthersProperty",
                "operatesAsFacility",
            ],
        },
    ],
};
