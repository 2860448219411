"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const commonFields = {
    firstName: {
        type: "string",
    },
    lastName: {
        type: "string",
    },
    suffix: {
        type: "string",
    },
    additionalName: {
        type: "string",
    },
    businessName: {
        type: "string",
    },
};
const withEmail = {
    required: [
        "email",
        "firstName",
        "lastName",
    ],
    properties: Object.assign(Object.assign({}, commonFields), { firstName: {
            type: "string",
            minLength: 1,
        }, lastName: {
            type: "string",
            minLength: 1,
        }, email: {
            type: "string",
            format: "email",
        } }),
};
const withPhoneNumber = {
    required: [
        "phoneNumber",
        "firstName",
        "lastName",
    ],
    properties: Object.assign(Object.assign({}, commonFields), { firstName: {
            type: "string",
            minLength: 1,
        }, lastName: {
            type: "string",
            minLength: 1,
        }, phoneType: {
            type: "string",
        }, phoneNumber: {
            type: "string",
            format: "phone",
        } }),
};
const withoutEmailOrPhoneNumber = {
    properties: Object.assign(Object.assign({}, commonFields), { phoneNumber: {
            enum: [null, ""],
        }, email: {
            enum: [null, ""],
        } }),
};
const contactInformation = {
    type: "object",
    anyOf: [
        withoutEmailOrPhoneNumber,
        withEmail,
        withPhoneNumber,
    ],
};
exports.default = contactInformation;
