"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const purchasedAircraft = {
    if: {
        properties: {
            purchasedAircraft: {
                const: true,
            },
        },
    },
    then: {
        required: ["seller"],
        properties: {
            seller: {
                type: "object",
                required: ["name", "street", "city", "state", "zipCode"],
                properties: {
                    name: {
                        $ref: "#/$defs/nonEmptyString",
                    },
                    street: {
                        $ref: "#/$defs/nonEmptyString",
                    },
                    city: {
                        $ref: "#/$defs/nonEmptyString",
                    },
                    state: {
                        $ref: "#/$defs/nonEmptyString",
                    },
                    zipCode: {
                        type: "string",
                        format: "zipCode",
                    },
                },
            },
        },
    },
};
exports.default = purchasedAircraft;
