/* eslint-disable max-len */
import React, { FC } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import NoJurisFound from "../images/no-juris-found.png";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "components/Footer";
import Navigation from "components/Navigation";
import { useJurisdictionContext } from "@taxscribe/ui";

const JurisdictionNotFound: FC = () => {
  const { jurisdictionType, jurisdictionName, stateAbbr } =
    useJurisdictionContext();
  const details = "Please check for any spelling errors and try again.";
  const currentUrl = window.location.href;

  return (
    <div id="wrapper">
      <Navigation />
      <Container
        className="my-5"
        id="juris-not-found"
      >
        <Row className="justify-content-center">
          <Col
            lg={9}
            className="text-center p-4"
          >
            <Image
              src={NoJurisFound}
              width="150"
              height="auto"
              className="mb-2 mb-md-4"
            />
            <h1 className="display-6 mb-4">Unable To Identify Jurisdiction</h1>
            <p className="text-muted">
              Unable to find{" "}
              <span className="fw-semibold">
                {" "}
                {jurisdictionName} {jurisdictionType}{" "}
              </span>
              for{" "}
              <span className="fw-semibold">
                {stateAbbr.toLocaleUpperCase()}
              </span>
              . {details}
              <br />
              <small>Current Location: {currentUrl}</small>
            </p>
            <hr />
            <div>
              <Link
                to={`/${stateAbbr}`}
                className="align-self-center text-nowrap"
              >
                <Button variant="info">
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    aria-hidden="true"
                    className="text-dark pe-2"
                  />
                  Select Jurisdiction
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default JurisdictionNotFound;
