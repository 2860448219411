"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const groupItem = {
    type: "object",
    properties: {
        yearAcquired: {
            type: "string",
            format: "optionalYear",
        },
        previouslyReportedCost: {
            type: "number",
        },
        additionsOrTransfersIn: {
            type: "number",
        },
        disposalsOrTransfersOut: {
            type: "number",
        },
        compConvFactor: {
            type: "number",
        },
    },
};
const scheduleA = {
    type: "object",
    required: ["ownedEquipment"],
    allOf: [
        {
            properties: {
                ownedEquipment: {
                    type: "boolean",
                },
            },
        },
        {
            if: {
                properties: {
                    ownedEquipment: {
                        const: true,
                    },
                },
            },
            then: {
                properties: {
                    group1: {
                        type: "array",
                        items: groupItem,
                        minItems: 1,
                    },
                    group2: {
                        type: "array",
                        items: groupItem,
                        minItems: 1,
                    },
                    group3: {
                        type: "array",
                        items: groupItem,
                        minItems: 1,
                    },
                    group4: {
                        type: "array",
                        items: groupItem,
                        minItems: 1,
                    },
                },
            },
        },
    ],
};
exports.default = scheduleA;
