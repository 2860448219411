"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable max-len */
const sectionN = {
    type: "object",
    anyOf: [
        {
            required: ["alreadyAssessed"],
            properties: {
                alreadyAssessed: {
                    enum: [true],
                },
            },
        },
        {
            required: ["towersAttachment"],
            properties: {
                towersAttachment: {
                    $ref: "#/$defs/attachment",
                },
            },
        },
        {
            required: ["structures"],
            properties: {
                structures: {
                    type: "array",
                    items: {
                        type: "object",
                        default: {},
                        required: [
                            "city",
                            "address",
                            "zipCode",
                            "yearBuilt",
                            "totalCapitalizedCost",
                        ],
                        properties: {
                            address: {
                                $ref: "#/$defs/nonEmptyString",
                            },
                            city: {
                                $ref: "#/$defs/nonEmptyString",
                            },
                            zipCode: {
                                $ref: "#/$defs/zipCode",
                            },
                            stateAbbr: {
                                type: "string",
                                format: "state",
                            },
                            yearBuilt: {
                                type: "string",
                                format: "year",
                            },
                            totalCapitalizedCost: {
                                minimum: 1,
                                type: "integer",
                            },
                        },
                    },
                },
            },
        },
    ],
};
exports.default = sectionN;
