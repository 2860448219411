"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.alterUrlPathTail = void 0;
/*
 * Alters the tail of a URL path.
 */
const alterUrlPathTail = (url, newPathTail) => [
    url.split(/\/[^/]*$/).slice(0, -1),
    newPathTail,
].join("/");
exports.alterUrlPathTail = alterUrlPathTail;
