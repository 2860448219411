import React, { FC, useMemo } from "react";
import { Col } from "react-bootstrap";
import { gql, useQuery } from "@apollo/client";

import config from "config/hostnames";
import JurisCard from "components/JurisCard";

const GET_EFILE_COUNTIES = gql`
  query GetEfileCounties($stateAbbr: String!) {
    state(stateAbbr: $stateAbbr) {
      abbr
      name
      jurisdictions(hasEfile: true, jurisdictionType: "County") {
        name
      }
    }
  }
`;

interface GetEfileCoutiesReturn {
  state: {
    abbr: string;
    name: string;
    jurisdictions: {
      name: string;
    }[];
  };
}

interface JurisCardListProps {
  stateAbbr: string;
}

const JurisCardList: FC<JurisCardListProps> = ({ stateAbbr }) => {
  const { data, loading } = useQuery<GetEfileCoutiesReturn>(
    GET_EFILE_COUNTIES,
    { variables: { stateAbbr } },
  );

  const counties = useMemo(() => {
    const efileCounties =
      data?.state.jurisdictions.map((jur) => jur.name) || [];

    if (config.releaseStage === "production") {
      return efileCounties.filter((county) => county !== "Demo");
    }

    return efileCounties;
  }, [data]);

  if (loading) return null;

  return (
    <>
      {counties.map((county) => (
        <Col key={county}>
          <JurisCard
            stateAbbr={stateAbbr}
            county={county}
          />
        </Col>
      ))}
    </>
  );
};

export default JurisCardList;
