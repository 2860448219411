import { gql } from "@apollo/client";
import { PT50A } from "@taxscribe/form";
import { fetchPrePopData } from "@taxscribe/ui";
import { cleanFormData, deepPurgeValues } from "@taxscribe/utils";
import createFormPage, {
  FormDataInjector,
} from "components/shared/createFormPage";
import createFormView from "components/shared/createFormView";
import { getAuth } from "firebase/auth";
import { merge } from "lodash";

const CREATE_FORM = gql`
  mutation CreatePT50A(
    $app: String!
    $businessName: String
    $source: String!
    $jurisdiction: String!
    $formData: PT50ADataInput!
  ) {
    createPT50A(
      app: $app
      source: $source
      formData: $formData
      businessName: $businessName
      jurisdiction: $jurisdiction
    )
  }
`;

const formType = "PT50A";
const PT50AReport = createFormView({
  Form: PT50A,
  formType,
  formName: "PT50A",
  header: "GA PT50A",
  creationQuery: CREATE_FORM,
});

const injectFormData: FormDataInjector = ({
  formData,
  formUuid,
  currentSubmission,
}) => {
  if (!formData) return formData;
  if (!currentSubmission?.id) {
    const user = getAuth().currentUser;
    const email = user?.email;
    const extraState: Record<string, any> = {
      pt50a: { affirmation: { preparerEmail: email || undefined } },
    };
    return deepPurgeValues(null, merge(cleanFormData(formData), extraState));
  }

  return deepPurgeValues(null, cleanFormData(formData));
};

const GAPT50APage = createFormPage({
  formType,
  injectFormData,
  ViewForm: PT50AReport,
  prePopForm: fetchPrePopData(formType),
});

export default GAPT50APage;
