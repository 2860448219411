/* eslint-disable max-len */
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import logo from "images/logos/taxscribeBus-logo-blue.png";
import stateNotFound from "images/state-not-found.png";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import MILandingPage from "./MILandingPage";
import CountyLandingPage from "./CountyLanding";

const stateLandingPageMapping: Record<string, FC> = {
  mi: MILandingPage,
  nc: CountyLandingPage,
  ga: CountyLandingPage,
  tx: CountyLandingPage,
};

const StateLandingPage: FC = () => {
  const { stateAbbr = "" } = useParams();
  const LandingPage = stateLandingPageMapping[stateAbbr];

  if (!LandingPage) {
    // TODO: Make this a redirect to the up and coming state selection page.
    return (
      <Container
        fluid
        className="bg-bpp-light py-3 mb-5 min-vh-100 h-100"
      >
        <Row>
          <Col>
            <img
              className="img-fluid ms-2"
              alt="taxscribe-header-logo"
              width="250"
              height="auto"
              src={logo}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col
            lg={9}
            className="text-center p-4"
          >
            <img
              className="img-fluid"
              alt="No State Found"
              width="430"
              height="auto"
              src={stateNotFound}
            />
            <h1 className="display-5 mb-4 ts-primary">Opps...</h1>
            <p className="text-muted">
              We are <b>unable to find the state</b> you are looking for. Please
              check for any spelling errors and try again.
            </p>
            <hr />
            <div>
              <Link
                to="/"
                className="align-self-center text-nowrap"
              >
                <Button variant="info">
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    aria-hidden="true"
                    className="text-dark pe-2"
                  />
                  Select State
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return <LandingPage />;
};

export default StateLandingPage;
