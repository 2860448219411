"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lat5Utils = void 0;
const lodash_1 = require("lodash");
const getCategoryAverage = ({ category, data: { value }, }) => {
    if (!value)
        return 0;
    const monthData = (0, lodash_1.flattenDeep)(Object.values(value).map((month) => (month && month[category]) || 0));
    const totalCount = monthData.reduce((pre, next) => {
        if (next > 0)
            return pre + 1;
        return pre;
    }, 0);
    if (!totalCount)
        return 0;
    return Math.round((0, lodash_1.sum)(monthData) / totalCount);
};
const getCategoryTotal = ({ category, data: { value }, }) => {
    if (!value)
        return 0;
    const monthData = (0, lodash_1.flattenDeep)(Object.values(value).map((month) => (month && month[category]) || 0));
    return (0, lodash_1.sum)(monthData);
};
const getGrandTotal = ({ data }) => {
    const merchandise = getCategoryAverage({
        category: "merchandise",
        data,
    });
    const rawMaterials = getCategoryAverage({
        category: "rawMaterials",
        data,
    });
    const workInProgress = getCategoryAverage({
        category: "workInProgress",
        data,
    });
    const finishedGoods = getCategoryAverage({
        category: "finishedGoods",
        data,
    });
    const supplies = getCategoryAverage({
        category: "supplies",
        data,
    });
    return (0, lodash_1.sum)([
        merchandise,
        rawMaterials,
        workInProgress,
        finishedGoods,
        supplies,
    ]);
};
const getAverage = ({ data, }) => {
    const merchandise = getCategoryAverage({
        category: "merchandise",
        data,
    });
    const rawMaterials = getCategoryAverage({
        category: "rawMaterials",
        data,
    });
    const workInProgress = getCategoryAverage({
        category: "workInProgress",
        data,
    });
    const finishedGoods = getCategoryAverage({
        category: "finishedGoods",
        data,
    });
    const supplies = getCategoryAverage({
        category: "supplies",
        data,
    });
    return (0, lodash_1.sum)([
        merchandise,
        rawMaterials,
        workInProgress,
        finishedGoods,
        supplies,
    ]);
};
exports.lat5Utils = {
    getAverage,
    getGrandTotal,
    getCategoryTotal,
    getCategoryAverage,
};
