import React, { FC, PropsWithChildren } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import logo from "images/logos/taxscribe-bus-logo.png";
import TSQuill from "images/color-quill.png";
import config from "config/hostnames";
import styled from "styled-components";

interface StandardLandingDetailsProps {
  heroImage: string | null;
  state?: string;
}

const StyledHeroImage = styled.img`
  min-height: 500px;
`;

const HeroImage: FC<Pick<StandardLandingDetailsProps, "heroImage">> = ({
  heroImage,
}) => {
  return (
    <>
      <StyledHeroImage
        src={heroImage || ""}
        className="d-block mx-lg-auto img-fluid"
        alt="TImage"
        width="auto"
        height="auto"
        loading="lazy"
      />{" "}
    </>
  );
};

const StandardLandingDetails: FC<
  PropsWithChildren<StandardLandingDetailsProps>
> = ({ heroImage, children, state }) => {
  return (
    <>
      <main>
        <Container
          id="juris-cards"
          fluid
          className="hero-gradient py-3 mb-4"
        >
          <Container className="col-xxl-11 px-1">
            <Row className="flex-lg-row-reverse g-5 justify-content-center">
              <Col
                sm={10}
                md={6}
                lg={7}
                className="mt-2"
              >
                <HeroImage heroImage={heroImage} />
              </Col>
              <Col
                md={6}
                lg={5}
                className="align-self-center"
              >
                <img
                  className="img-fluid ms-2"
                  alt="taxscribe-header-logo"
                  width="350"
                  src={logo}
                />{" "}
                <h1 className="ts-yellow h4 mt-lg-4 mb-3 mb-lg-0">
                  Filing your {state} business personal property online.
                </h1>
              </Col>
            </Row>
          </Container>
          <div
            id="select-juris"
            className="p-2 text-center"
          >
            <h3 className="h4 text-white mb-0 text-nowrap">
              SELECT YOUR FILING JURISDICTION:
            </h3>
          </div>
        </Container>

        <Container
          id="juris-cards"
          fluid
          className="p-4 mb-2"
        >
          {children}
        </Container>
      </main>
      <footer className="bg-bpp-light p-lg-3">
        <p className="text-center mb-0 fs-3 fw-light">
          Don't see your county listed here? Sign up for
          <a
            className="fw-bold text-pro-teal"
            href={config.professionalUri}
          >
            <Image
              width="10"
              height="auto"
              src={TSQuill}
              className="ms-1"
            />
            TaxScribe Professional
          </a>{" "}
          to file your forms for multiple jurisdictions within one place!
        </p>
      </footer>
    </>
  );
};

export default StandardLandingDetails;
