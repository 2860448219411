"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const sectionH = {
    type: "object",
    default: {},
    title: "The sectionH Schema",
    required: ["standardToolingInfo"],
    properties: {
        standardToolingInfo: {
            type: "array",
            default: [],
            title: "The standardToolingInfo Schema",
            items: {
                type: "object",
                default: {},
                title: "A Schema",
                required: ["year", "costNew"],
                properties: {
                    year: {
                        type: "string",
                        default: "",
                        title: "The year Schema",
                    },
                    costNew: {
                        type: "integer",
                        default: 0,
                        title: "The costNew Schema",
                    },
                },
            },
        },
    },
};
exports.default = sectionH;
