import { gql } from "@apollo/client";
import {
  NCListingFormData,
  NCListingFormResult,
  NC_LISTING_DATA,
  TAX_FORM_METADATA_FRAGMENT,
} from "@taxscribe/gql-queries";

export const GET_FORM = gql`
  ${NC_LISTING_DATA}
  ${TAX_FORM_METADATA_FRAGMENT}
  query GetForm($formUuid: String!) {
    form(uuid: $formUuid) {
      ...TaxFormMetadataFragment
      data {
        formType
        ... on NCListingData {
          ...NCListingFormData
        }
      }
    }
  }
`;

export const GET_NEW_FORM_FOR_BUSINESS = gql`
  ${NC_LISTING_DATA}
  query GetNewFormForBusiness(
    $formType: NCFormType!
    $accountNumberPrimary: String!
    $jurisdictionId: Int!
  ) {
    ncptsFormData(
      formType: $formType
      accountNumberPrimary: $accountNumberPrimary
      jurisdictionId: $jurisdictionId
    ) {
      data {
        ... on NCListingData {
          ...NCListingFormData
        }
      }
      rawNcpts
    }
  }
`;

export interface NewFormForBusinessResults {
  ncptsFormData: {
    data: NCListingFormData;
    rawNcpts: any;
  };
}

export interface FormQueryResults {
  form: NCListingFormResult;
}
