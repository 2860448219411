"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPurchasesAndReceiptsLineTotalFromRoot = exports.getPurchasesAndReceiptsLineTotal = void 0;
const lodash_1 = require("lodash");
const percentages = {
    generalStateRate: 4.75,
    threePercentStateRate: 3,
    modularHomes: 4.75,
    manufacturedHomes: 4.75,
    twoPercentFoodRate: 2,
    twoPercentCountyRate: 2,
    twoAndOneQuarterPercentCountyRate: 2.25,
    halfPercentTransitCountyRate: 0.5,
    quarterPercentTransitCountyRate: 0.25,
};
const expectedTypes = ["number", "string"];
const isPurchasesAndReceiptsEntry = (value) => {
    if (typeof value !== "object")
        return false;
    if (value === null)
        return false;
    if (Array.isArray(value))
        return false;
    const valueAsRecord = value;
    if (!expectedTypes.includes(typeof valueAsRecord.purchasesForUse))
        return false;
    if (!expectedTypes.includes(typeof valueAsRecord.receipts))
        return false;
    return true;
};
const asNumber = (value) => {
    if (typeof value === "number")
        return value;
    if (typeof value === "string")
        return parseFloat(value);
    return 0;
};
const purchasesAndReceiptsPath = "taxInformation.purchasesAndReceipts";
const getPurchasesAndReceiptsLineTotal = ({ data, category, }) => {
    const rate = percentages[category];
    const compoundKey = `${purchasesAndReceiptsPath}.${category}`;
    const value = (0, lodash_1.get)(data, category);
    if (!isPurchasesAndReceiptsEntry(value)) {
        console.warn("Expected valid entry for purchases and receipts at path:", compoundKey);
        return 0;
    }
    return ((asNumber(value.purchasesForUse) + asNumber(value.receipts)) * (rate / 100));
};
exports.getPurchasesAndReceiptsLineTotal = getPurchasesAndReceiptsLineTotal;
const getPurchasesAndReceiptsLineTotalFromRoot = ({ data, category, }) => {
    return (0, exports.getPurchasesAndReceiptsLineTotal)({
        data: (0, lodash_1.get)(data, purchasesAndReceiptsPath),
        category,
    });
};
exports.getPurchasesAndReceiptsLineTotalFromRoot = getPurchasesAndReceiptsLineTotalFromRoot;
