"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const equitableInterest = [
    {
        required: ["equitableInterest"],
        properties: {
            equitableInterest: {
                enum: [false],
            },
        },
    },
    {
        required: ["equitableInterest", "equitableInterestList"],
        properties: {
            equitableInterest: {
                enum: [true],
            },
            equitableInterestList: {
                type: "array",
                default: [],
                title: "The equitableInterestList Schema",
                minItems: 1,
                items: {
                    type: "object",
                    default: {},
                    title: "A Schema",
                    required: [
                        "propertyLocation",
                        "interestHolders",
                        "equipmentDescription",
                        "year",
                        "city",
                        "zipCode",
                        "stateAbbr",
                    ],
                    properties: {
                        year: {
                            type: "string",
                            format: "year",
                        },
                        interest: {
                            $ref: "#/$defs/nonEmptyString",
                        },
                        interestHolders: {
                            $ref: "#/$defs/nonEmptyString",
                        },
                        equipmentDescription: {
                            $ref: "#/$defs/nonEmptyString",
                        },
                        propertyLocation: {
                            $ref: "#/$defs/nonEmptyString",
                        },
                        city: {
                            $ref: "#/$defs/nonEmptyString",
                        },
                        zipCode: {
                            $ref: "#/$defs/zipCode",
                        },
                        stateAbbr: {
                            $ref: "#/$defs/stateAbbr",
                        },
                        sellingPrice: {
                            type: "number",
                            minimum: 1,
                        },
                    },
                },
            },
        },
    },
];
exports.default = equitableInterest;
