import { gql } from "@apollo/client";
import { MIListing } from "@taxscribe/form";
import { fetchPrePopData } from "@taxscribe/ui";
import createFormPage, { FormMetadataInjector } from "components/shared/createFormPage";
import createFormView from "components/shared/createFormView";
import { getAuth } from "firebase/auth";


const CREATE_FORM = gql`
  mutation CreateMIListing(
    $source: String!
    $formData: MIListingDataInput!
    $businessName: String
    $jurisdiction: String!
  ) {
    createMIListing(
      source: $source
      formData: $formData
      businessName: $businessName
      jurisdiction: $jurisdiction
    )
  }
`;

const formType = "Listing";
const ListingView = createFormView({
  Form: MIListing,
  formType,
  formName: "MIListing",
  header: "Statement",
  creationQuery: CREATE_FORM,
});

const injectMetadata: FormMetadataInjector = ({ metadata }) => {
  const auth = getAuth();
  const email = auth.currentUser?.email;
  const contactName = auth.currentUser?.displayName;

  if (metadata?.contactName) return metadata;

  return {
    ...metadata,
    contactName: contactName || email || "",
  };
};

const MIListingPage = createFormPage({
  formType,
  injectMetadata,
  ViewForm: ListingView,
  prePopForm: fetchPrePopData(formType),
});

export default MIListingPage;
