"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const boatSeller = {
    type: "object",
    required: ["street", "city", "state", "zipCode"],
    properties: {
        name: {
            $ref: "#/$defs/nonEmptyString",
        },
        street: {
            $ref: "#/$defs/nonEmptyString",
        },
        city: {
            $ref: "#/$defs/nonEmptyString",
        },
        state: {
            $ref: "#/$defs/nonEmptyString",
        },
        zipCode: {
            type: "string",
            format: "zipCode",
        },
    },
};
exports.default = boatSeller;
