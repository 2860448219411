import React, { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useJurisdictionContext } from "@taxscribe/ui";

const LoginRedirect: FC = () => {
  const { jurisdictionName, jurisdictionType, stateAbbr } =
    useJurisdictionContext();
  const location = useLocation();

  const lowerType = jurisdictionType.toLowerCase();
  const lowerName = jurisdictionName.toLowerCase();
  return (
    <Navigate
      to={`/${stateAbbr}/${lowerType}/${lowerName}/login`}
      state={{ from: location }}
      replace
    />
  );
};

export default LoginRedirect;
