"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const sectionG = {
    type: "object",
    default: {},
    title: "The sectionG Schema",
    required: ["otherPropertyInfo"],
    properties: {
        otherPropertyInfo: {
            type: "array",
            default: [],
            title: "The otherPropertyInfo Schema",
            items: {
                type: "object",
                default: {},
                title: "A Schema",
                required: ["description", "costNew", "yearAcquired"],
                properties: {
                    description: {
                        $ref: "#/$defs/nonEmptyString",
                    },
                    costNew: {
                        type: "integer",
                        minimum: 1,
                    },
                    yearAcquired: {
                        type: "string",
                        format: "year",
                    },
                    cashValue: {
                        type: "integer",
                        title: "The cashValue Schema",
                    },
                },
            },
        },
    },
};
exports.default = sectionG;
