"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// TODO: Factor out these functions and remove repetition from
// conditional/required versions of the same format.
const formats = {
    optionalYear: {
        validate: (value) => {
            if (!value)
                return true;
            // Mostly for JSEN/ajv usage
            const val = typeof value == "string" ? value : value.toString();
            return val.length == 4 && val.search("^[0-9]{4}$") > -1;
        },
    },
    year: {
        validate: (value) => {
            // Mostly for JSEN/ajv usage
            const val = typeof value == "string" ? value : value.toString();
            return val.length == 4 && val.search("^[0-9]{4}$") > -1;
        },
    },
    optionalCalendar: {
        validate: (value) => {
            if (!value && typeof value !== "number")
                return true;
            return (value.search("^([0-9]{2})/([0-9]{2})/([0-9]{4})$") > -1 ||
                value.search("^([0-9]{4})-([0-9]{2})-([0-9]{2})$") > -1);
        },
    },
    calendar: {
        validate: (value) => {
            return (value.search("^([0-9]{2})/([0-9]{2})/([0-9]{4})$") > -1 ||
                value.search("^([0-9]{4})-([0-9]{2})-([0-9]{2})$") > -1);
        },
    },
    optionalPhone: {
        validate: (value) => {
            if (!value)
                return true;
            return Boolean(value.match("^\\([0-9]{3}\\)[0-9]{3}-[0-9]{4}$"));
        },
    },
    phone: {
        validate: (value) => {
            return Boolean(value.match("^\\([0-9]{3}\\)[0-9]{3}-[0-9]{4}$"));
        },
    },
    naics: {
        validate: (value) => {
            return (value.length >= 2 && value.length <= 6 && !!value.match(/^([0-9]*)$/));
        },
    },
    optionalNaics: {
        validate: (value) => {
            if (!value)
                return true;
            return (value.length >= 2 && value.length <= 6 && !!value.match(/^([0-9]*)$/));
        },
    },
    optionalState: {
        validate: (value) => {
            if (!value)
                return true;
            return value.search("^([A-Z]{2})$") > -1;
        },
    },
    state: {
        validate: (value) => {
            return value.search("^([A-Z]{2})$") > -1;
        },
    },
    optionalConditional: {
        validate: (value) => {
            if (!value)
                return true;
            const isBool = typeof value == "boolean";
            const isStr = typeof value == "string";
            if (isBool)
                return true;
            if (!isStr)
                return false;
            const lower = value.toLowerCase();
            return lower == "yes" || lower == "no";
        },
    },
    conditional: {
        validate: (value) => {
            const isBool = typeof value == "boolean";
            const isStr = typeof value == "string";
            if (isBool)
                return true;
            if (!isStr)
                return false;
            const lower = value.toLowerCase();
            return lower == "yes" || lower == "no";
        },
    },
    numeric: {
        validate: (value) => {
            return !!(value.match(/^[,0-9]*$/) || value.match(/^[0-9]*\.\d\d$/));
        },
    },
    optionalPercentage: {
        validate: (value) => {
            if (!value)
                return true;
            const isNumeric = !!(value.match(/^[,0-9]*$/) || value.match(/^[0-9]*\.\d\d$/));
            const actualNumber = parseFloat(value);
            return Boolean(isNumeric &&
                actualNumber &&
                actualNumber >= 0.0 &&
                actualNumber <= 100.0);
        },
    },
    percentage: {
        validate: (value) => {
            const isNumeric = !!(value.match(/^[,0-9]*$/) || value.match(/^[0-9]*\.\d\d$/));
            const actualNumber = parseFloat(value);
            return Boolean(isNumeric &&
                actualNumber &&
                actualNumber >= 0.0 &&
                actualNumber <= 100.0);
        },
    },
    notBlank: {
        validate: (value) => {
            return !!value.match(/[a-zA-Z0-9]/);
        },
    },
    greaterThanZero: {
        validate: (value) => {
            return !!(parseFloat(value) > 0);
        },
    },
    optionalZipCode: {
        validate: (value) => {
            if (!value)
                return true;
            const usFive = /^\d{5}$/;
            const usNine = /^\d{5}-\d{4}$/;
            const caSix = /[A-Za-z]\d[A-Za-z]\s\d[A-Za-z]\d$/;
            return [usFive, usNine, caSix].reduce((pre, next) => pre || !!value.match(next), false);
        },
    },
    zipCode: {
        validate: (value) => {
            const usFive = /^\d{5}$/;
            const usNine = /^\d{5}-\d{4}$/;
            const caSix = /[A-Za-z]\d[A-Za-z]\s\d[A-Za-z]\d$/;
            return [usFive, usNine, caSix].reduce((pre, next) => pre || !!value.match(next), false);
        },
    },
};
exports.default = formats;
