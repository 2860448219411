import { useImage } from "hooks/useImage";

interface Props {
  src: string;
  alt: string;
  [x: string]: any;
  fallbackSrc?: string;
}

export default function Image({ src, alt, fallbackSrc, ...imageProps }: Props) {
  const { hasLoaded, hasError } = useImage(src);

  if (hasError && fallbackSrc) {
    return (
      <img
        src={fallbackSrc}
        alt={alt}
        {...imageProps}
      />
    );
  }

  if (hasError) {
    return null;
  }

  return hasLoaded ? (
    <img
      src={src}
      alt={alt}
      {...imageProps}
    />
  ) : null;
}
