import React, { FC } from "react";
import { PenaltyBanner, useMostRecentFormPeriodForRoute } from "@taxscribe/ui";

export interface TSBPenaltyBannerProps {
  shortName: string;
  readOnly?: boolean;
}

const TSBPenaltyBanner: FC<TSBPenaltyBannerProps> = ({
  readOnly,
  shortName,
}) => {
  const { formPeriod } = useMostRecentFormPeriodForRoute({});

  if (!formPeriod?.penaltyDeadlineTimestamp) return null;

  return (
    <PenaltyBanner
      readOnly={readOnly}
      shortName={shortName}
      deadlineTimestamp={Number(formPeriod.filingDeadlineTimestamp) - 1}
      penaltyDeadineTimestamp={Number(formPeriod.penaltyDeadlineTimestamp) - 1}
    />
  );
};

export default TSBPenaltyBanner;
