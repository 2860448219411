"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const functionalIssues = {
    if: {
        properties: {
            hasFunctionalIssues: {
                const: true,
            },
        },
    },
    then: {
        required: ["functionalIssues"],
        properties: {
            functionalIssues: {
                $ref: "#/$defs/nonEmptyString",
            },
        },
    },
};
exports.default = functionalIssues;
