"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    type: "object",
    properties: {
        auditContact: {
            type: "object",
            properties: {
                name: {
                    type: "string",
                },
                phoneNumber: {
                    type: "string",
                    format: "optionalPhone",
                },
                street: {
                    type: "string",
                },
                city: {
                    type: "string",
                },
                state: {
                    type: "string",
                    format: "optionalState",
                },
                zipcode: {
                    type: "string",
                    format: "optionalZipCode",
                },
            },
        },
        paymentContact: {
            type: "object",
            properties: {
                name: {
                    type: "string",
                },
                phoneNumber: {
                    type: "string",
                    format: "optionalPhone",
                },
                street: {
                    type: "string",
                },
                city: {
                    type: "string",
                },
                state: {
                    type: "string",
                    format: "optionalState",
                },
                zipcode: {
                    type: "string",
                    format: "optionalZipCode",
                },
            },
        },
    },
};
