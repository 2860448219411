import React, { FC } from "react";
import { Row } from "react-bootstrap";

import ncHeroImage from "images/nc-hero.svg";
import JurisCardList from "components/JurisCardList";
import StandardLandingDetails from "../StandardLandingDetails";
import { useParams } from "react-router-dom";
import { states } from "@taxscribe/utils";

const images: Record<string, string> = {
  NC: ncHeroImage,
};

const CountyLandingPage: FC = () => {
  const { stateAbbr } = useParams();
  const upCaseStateAbbr = stateAbbr?.toUpperCase();

  if (!Object.keys(states).includes(upCaseStateAbbr || "")) {
    return <div>Invalid state abbreviation</div>;
  }

  const landingHeroImage: string | null = images[upCaseStateAbbr || ""] || null;
  const stateName = (states as Record<string, string>)[upCaseStateAbbr || ""];

  return (
    <StandardLandingDetails
      heroImage={landingHeroImage}
      state={stateName}
    >
      <Row
        xs={1}
        sm={2}
        md={3}
        lg={5}
        xlg={7}
        className="g-4 mb-4"
      >
        {/* We can assert here because we've already checked this */}
        <JurisCardList stateAbbr={stateAbbr as string} />
      </Row>
    </StandardLandingDetails>
  );
};

export default CountyLandingPage;
