"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const itemProperties = {
    year: {
        type: "string",
        format: "year",
    },
    make: {
        type: "string",
        minLength: 1,
    },
    width: {
        $ref: "#/$defs/nonZeroPositiveInteger",
    },
    length: {
        $ref: "#/$defs/nonZeroPositiveInteger",
    },
    vin: {
        type: "string",
        minLength: 1,
    },
    location: {
        type: "string",
        // minLength: 1,
    },
    yearAcquired: {
        type: "string",
        format: "year",
    },
    originalCost: {
        $ref: "#/$defs/nonZeroPositiveInteger",
    },
};
const b3items = {
    type: ["null", "object"],
    anyOf: [
        {
            properties: {
                PropertyPk: {
                    type: "string",
                    minLength: 1,
                },
            },
            required: ["PropertyPk"],
        },
        {
            properties: Object.assign({}, itemProperties),
            required: [
                "year",
                "make",
                "vin",
                "width",
                "length",
                // "location",
                "yearAcquired",
                "originalCost",
            ],
        },
    ],
};
exports.default = [
    {
        properties: {
            mobileHome: {
                enum: [false, "no"],
            },
            b3Attachment: {
                $ref: "#/$defs/nullableAttachment",
            },
        },
        required: ["mobileHome"],
    },
    {
        properties: {
            mobileHome: {
                enum: [true, "yes"],
            },
            b3s: {
                type: "array",
                minItems: 1,
                items: b3items,
            },
            b3Attachment: {
                $ref: "#/$defs/nullableAttachment",
            },
        },
        required: ["mobileHome", "b3s"],
    },
    {
        properties: {
            mobileHome: {
                enum: [true, "yes"],
            },
            b3Attachment: {
                $ref: "#/$defs/attachment",
            },
        },
        required: ["mobileHome", "b3Attachment"],
    },
];
