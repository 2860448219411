import { deepPurge } from "@taxscribe/utils";
import { useMemo } from "react";

const useFilteredFormData = <T extends Record<string, any>>(formData?: T) => {
  return useMemo(() => {
    if (formData) {
      return deepPurge("formType", deepPurge("__typename", formData));
    }
  }, [formData]);
};

export default useFilteredFormData;
