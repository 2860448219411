import React, { FC, useCallback, useMemo, useState } from "react";
import Select from "react-select";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";

import OrganizationInfo from "components/shared/OrganizationInfo";
import {
  useJurisdictionContext,
  useJurisdictionFormTypes,
} from "@taxscribe/ui";
import PinField from "./PinField";
import StartButtons from "./StartButtons";

interface FormTypeOption {
  label: string;
  value: string;
}

const Pin: FC = () => {
  const { jurisdictionId } = useJurisdictionContext();
  const { formTypes, loading } = useJurisdictionFormTypes({
    id: jurisdictionId,
    formGroup: "BPP",
  });
  const [pin, setPin] = useState("");
  const [formType, setFormType] = useState<null | FormTypeOption>(null);

  const handleFormTypeChange = useCallback((formType: any) => {
    setFormType(formType);
  }, []);

  const formTypeOptions = useMemo(() => {
    return formTypes.map((formType) => ({
      label: formType.longName,
      value: formType.shortName,
    }));
  }, [formTypes]);

  if (loading) {
    return <Spinner animation="border" />;
  }

  const show = Boolean(formType);
  return (
    <>
      <OrganizationInfo />
      <div>
        <Container
          fluid
          className="mt-4 mb-4 container-xxl"
        >
          <Row>
            <Col />
            <Col md={4}>
              <Row className="d-flex justify-content-between align-items-center">
                <Col>
                  <div className="d-flex justify-content-between">
                    <h1 className="display-6">New Form</h1>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Form.Label>Select A Form Type</Form.Label>
                      <Select
                        value={formType}
                        options={formTypeOptions}
                        onChange={handleFormTypeChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <PinField
                        show={show}
                        setPin={setPin}
                        value={pin}
                      />
                    </Col>
                  </Row>
                  <StartButtons
                    pin={pin}
                    show={show}
                    formType={formType?.value}
                  />
                </Col>
              </Row>
            </Col>
            <Col />
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Pin;
