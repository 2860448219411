"use strict";
// const improvementItems = {
//   type: "object",
//   anyOf: [
//     {
//       properties: {
//         description: {
//           $ref: "#/$defs/nonEmptyString",
//         },
//         originalCost: {
//           type: "number",
//           minimum: 1,
//         },
//       },
//     },
//     {
//       properties: {
//         description: {
//           enum: ["", null],
//         },
//         originalCost: {
//           type: "number",
//           maximum: 0,
//           minimum: 0,
//         },
//       },
//     },
//   ],
// };
Object.defineProperty(exports, "__esModule", { value: true });
const v2 = {
    type: "object",
    properties: {
        description: {
            type: "string",
        },
    },
    allOf: [
        {
            if: {
                properties: {
                    description: {
                        minLength: 1,
                    },
                },
            },
            then: {
                properties: {
                    originalCost: {
                        minimum: 1,
                    },
                },
            },
        },
        {
            if: {
                properties: {
                    originalCost: {
                        minimum: 1,
                    },
                },
            },
            then: {
                properties: {
                    description: {
                        minLength: 1,
                    },
                },
            },
        },
    ],
};
const sectionM = {
    type: "object",
    required: ["leaseHoldImprovements"],
    properties: {
        leaseHoldImprovements: {
            type: "array",
            items: v2,
        },
    },
};
exports.default = sectionM;
