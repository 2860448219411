"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculatePrice = void 0;
// https://stackoverflow.com/a/18358056
// .toFixed has rounding errors in chrome recently
// multiply by 100, round, divide by 100
function roundToTwo(num) {
    return Number(Math.round(Number(num + "e+2")) + "e-2");
}
const calculatePrice = ({ volume, priceRanges }) => {
    const priceRange = priceRanges.find((range) => {
        // If there is no min, then it's 0.
        const isOverMin = (range.min || 0) <= volume;
        // If there is no max, then the max is infinity.
        const isBelowMax = !range.max || range.max >= volume;
        return isOverMin && isBelowMax;
    });
    if (!priceRange) {
        const errMessage = "Error determining price range";
        console.error(errMessage);
        throw new Error(errMessage);
    }
    const dollarAmount = roundToTwo(volume * priceRange.price);
    return {
        dollarAmount,
        ranges: priceRanges,
    };
};
exports.calculatePrice = calculatePrice;
