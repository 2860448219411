"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const scheduleB = {
    type: "object",
    required: ["ownedInventory"],
    allOf: [
        {
            properties: {
                ownedInventory: {
                    type: "boolean",
                },
            },
        },
        {
            if: {
                properties: {
                    ownedInventory: {
                        const: true,
                    },
                },
            },
            then: {
                properties: {
                    inventory: {
                        type: "object",
                        scheduleBTotalPositive: true,
                        properties: {
                            merchandise: { type: "number" },
                            rawMaterials: { type: "number" },
                            goodsInProcess: { type: "number" },
                            finishedGoods: { type: "number" },
                            goodsInTransit: { type: "number" },
                            warehoused: { type: "number" },
                            consigned: { type: "number" },
                            floorPlanned: { type: "number" },
                            spareParts: { type: "number" },
                            supplies: { type: "number" },
                            packagingMaterials: { type: "number" },
                            livestock: { type: "number" },
                            accountingMethod: { type: "string" },
                            fiscalYearEndDate: { type: "string", format: "optionalCalendar" },
                            costMethod: {
                                $ref: "#/$defs/nonEmptyString",
                            },
                            priorYearInventoryReported: { type: "number" },
                            grossSalesPreviousYear: { type: "number" },
                        },
                    },
                },
            },
        },
    ],
};
exports.default = scheduleB;
