"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const taxpayerInformation = {
    type: "object",
    required: [
        "name",
        "address",
    ],
    properties: {
        name: {
            $ref: "#/$defs/nonEmptyString",
        },
        address: {
            $ref: "#/$defs/standardAddressWithRequiredFields",
        },
    },
};
exports.default = taxpayerInformation;
