"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const pt50pKeywords = {
    scheduleBTotalPositive: {
        keyword: "scheduleBTotalPositive",
        validate: (schema, sig, parentSchema, data) => {
            const inventory = (0, lodash_1.get)(data, "parentData.inventory", {});
            if (!inventory || typeof inventory !== "object")
                return false;
            const numericValues = Object.values(inventory).filter((v) => typeof v === "number");
            return (0, lodash_1.sum)(numericValues) > 0;
        },
    },
};
exports.default = pt50pKeywords;
