import { FC, PropsWithChildren } from "react";
import { TaxScribeLogo, PrivateLogoImage } from "./AppImages";
import { useJurisdictionContext } from "@taxscribe/ui";

const privateLabelJurisdictions: Record<string, boolean> = {
  Mecklenburg: true,
};

interface PrivateLabelProps {
  labelImage: any;
  isPrivateLabel: boolean;
  jurisdictionName: string;
}

const PrivateLabel: FC<PrivateLabelProps> = ({
  labelImage,
  isPrivateLabel,
  jurisdictionName,
}) => {
  if (isPrivateLabel && privateLabelJurisdictions[jurisdictionName] === true) {
    return <>{labelImage}</>;
  }

  return null;
};

interface TaxscribeLabelProps {
  labelImage: any;
  isPrivateLabel: boolean;
}

const TaxScribeLabel: FC<TaxscribeLabelProps> = ({
  labelImage,
  isPrivateLabel,
}) => {
  if (isPrivateLabel) {
    return null;
  }

  return <>{labelImage}</>;
};

const UnauthenticatedLayout: FC<PropsWithChildren> = ({ children }) => {
  const { fullSlug, jurisdictionName } = useJurisdictionContext();

  // @TODO: Add proper types to Private label
  // @TODO: Get private label data from server side sponsorsBPP attribute
  const privateKeys = Object.keys(privateLabelJurisdictions);
  const isPrivateLabel = privateKeys.includes(jurisdictionName);

  return (
    <>
      <PrivateLabel
        labelImage={<PrivateLogoImage labelPath={fullSlug} />}
        isPrivateLabel={isPrivateLabel}
        jurisdictionName={jurisdictionName}
      />
      <TaxScribeLabel
        labelImage={<TaxScribeLogo />}
        isPrivateLabel={isPrivateLabel}
      />
      {children}
      <PrivateLabel
        labelImage={<TaxScribeLogo />}
        isPrivateLabel={isPrivateLabel}
        jurisdictionName={jurisdictionName}
      />
    </>
  );
};

export default UnauthenticatedLayout;
