import { useMemo } from "react";
import { Jurisdiction } from "../../../types/jurisdiction";

interface UseChildJurisdictionsArgs {
  jurId?: number;
  jurisdictions: Jurisdiction[] | null;
}

const useChildJurisdictions = ({
  jurId,
  jurisdictions,
}: UseChildJurisdictionsArgs) => {
  return useMemo(() => {
    if (!jurId) return [];

    return (
      jurisdictions?.filter((jur) => {
        return jur.parentId === jurId;
      }) || []
    );
  }, [jurisdictions, jurId]);
};

export default useChildJurisdictions;
