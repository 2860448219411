"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ownershipInformation = {
    type: "object",
    required: [
        "firstName",
        "lastName",
        "email",
        "phoneNumber",
        "addressOne",
        "city",
        "state",
        "zipCode",
    ],
    properties: {
        firstName: {
            $ref: "#/$defs/nonEmptyString",
        },
        lastName: {
            $ref: "#/$defs/nonEmptyString",
        },
        middleName: {
            type: "string",
        },
        suffix: {
            type: "string",
        },
        additionalName: {
            type: "string",
        },
        accountNumber: {
            type: "string",
        },
        email: {
            $ref: "#/$defs/nonEmptyString",
            format: "email",
        },
        phoneNumber: {
            $ref: "#/$defs/nonEmptyString",
            format: "phone",
        },
        addressOne: {
            $ref: "#/$defs/nonEmptyString",
        },
        addressTwo: {
            type: "string",
        },
        city: {
            $ref: "#/$defs/nonEmptyString",
        },
        state: {
            $ref: "#/$defs/nonEmptyString",
        },
        zipCode: {
            $ref: "#/$defs/nonEmptyString",
            format: "zipCode",
        },
    },
};
exports.default = ownershipInformation;
