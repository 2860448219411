"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.vehiclePermanentPlateData = exports.vehiclePermanentPlateDataEntry = void 0;
exports.vehiclePermanentPlateDataEntry = {
    year: "",
    make: "",
    model: "",
    body: "",
    vin: "",
    yearAcquired: "",
    cost: 0,
    length: 0,
    width: 0,
    plateNumber: "",
    titleNumber: "",
    regisrationIssueDate: "",
};
exports.vehiclePermanentPlateData = {
    needToAdd: null,
    vehicles: [],
};
