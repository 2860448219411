"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const exemption = {
    type: "object",
    anyOf: [
        {
            properties: {
                hasExemption: {
                    enum: [false],
                },
            },
        },
        {
            properties: {
                hasExemption: {
                    enum: [true],
                },
                exemptionAttachment: {
                    $ref: "#/$defs/attachment",
                },
            },
        },
    ],
};
exports.default = exemption;
