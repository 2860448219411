import { useMemo } from "react";

import { Jurisdiction } from "../../../types/jurisdiction";

const mapJurToOpt = (jur: Jurisdiction) => ({
  value: jur,
  label: `${jur.name} ${jur.jurisdictionType}`,
});

export interface JurisdictionOption {
  value: Jurisdiction;
  label: string;
}

export interface UseJurisdictionSelectOptions {
  jurisdictions: Jurisdiction[];
  highlight?: (jur: Jurisdiction) => boolean;
  sort?: (jurA: JurisdictionOption, jurB: JurisdictionOption) => 1 | -1 | 0;
}

const useJurisdictionSelectOptions = ({
  jurisdictions,
  sort,
}: UseJurisdictionSelectOptions) => {
  return useMemo(() => {
    const options = jurisdictions.map(mapJurToOpt);

    if (sort) {
      const sorted = [...options].sort(sort);
      return sorted;
    }
    return options;
  }, [jurisdictions, sort]);
};

export default useJurisdictionSelectOptions;
