"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    type: "object",
    properties: {
        leaseProperty: {
            type: ["string", "boolean"],
            format: "conditional",
        },
        f1s: {
            type: "array",
            items: {
                type: ["null", "object"],
                properties: {
                    ownerName: {
                        type: "string",
                    },
                    ownerAddress: {
                        type: "string",
                    },
                    description: {
                        type: "string",
                    },
                    leaseDateAndTerm: {
                        type: "string",
                    },
                    monthlyPayment: {
                        type: ["string", "number"],
                        format: "numeric",
                    },
                    accountNumber: {
                        type: "string",
                    },
                },
            },
        },
        f1Attachment: {
            type: ["null", "object"],
            properties: {
                lastModifiedDate: {
                    type: "string",
                },
                name: {
                    type: "string",
                },
                size: {
                    type: ["string", "number"],
                    format: "numeric",
                },
                typeOf: {
                    type: "string",
                },
            },
        },
    },
    required: ["leaseProperty"],
};
