import React, { FC } from "react";
import { PDFInAppView } from "@taxscribe/form";
import styled from "styled-components";

export interface PDFViewProps {
  uuid: string;
}

const PDFWrapper = styled.div`
  height: 100vh;
`;

const PDFView: FC<PDFViewProps> = ({ uuid }) => {
  return (
    <PDFWrapper>
      <PDFInAppView uuid={uuid} />
    </PDFWrapper>
  );
};

export default PDFView;
