import { gql } from "@apollo/client";
import { TXExtension } from "@taxscribe/form";
import { fetchPrePopDataByPin } from "@taxscribe/ui";
import { cleanFormData, deepPurgeValues } from "@taxscribe/utils";
import createFormPage, { FormDataInjector } from "components/shared/createFormPage";
import createFormView from "components/shared/createFormView";
import { merge } from "lodash";

const CREATE_FORM = gql`
  mutation CreateTXExtension(
    $app: String!
    $businessName: String
    $source: String!
    $jurisdiction: String!
    $formData: TXExtensionDataInput!
  ) {
    createTXExtension(
      app: $app
      source: $source
      formData: $formData
      businessName: $businessName
      jurisdiction: $jurisdiction
    )
  }
`;

const formType = "TXExtension";
const ExtensionReport = createFormView({
  Form: TXExtension,
  formType,
  formName: "TXExtension",
  header: "TX Extension",
  creationQuery: CREATE_FORM,
});

const injectFormData: FormDataInjector = ({
  formData,
  formUuid,
  currentSubmission,
}) => {
  if (!formData) return formData;
  if (!currentSubmission?.id) {
    const extraState: Record<string, any> = {};
    return deepPurgeValues(null, merge(cleanFormData(formData), extraState));
  }

  return deepPurgeValues(null, cleanFormData(formData));
};

const TXExtensionPage = createFormPage({
  formType,
  injectFormData,
  ViewForm: ExtensionReport,
  prePopForm: fetchPrePopDataByPin(formType),
});

export default TXExtensionPage;
