import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import styled from "styled-components";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import miStateImg from "images/mi-state.svg";
import ncStateImg from "images/nc-state.svg";
import gaStateImg from "images/ga-state.png";
import txStateImg from "images/tx-state.png";
import landingHeroImage from "images/bpp-state-hero-icon.png";
import logo from "images/logos/taxscribeBus-logo-blue.png";
import config from "config/hostnames";

const StateCardStyled = styled(Card)`
  transform: rotate(0);
  margin-bottom: 1rem;
  height: 10rem;
  &:last-child {
    margin-bottom: 0 !important;
  }
`;

const StyledImg = styled.img`
`;

interface StatesTypes {
  name: string;
  image?: string;
  link: string;
}

const states: StatesTypes[] = [
  {
    name: "North Carolina",
    image: ncStateImg,
    link: "/nc",
  },
  {
    name: "Michigan",
    image: miStateImg,
    link: "/mi",
  },
  {
    name: "Georgia",
    link: "/ga",
    image: gaStateImg,
  },
  {
    name: "Texas",
    link: "/tx",
    image: txStateImg,
  },
].filter((state) => {
  return config.states.includes(state.name);
});

const StateSelectorLandingPage: FC = () => {
  return (
    <main>
      <Container
        id="state-landing"
        fluid
        className="bg-bpp-light py-3 mb-5"
      >
        <img
          className="img-fluid ms-2"
          alt="taxscribe-header-logo"
          width="250"
          src={logo}
        />{" "}
        <Container className="col-xxl-11 px-1 py-5">
          <Row className="flex-lg-row-reverse g-5 justify-content-center">
            <Col className="col-10 col-sm-8 col-lg-6 mt-2">
              <img
                src={landingHeroImage}
                className="d-block mx-lg-auto img-fluid"
                alt="Taxes"
                width="600"
                height="400"
                loading="lazy"
              />{" "}
              <h1 className="text-center ts-primary">Save. Send. Simple.</h1>
            </Col>
            <Col className="col-lg-6">
              <h1 className="display-3 fw-semibold">
                Filing business personal property in your state made easy!
              </h1>
            </Col>
          </Row>
        </Container>
        <div
          id="select-state"
          className="p-2 text-center"
        >
          <h3 className="h4 text-white mb-0 text-nowrap">
            SELECT YOUR FILING STATE:
          </h3>
        </div>
      </Container>

      <Container
        id="state-cards"
        className="mb-5"
      >
        <Row className="justify-content-center">
          <Col
            lg={8}
            xl={9}
            xxl={10}
          >
            <div className="bg-light p-4 rounded-4 border-0 w-100">
              {states.map(({ name, image, link }, idx) => {
                return (
                  <StateCardStyled
                    key={idx}
                    className="border-light"
                  >
                    <Card.Body className="d-flex align-items-center ts-primary">
                      <StyledImg
                        src={image}
                        className="img-fluid me-3"
                        alt="State Logo"
                        width="100"
                        loading="lazy"
                      />{" "}
                      <h2 className="fw-bold">{name}</h2>
                      <Link
                        to={link}
                        className="align-self-center ms-auto stretched-link"
                      >
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          aria-hidden="true"
                          size="xl"
                          className="fw-bold me-2 ts-primary"
                        />
                      </Link>
                    </Card.Body>
                  </StateCardStyled>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default StateSelectorLandingPage;
