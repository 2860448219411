"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lengthEntry = {
    type: "object",
    required: [
        "feet",
        "inches",
    ],
    properties: {
        feet: {
            type: "number",
            minimum: 1,
        },
        inches: {
            type: "number",
            minimum: 0,
            maximum: 11,
        },
    },
};
const watercraftEntry = {
    type: "object",
    required: [
        "year",
        "make",
        "model",
        "length",
    ],
    properties: {
        year: {
            type: "string",
            format: "year",
        },
        make: {
            $ref: "#/$defs/nonEmptyString",
        },
        model: {
            $ref: "#/$defs/nonEmptyString",
        },
        length: lengthEntry,
        registrationNumber: {
            $ref: "#/$defs/nonEmptyString",
        },
        fuelCode: {
            type: "string",
        },
        modelCode: {
            type: "string",
        },
        propulsionCode: {
            type: "string",
        },
        primaryUseCode: {
            $ref: "#/$defs/nonEmptyString",
        },
        yearAcquired: {
            type: "string",
            format: "optionalYear",
        },
        cost: {
            type: "number",
        },
        marina: {
            type: "string",
        },
        motorMakeOne: {
            type: "string",
        },
        motorMakeTwo: {
            type: "string",
        },
        motorHpOne: {
            type: "number",
        },
        motorHpTwo: {
            type: "number",
        },
        slipNumber: {
            type: "string",
        },
        slipNumberExtension: {
            type: "string",
        },
        motorSerialNumberOne: {
            type: "string",
        },
        motorSerialNumberTwo: {
            type: "string",
        },
    },
};
const watercraft = {
    type: "object",
    oneOf: [
        {
            required: ["needToAdd"],
            properties: {
                needToAdd: {
                    enum: [false],
                },
            },
        },
        {
            required: ["needToAdd", "watercraft"],
            properties: {
                needToAdd: {
                    enum: [true],
                },
                watercraft: {
                    type: "array",
                    items: watercraftEntry,
                    minItems: 1,
                },
            },
        },
    ],
};
exports.default = watercraft;
