import React, { FC, useMemo } from "react";

import ViewExtension from "./ViewExtension";
import { FormQueryResults, GET_FORM } from "./queries";
import useExistingFormData from "hooks/useExistingFormData";
import { useParams } from "react-router-dom";
import NewFormForBusiness from "./NewFormForBusiness";
import useFilteredFormData from "hooks/useCleansedFormData";
import useNewFormMetadata from "hooks/useNewFormMetadata";

interface ExistingFormArgs {
  formUuid: string;
}

const ExistingForm: FC<ExistingFormArgs> = ({ formUuid }) => {
  const { data, loading, formData, currentSubmission } = useExistingFormData<
    FormQueryResults["form"]["data"]
  >({ formUuid, query: GET_FORM });

  const filingDeadline = data?.form?.formPeriod?.filingDeadlineFormattedDay;
  const source = data?.form?.source;
  const filingYear = String(data?.form?.filingYear || "");

  const filteredFormData = useFilteredFormData(formData);

  const metadata = useMemo(() => {
    return {
      ...currentSubmission,
      source,
      app: "TSB",
      filingYear: String(data?.form?.filingYear),
    };
  }, [currentSubmission, source, data]);

  if (loading) return null;

  return (
    <ViewExtension
      metadata={metadata}
      formUuid={formUuid}
      filingYear={filingYear}
      initialState={filteredFormData}
      filingDeadline={filingDeadline}
    />
  );
};

const NCExtensionPage: FC = () => {
  const { formUuid, accountNumberPrimary } = useParams();
  const { metadata: newFormMetadata, loading } = useNewFormMetadata();

  if (formUuid) {
    return <ExistingForm formUuid={formUuid} />;
  }

  if (loading) return null;
  if (accountNumberPrimary) {
    return (
      <NewFormForBusiness
        metadata={newFormMetadata}
        accountNumberPrimary={accountNumberPrimary}
      />
    );
  }

  return <ViewExtension metadata={newFormMetadata} />;
};

export default NCExtensionPage;
