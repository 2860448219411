import { Container, Navbar, Nav, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useJurisdictionContext } from "@taxscribe/ui";

export default function HomePage() {
  const { webPath, fullSlug, name } = useJurisdictionContext();

  const year = new Date().getFullYear();
  /* eslint-disable max-len */
  const text = `
  TaxScribe Business is ${name}’s web portal for filing business personal property extension requests and listings. Online listing forms make the listing process easy and allow for multiple revisions or reviews prior to submission. Files can be readily attached to listing forms or as supplemental documentation. Data validation means fewer errors which expedites our review and acceptance. TaxScribe Business provides real time status, includes a dedicated message system and maintains a full history of your filing.
`;
  /* eslint-enable max-len */

  return (
    <div
      id="county-landing"
      className={`w-100 min-vh-100 text-white ${fullSlug}`}
    >
      <Container>
        <header>
          <Navbar
            id="county-landing-nav"
            variant="dark"
            expand="lg"
            className="px-3"
          >
            <Link
              to="/"
              className="navbar-brand"
              style={{ textDecoration: "none", color: "white" }}
            >
              <img
                alt="Taxscribe Business Logo"
                src="/images/seals/taxscribe-bus-logo.png"
                width="280"
                height="auto"
                className="d-inline-block align-top"
              />{" "}
            </Link>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="ms-auto my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              >
                <Nav.Link
                  className="text-white fw-bold mb-2 ps-2"
                  href="https://www.ncdor.gov/local-government/property-tax"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NCDOR Property Tax
                </Nav.Link>
                <Nav.Link
                  className="text-white fw-bold mb-2 ps-2"
                  href="https://taxman.atlassian.net/wiki/spaces/TSR/overview"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  TaxScribe Knowledge Base
                </Nav.Link>
                <Nav.Link
                  className="text-white fw-bold mb-2 ps-2"
                  href="https://bizlink.taxscribe.com/#/terms-of-service?_k=qlmojp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  TaxScribe Business
                </Nav.Link>
                <Link to={`/${webPath}/login`}>
                  <Button
                    variant="info"
                    className="text-uppercase ms-md-2"
                  >
                    login
                  </Button>
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </header>
        <main>
          <div className="px-4 py-5 mb-5">
            <Row className="flex-lg-row-reverse g-5 py-5 align-items-center">
              <Col className="col-10 col-sm-8 col-lg-6 mt-2">
                <img
                  src={`/images/seals/${fullSlug}.png`}
                  alt="County Seal"
                  className="d-block mx-lg-auto img-fluid"
                  width="450"
                  height="450"
                  loading="lazy"
                />
              </Col>
              <Col className="col-lg-6">
                <h1 className="text-white display-3">File Online Today</h1>
                <p className="text-white ls-1 fs-5 mt-4 mb-4">{text}</p>
                <Link to={`/${webPath}/register`}>
                  <Button
                    variant="info"
                    size="lg"
                    className="text-uppercase ms-2"
                  >
                    register
                  </Button>
                </Link>{" "}
                <Link to={`/${webPath}/login`}>
                  <Button
                    variant="info"
                    size="lg"
                    className="text-uppercase ms-2"
                  >
                    login
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
          <Row className="mt-lg-3">
            <Col className="pb-lg-4 pt-lg-5 pb-3">
              <p className="text-center mb-0 text-secondary">
                {" "}
                &copy; {year} Reason Consulting Corporation
              </p>
            </Col>
          </Row>
        </main>
      </Container>
    </div>
  );
}
