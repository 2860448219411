import { useProcessFormName } from "@taxscribe/ui";
import React, { FC } from "react";
import { Alert } from "react-bootstrap";
import { GuardMessageProps } from "./DeadlineGuard";

const DeadlineAlert: FC<GuardMessageProps> = ({
  timezone,
  formType,
  deadline,
}) => {
  const processFormName = useProcessFormName();
  const formName = processFormName(formType || "").toLowerCase();

  return (
    <Alert variant="warning">
      The deadline for filing a(n) {formName} has ended as of{" "}
      {deadline?.toLocaleDateString("en-US", { timeZone: timezone })} {timezone}{" "}
      time. You can view this form but you cannot submit it.
    </Alert>
  );
};

export default DeadlineAlert;
