import React, { FC } from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-top: 10%;
`;

const PageSpinner: FC = () => {
  return (
    <SpinnerWrapper>
      <Spinner animation="border" />
    </SpinnerWrapper>
  );
};

export default PageSpinner;
