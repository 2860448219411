import { Jurisdiction } from "types/jurisdiction";
import { JurisdictionOption } from "pages/StateLandingPage/hooks/useJurisdictionSelectOptions";

const checkEfile = (jur?: Jurisdiction) => jur?.hasEfile;
const checkEfileDeep = (
  jurisdictions: Jurisdiction[] | null,
  jurisdiction: JurisdictionOption | null,
) => {
  if (!jurisdiction) return false;
  if (checkEfile(jurisdiction.value)) return true;

  const gatherChildren = (cty: JurisdictionOption) => (jur: Jurisdiction) => {
    return jur.parentId === cty?.value.id;
  };
  const children = jurisdictions?.filter(gatherChildren(jurisdiction)) || [];
  return children.some(checkEfile);
};

export default checkEfileDeep;
