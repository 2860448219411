"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const keywords = {
    outOfBusinessConditions: {
        keyword: "outOfBusinessConditions",
        validate: (schema, data) => {
            const groups = ["group1", "group3", "group4", "group5", "group6"];
            const outOfBusiness = data.business.outOrInBusiness === "yes";
            const scheduleA = data.scheduleA;
            const scheduleAExists = !!scheduleA;
            const group2Na = scheduleAExists && !!scheduleA.group2 && !!scheduleA.group2.na;
            const group7Na = scheduleAExists && !!scheduleA.group7 && !!scheduleA.group7.na;
            const allNa = !!scheduleA &&
                groups
                    .map((key) => scheduleA[key] && (scheduleA[key].na || !scheduleA[key].grid))
                    .reduce((pre, next) => pre && next);
            if (outOfBusiness) {
                return true;
            }
            if (allNa && group2Na && group7Na) {
                return false;
            }
            return !!scheduleA;
        },
        errors: true,
    },
    signatureMatches: {
        keyword: "signatureMatches",
        validate: (schema, sig, parentSchema, data) => {
            const { parentData } = data || {};
            const email = (parentData === null || parentData === void 0 ? void 0 : parentData.email) || "";
            if (!email)
                return false;
            return sig === email;
        },
        errors: true,
    },
    positiveGridRow: {
        keyword: "positiveGridRow",
        validate: (_, parentSchema = {}) => {
            const { instancePath, rootData, parentData } = parentSchema;
            const naKey = [...instancePath.split("/").slice(1, -3), "na"];
            const na = (0, lodash_1.get)(rootData, naKey, false);
            const { prior = 0, additions = 0, deletions = 0 } = parentData || {};
            // The validation should pass IF
            // 1. The grid is disabled, i.e. na === true, OR
            // 2. Pror + Additions are greater than or equal to Deletions.
            return na || prior + additions >= deletions;
        },
        errors: true,
        schema: false,
    },
    dbaName: {
        keyword: "dbaName",
        validate: (schema, data) => {
            if (data.dbaCheckbox) {
                return true;
            }
            return data.dbaName;
        },
    },
};
exports.default = keywords;
