import { useJurisdictionContext } from "@taxscribe/ui";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const useNewFormLink = () => {
  const { webPath, hasPrePopulationPin } = useJurisdictionContext();
  if (hasPrePopulationPin) {
    return `/${webPath}/pin`;
  }

  return `/${webPath}/search`;
};

const NewFormLink: FC = () => {
  const newFormLink = useNewFormLink();

  return (
    <Link to={newFormLink}>
      <Button
        variant="info"
        className="text-uppercase"
      >
        <FontAwesomeIcon
          icon={faPlus}
          aria-hidden="true"
          className="text-dark pe-2"
        />
        Start New Form
      </Button>
    </Link>
  );
};

export default NewFormLink;
