"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    type: "object",
    anyOf: [
        {
            required: ["ownsAdvertisingStructures"],
            properties: {
                ownsAdvertisingStructures: {
                    enum: [false, "no"],
                },
            },
        },
        {
            required: ["i1Attachment", "ownsAdvertisingStructures"],
            properties: {
                ownsAdvertisingStructures: {
                    enum: [true, "yes"],
                },
                i1Attachment: {
                    $ref: "#/$defs/attachment",
                },
            },
        },
        {
            required: ["i1s", "ownsAdvertisingStructures"],
            properties: {
                ownsAdvertisingStructures: {
                    enum: [true, "yes"],
                },
                i1s: {
                    type: "array",
                    minItems: 1,
                    items: {
                        type: "object",
                        properties: {
                            panelNumbers: {
                                type: "string",
                            },
                            DOTPermitNumber: {
                                type: "string",
                            },
                            location: {
                                type: "string",
                            },
                            cityDistrictNone: {
                                type: "string",
                            },
                            buildDate: {
                                type: "string",
                                format: "optionalCalendar",
                            },
                            classAndType: {
                                type: "string",
                            },
                            haglFeet: {
                                type: ["string", "number"],
                                format: "numeric",
                            },
                            displaySpaceSqFt: {
                                type: ["string", "number"],
                                format: "numeric",
                            },
                            countyIdNumber: {
                                type: "string",
                            },
                            displayIsIlluminated: {
                                type: ["string", "boolean"],
                                format: "optionalConditional",
                            },
                            originalCost: {
                                type: ["string", "number"],
                                format: "numeric",
                            },
                        },
                        // required: [
                        //   "DOTPermitNumber",
                        //   "panelNumbers",
                        //   "location",
                        //   "cityDistrictNone",
                        //   "buildDate",
                        //   "classAndType",
                        //   "haglFeet",
                        //   "displayIsIlluminated",
                        //   "displaySpaceSqFt",
                        //   "originalCost",
                        // ],
                    },
                },
            },
        },
    ],
};
