"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const affirmation = {
    type: "object",
    required: ["preparerTitle", "preparerSignatureImage"],
    properties: {
        preparerEmail: {
            type: "string",
        },
        preparerTitle: {
            type: "string",
            minLength: 1,
        },
        preparerSignatureImage: {
            type: "object",
            required: ["signatureUuid"],
            properties: {
                signatureUuid: {
                    type: "string",
                },
            },
        },
    },
};
exports.default = affirmation;
