"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const getScheduleAData = (filingYear) => {
    const getGridItems = (yearCount) => {
        const years = new Array(yearCount).fill(filingYear).map((year, index) => {
            return year - (index + 1);
        });
        const entries = [...years, "Prior"];
        return entries.reduce((acc, next) => {
            return [
                ...acc,
                {
                    year: next.toString(),
                    additions: 0,
                    deletions: 0,
                    prior: 0,
                },
            ];
        }, []);
    };
    const scheduleAData = {
        group1: {
            grid: getGridItems(16),
        },
        group2: {
            constructionInProgress: 0,
        },
        group3: {
            grid: getGridItems(7),
        },
        group4: {
            grid: getGridItems(4),
        },
        group5: {
            grid: getGridItems(14),
        },
        group6: {
            capitalizationThreshold: 0,
            grid: getGridItems(8),
        },
        group7: {
            supplies: 0,
            fuelsHeld: 0,
            parts: 0,
            restaurantHotelItems: 0,
            rentalItems: 0,
            other: 0,
        },
        group8: [],
    };
    return scheduleAData;
};
exports.default = getScheduleAData;
