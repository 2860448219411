import { camelCase } from "lodash";
import { config as firebaseQa } from "./qa.firebaseConfig";
import { config as firebaseUat } from "./uat.firebaseConfig";
import { config as firebaseProduction } from "./production.firebaseConfig";

/* eslint no-useless-escape: 0 */
const previewRegExp =
  /([^ ]*taxscribe-qa-bpp-app--pr[0-9]{1,3}\-.+?\.\web\.app)/;

const hostname = window.location.hostname;
const getCookieName = (): string => {
  const prefix = "pro_auth";
  const domain = hostname.split(".").slice(1).join("_");

  return camelCase(`${prefix}_${domain}`);
};

const detectStage = (): "production" | "uat" | "qa" | "development" | "preview" => {
  const { hostname } = window.location;

  if (hostname === "bpp.taxscribe.app") {
    return "production";
  }

  if (hostname === "qa-bpp.taxscribe.app") {
    return "qa";
  }

  if (hostname === "uat-bpp.taxscribe.app") {
    return "uat";
  }

  if (hostname.match(previewRegExp)) {
    return "preview";
  }

  return "development";
};

const formGroup = "BPP";

const productionStates = [
  "North Carolina",
  "Michigan",
  "Georgia",
];

const uatStates = [
  ...productionStates,
  "Texas",
];

const qaStates = [
  ...uatStates,
];

const developmentStates = [
  ...qaStates,
];

const configOpts = {
  production: {
    formGroup,
    fireSubApi: "https://sso-api.pro.taxscribe.app/",
    firebaseConfig: firebaseProduction,
    baseUri: "https://bpp.taxscribe.app",
    clientUri: "https://bpp.taxscribe.app",
    taxbaseUri: "https://taxbase.taxscribe.app/",
    professionalUri: "https://pro.taxscribe.app/",
    authCookieName: getCookieName(),
    states: productionStates,
  },
  uat: {
    formGroup,
    fireSubApi: "https://uat-sso-api.pro.taxscribe.app/",
    firebaseConfig: firebaseUat,
    baseUri: "https://uat-bpp.taxscribe.app",
    clientUri: "https://uat-bpp.taxscribe.app",
    taxbaseUri: "https://uat-taxbase.taxscribe.app/",
    professionalUri: "https://uat-pro.taxscribe.app/",
    authCookieName: getCookieName(),
    states: uatStates,
  },
  qa: {
    formGroup,
    fireSubApi: "https://qa-sso-api.pro.taxscribe.app/",
    firebaseConfig: firebaseQa,
    baseUri: "https://qa-bpp.taxscribe.app",
    clientUri: "https://qa-bpp.taxscribe.app",
    taxbaseUri: "https://qa-taxbase.taxscribe.app/",
    professionalUri: "https://qa-pro.taxscribe.app/",
    authCookieName: getCookieName(),
    states: qaStates,
  },
  preview: {
    formGroup,
    fireSubApi: "https://qa-sso-api.pro.taxscribe.app/",
    firebaseConfig: firebaseQa,
    baseUri: "",
    clientUri: "",
    taxbaseUri: "",
    professionalUri: "",
    authCookieName: getCookieName(),
    states: uatStates,
  },
  development: {
    formGroup,
    fireSubApi: "http://localhost:4001/",
    firebaseConfig: {
      ...firebaseQa,
      projectId: "taxscribe-dev",
    },
    baseUri: "http://localhost:3000",
    clientUri: "http://localhost:3000",
    taxbaseUri: "http://localhost:4002/",
    professionalUri: "http://localhost:6010/",
    authCookieName: getCookieName(),
    states: developmentStates,
  },
};

const releaseStage = detectStage();
const devStages = ["uat", "qa", "preview", "development"];
const devMode = devStages.indexOf(releaseStage) >= 0;
const config = { ...configOpts[releaseStage], releaseStage, devMode };

export default config;
