import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import config from "./hostnames";
// import { getAnalytics } from "firebase/analytics";
// import { getFunctions } from "firebase/functions";

const { firebaseConfig } = config;
const firebaseApp = initializeApp(firebaseConfig);

// Initialize auth && firestore with the 'firebaseApp' property
export const auth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp);
// export const functions = getFunctions(firebaseApp, "us-east1");
// export const analytics = getAnalytics(firebaseApp);

export default firebaseApp;
