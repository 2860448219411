"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tangibleProperty = [
    {
        required: ["tangiblePropertyReported"],
        properties: {
            tangiblePropertyReported: {
                enum: [true],
            },
        },
    },
    {
        required: ["tangiblePropertyReported", "tangiblePropertyExplanation"],
        properties: {
            tangiblePropertyReported: {
                enum: [false],
            },
            tangiblePropertyExplanation: {
                type: "string",
                minLength: 1,
                description: "Explanation For Why Property Wasn't Reported",
            },
        },
    },
];
exports.default = tangibleProperty;
