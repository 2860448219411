"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const sectionL = {
    type: "object",
    anyOf: [
        {
            required: ["isManufacturer"],
            properties: {
                isManufacturer: {
                    enum: [false],
                },
            },
        },
        {
            required: ["isManufacturer", "manufacturerAttachment"],
            properties: {
                isManufacturer: {
                    enum: [true],
                },
                manufacturerAttachment: {
                    $ref: "#/$defs/attachment",
                },
            },
        },
        {
            required: ["isManufacturer", "leaseEquipmentInfo"],
            properties: {
                isManufacturer: {
                    enum: [true],
                },
                leaseEquipmentInfo: {
                    type: ["array", "null"],
                    default: [],
                    title: "The leaseEquipmentInfo Schema",
                    items: {
                        type: "object",
                        minLength: 1,
                        required: [
                            "name",
                            "address",
                            "city",
                            "zipCode",
                            "stateAbbr",
                            "leaseNumber",
                            "location",
                            "equipmentType",
                            "monthlyRental",
                            "firstServiceYear",
                            "manufactureCost",
                            "originalSellingPrice",
                        ],
                        properties: {
                            leasePeriod: {
                                minimum: 1,
                                type: "number",
                            },
                            leaseNumber: {
                                $ref: "#/$defs/nonEmptyString",
                            },
                            equipmentType: {
                                $ref: "#/$defs/nonEmptyString",
                            },
                            location: {
                                $ref: "#/$defs/nonEmptyString",
                            },
                            monthlyRental: {
                                minimum: 1,
                                type: "number",
                            },
                            firstServiceYear: {
                                type: "string",
                                format: "year",
                            },
                            manufactureCost: {
                                minimum: 1,
                                type: "number",
                            },
                            originalSellingPrice: {
                                minimum: 1,
                                type: "number",
                            },
                            name: {
                                $ref: "#/$defs/nonEmptyString",
                            },
                            address: {
                                $ref: "#/$defs/nonEmptyString",
                            },
                            city: {
                                $ref: "#/$defs/nonEmptyString",
                            },
                            zipCode: {
                                type: "string",
                                format: "zipCode",
                            },
                            stateAbbr: {
                                type: "string",
                                format: "state",
                            },
                        },
                    },
                },
            },
        },
    ],
};
exports.default = sectionL;
