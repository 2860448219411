"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const otherOperatingBusinesses = [
    {
        required: ["otherOperatingBusinesses"],
        properties: {
            otherOperatingBusinesses: {
                enum: [false],
            },
        },
    },
    {
        required: ["otherOperatingBusinesses", "otherOperatingBusinessesList"],
        properties: {
            otherOperatingBusinesses: {
                enum: [true],
            },
            otherOperatingBusinessesList: {
                type: "array",
                items: {
                    type: "object",
                    properties: {
                        name: {
                            $ref: "#/$defs/nonEmptyString",
                        },
                    },
                    required: ["name"],
                },
            },
        },
    },
];
exports.default = otherOperatingBusinesses;
