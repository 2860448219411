import { FC, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSignin } from "@reasongcp/react-fire-sub";
import { Container, Row, Col, Card } from "react-bootstrap";
import { TaxScribeLogo } from "components/AppImages";
import CountyMessage from "components/CountyMessage";

import { LoginForm } from "components/Login";
import EmailHelp from "components/EmailHelp";
import { useJurisdictionContext } from "@taxscribe/ui";

const LoginPage: FC = () => {
  const signin = useSignin();
  const { name, webPath } = useJurisdictionContext();
  const navigate = useNavigate();

  const [error, setError] = useState("");

  const year = new Date().getFullYear();
  const defaultLogin = {
    email: "",
    password: "",
  };

  const handleLogin = async (values: any) => {
    const { email, password } = values;

    try {
      await signin(email, password);
      navigate(`/${webPath}/dashboard`, { replace: true });
    } catch (err) {
      setError("Invalid Email or Password");
      console.error(err);
    }
  };

  return (
    <main className="bg-bpp-primary text-white d-flex h-100 text-white min-vh-100 align-items-center">
      <Container fluid="xl">
        <Row className="g-2 justify-content-md-center flex-md-row-reverse mt-sm-3 mt-lg-0">
          <CountyMessage />
          <Col
            lg={5}
            className="d-flex justify-content-center"
          >
            <Card className="p-5 text-white bg-county-dark">
              <Card.Body>
                <TaxScribeLogo />
                <h1 className="login-heading my-4 h3 fw-light text-center text-capitalize">
                  {name} <br /> Business Personal Property Login
                </h1>
                {error ? <p>{error}</p> : null}
                <LoginForm
                  initialValues={defaultLogin}
                  submitHandler={handleLogin}
                />
                <Row>
                  <Col className="text-center">
                    <p className="mt-3">
                      Don&apos;t have an Account?&nbsp;
                      <Link
                        to={`/${webPath}/register`}
                        className="text-white"
                      >
                        <strong>Register</strong>
                      </Link>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <Link
                      to={`/${webPath}/reset`}
                      className="text-white"
                    >
                      <strong>Forgot your password?</strong>
                    </Link>
                  </Col>
                </Row>
                <EmailHelp />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="pb-lg-4 pt-lg-5 pb-3 align-self-center">
            <p className="text-center mb-0">
              {" "}
              &copy; {year} Reason Consulting Corporation
            </p>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default LoginPage;
