"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const aircraftEntry = {
    type: "object",
    required: [
        "year",
        "body",
        "make",
        "model",
        "tailNumber",
    ],
    properties: {
        year: {
            type: "string",
            format: "year",
        },
        body: {
            $ref: "#/$defs/nonEmptyString",
        },
        make: {
            $ref: "#/$defs/nonEmptyString",
        },
        model: {
            $ref: "#/$defs/nonEmptyString",
        },
        tailNumber: {
            $ref: "#/$defs/nonEmptyString",
        },
        cost: {
            type: ["number", "null"],
        },
        yearAcquired: {
            type: "string",
            format: "optionalYear",
        },
        airport: {
            type: "string",
        },
        hangarNumber: {
            type: "string",
        },
    },
};
const aircraft = {
    type: "object",
    oneOf: [
        {
            required: ["needToAdd"],
            properties: {
                needToAdd: {
                    enum: [false],
                },
            },
        },
        {
            required: ["needToAdd", "aircraft"],
            properties: {
                needToAdd: {
                    enum: [true],
                },
                aircraft: {
                    type: "array",
                    items: aircraftEntry,
                    minItems: 1,
                },
            },
        },
    ],
};
exports.default = aircraft;
