"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    type: "object",
    anyOf: [
        {
            properties: {
                ownsSeparatelyScheduledProperty: {
                    enum: [false, "no"],
                },
            },
            required: ["ownsSeparatelyScheduledProperty"],
        },
        {
            properties: {
                ownsSeparatelyScheduledProperty: {
                    enum: [true, "yes"],
                },
                d1Attachment: {
                    $ref: "#/$defs/attachment",
                },
            },
            required: ["d1Attachment", "ownsSeparatelyScheduledProperty"],
        },
        {
            properties: {
                ownsSeparatelyScheduledProperty: {
                    enum: [true, "yes"],
                },
                d1s: {
                    type: "array",
                    minItems: 1,
                    items: {
                        type: ["null", "object"],
                        properties: {
                            description: {
                                type: "string",
                            },
                            estimatedValue: {
                                type: ["string", "number"],
                                format: "numeric",
                            },
                        },
                    },
                },
            },
            required: ["d1s", "ownsSeparatelyScheduledProperty"],
        },
    ],
};
