import React, { FC, useCallback } from "react";
import { useAuth, useSignOut } from "@reasongcp/react-fire-sub";
import { Container, Row, Col, Button, Card, Nav } from "react-bootstrap";

import emailIcon from "images/email-icon.png";
import TSBusLogo from "images/logos/taxscribe-bus-logo.png";
import { sendEmailVerification } from "firebase/auth";
import { useAppToastContext } from "@taxscribe/ui";
import { useNavigate } from "react-router-dom";

const VerifyPage: FC = () => {
  const signout = useSignOut();
  const navigate = useNavigate();
  const user = useAuth();
  const { addToastMessage } = useAppToastContext();

  const sendEmail = useCallback(async () => {
    if (!user) return;
    try {
      await sendEmailVerification(user);
      addToastMessage({
        type: "success",
        header: "Email Sent",
        body: "An Email has been sent to your address",
      });
    } catch (e) {
      console.error(e);
      addToastMessage({
        type: "error",
        header: "Error",
        body: "Error sending email",
      });
    }
  }, [user, addToastMessage]);

  const handleSignOut = async () => {
    try {
      await signout();
      navigate("/home");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <main>
      <Container fluid>
        <Row className="justify-content-center align-items-center min-vh-100">
          <Col
            lg={4}
            className="my-auto text-center align-self-center"
          >
            <Card className="p-lg-3">
              <Card.Body>
                <img
                  alt="email-icon"
                  className="my-4 mx-auto d-block"
                  src={emailIcon}
                  width="80"
                  height="auto"
                />{" "}
                <h1 className="display-6">Verify Your Account</h1>
                <p className="lead">
                  The following email has not been verified:
                  <br />
                  {user?.email}
                </p>
                <p className="lead mb-1">
                  Please click the following link to resend the verification
                  email
                </p>
                <Button
                  onClick={sendEmail}
                  variant="link"
                  className="text-info"
                  size="lg"
                >
                  <strong>Resend Verification Email</strong>
                </Button>
                <hr />
                <img
                  alt="TaxScribe Business Logo"
                  className="mt-4 mx-auto d-block"
                  src={TSBusLogo}
                  width="250"
                  height="auto"
                />{" "}
                <hr />
                <p>
                  <small>
                    The verification email should arrive within five minutes, no
                    later than one hour. If it’s been over an hour and the email
                    isn’t in your SPAM folder, please email the following
                    support inbox:{" "}
                    <a href="mailto:hello@taxscribe.com">hello@taxscribe.com</a>
                  </small>
                </p>
                <Nav.Link
                  className="text-uppercase me-2"
                  onClick={handleSignOut}
                >
                  Logout
                </Nav.Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default VerifyPage;
