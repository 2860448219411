"use strict";
// Old required version. May need in the future(?).
// const situsInformation = {
//   required: ["taxSitus"],
//   if: {
//     properties: {
//       taxSitus: {
//         enum: ["city"],
//       },
//     },
//   },
//   then: {
//     required: ["situsCity"],
//     properties: {
//       situsCity: {
//         type: "string",
//         minLength: 1,
//       },
//     },
//   },
// };
Object.defineProperty(exports, "__esModule", { value: true });
const situsInformation = {
    taxSitus: {
        type: "string",
    },
    situsCity: {
        type: "string",
    },
};
exports.default = situsInformation;
