"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    {
        properties: {
            shortTermRental: {
                enum: [false, "no"],
            },
        },
        required: ["shortTermRental"],
    },
    {
        properties: {
            shortTermRental: {
                enum: [true, "yes"],
            },
            numberVehicles: {
                $ref: "#/$defs/nonZeroPositiveInteger",
            },
        },
        required: ["shortTermRental", "numberVehicles"],
    },
];
