"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vehicleEntry = {
    type: "object",
    required: [
        "year",
        "body",
        "make",
        "model",
        "vin",
        "plateNumber",
    ],
    properties: {
        year: {
            type: "string",
            format: "year",
        },
        body: {
            $ref: "#/$defs/nonEmptyString",
        },
        make: {
            $ref: "#/$defs/nonEmptyString",
        },
        model: {
            $ref: "#/$defs/nonEmptyString",
        },
        vin: {
            $ref: "#/$defs/nonEmptyString",
        },
        cost: {
            type: "number",
        },
        yearAcquired: {
            type: "string",
            format: "optionalYear",
        },
        length: {
            type: "number",
        },
        width: {
            type: "number",
        },
        plateNumber: {
            $ref: "#/$defs/nonEmptyString",
        },
        titleNumber: {
            type: "string",
        },
        registrationIssueDate: {
            type: "string",
            format: "optionalCalendar",
        },
    },
};
const vehiclePermanentPlate = {
    type: "object",
    oneOf: [
        {
            required: ["needToAdd"],
            properties: {
                needToAdd: {
                    enum: [false],
                },
            },
        },
        {
            required: ["needToAdd", "vehicles"],
            properties: {
                needToAdd: {
                    enum: [true],
                },
                vehicles: {
                    type: "array",
                    items: vehicleEntry,
                    minItems: 1,
                },
            },
        },
    ],
};
exports.default = vehiclePermanentPlate;
