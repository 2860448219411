"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const affirmation = {
    type: "object",
    required: [
        "firstName",
        "lastName",
        "phoneNumber",
        "signature",
    ],
    properties: {
        firstName: {
            $ref: "#/$defs/nonEmptyString",
        },
        lastName: {
            $ref: "#/$defs/nonEmptyString",
        },
        phoneNumber: {
            type: "string",
            format: "phone",
        },
        signature: {
            type: "string",
            format: "email",
            signatureEmailMatches: true,
        },
        email: {
            type: "string",
        },
    },
};
exports.default = affirmation;
