import { gql } from "@apollo/client";
import { PT50PF } from "@taxscribe/form";
import { fetchPrePopData } from "@taxscribe/ui";
import { cleanFormData, deepPurgeValues } from "@taxscribe/utils";
import createFormPage, { FormDataInjector } from "components/shared/createFormPage";
import createFormView from "components/shared/createFormView";
import { getAuth } from "firebase/auth";
import { merge } from "lodash";

const CREATE_FORM = gql`
  mutation CreatePT50PF(
    $app: String!
    $businessName: String
    $source: String!
    $jurisdiction: String!
    $formData: PT50PFDataInput!
  ) {
    createPT50PF(
      app: $app
      source: $source
      formData: $formData
      businessName: $businessName
      jurisdiction: $jurisdiction
    )
  }
`;

const formType = "PT50PF";
const PT50PFReport = createFormView({
  formType,
  Form: PT50PF,
  formName: "PT50PF",
  header: "GA PT50PF",
  creationQuery: CREATE_FORM,
});

const injectFormData: FormDataInjector = ({
  formData,
  formUuid,
  currentSubmission,
}) => {
  if (!formData) return formData;
  if (!currentSubmission?.id) {
    const user = getAuth().currentUser;
    const email = user?.email;
    const extraState: Record<string, any> = {
      pt50pf: { affirmation: { preparerEmail: email || undefined } },
    };
    return deepPurgeValues(null, merge(cleanFormData(formData), extraState));
  }

  return deepPurgeValues(null, cleanFormData(formData));
};

const GAPT50PFPage = createFormPage({
  formType,
  injectFormData,
  ViewForm: PT50PFReport,
  prePopForm: fetchPrePopData(formType),
});

export default GAPT50PFPage;
