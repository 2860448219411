"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const soldAircraft = {
    if: {
        properties: {
            soldAircraft: {
                const: true,
            },
        },
    },
    then: {
        required: ["purchaser"],
        properties: {
            purchaser: {
                type: "object",
                required: ["name", "street", "city", "state", "zipCode", "salePrice", "date"],
                properties: {
                    name: {
                        $ref: "#/$defs/nonEmptyString",
                    },
                    street: {
                        $ref: "#/$defs/nonEmptyString",
                    },
                    city: {
                        $ref: "#/$defs/nonEmptyString",
                    },
                    state: {
                        $ref: "#/$defs/nonEmptyString",
                    },
                    zipCode: {
                        $ref: "#/$defs/nonEmptyString",
                    },
                    salePrice: {
                        type: "number",
                        minimum: 1,
                    },
                    description: {
                        $ref: "#/$defs/nonEmptyString",
                    },
                    date: {
                        type: "string",
                        format: "calendar",
                    },
                },
            },
        },
    },
};
exports.default = soldAircraft;
