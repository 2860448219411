"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    {
        properties: {
            group1: {
                type: "object",
                properties: {
                    na: {
                        enum: [true, "yes"],
                    },
                },
                required: ["na"],
            },
        },
    },
    {
        properties: {
            group1: {
                type: "object",
                properties: {
                    na: {
                        enum: [false, "no"],
                    },
                    grid: {
                        $ref: "#/$defs/scheduleAGrid",
                    },
                },
                required: ["na", "grid"],
            },
        },
    },
];
