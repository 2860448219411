import { FC } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ErrorBoundary: FC<{}> = () => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(-1);
  };

  return (
    <>
      <Row>
        <Col className="d-flex justify-content-center mt-5">
          <h3>
            There is an internal issue occurring. Please reach out to
            hello@taxscribe.com for further assistance.
          </h3>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <Button
            type="button"
            variant="info"
            onClick={handleOnClick}
          >
            Return
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ErrorBoundary;
