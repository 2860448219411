"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable max-len */
const sectionJ = {
    type: "object",
    default: {},
    title: "The sectionJ Schema",
    required: ["personalPropertyInfo"],
    properties: {
        personalPropertyInfo: {
            type: "array",
            default: [],
            title: "The personalPropertyInfo Schema",
            items: {
                type: "object",
                default: {},
                title: "A Schema",
                required: [
                    "leaseNumber",
                    "description",
                    "leaseTermInMonths",
                    "monthlyRental",
                    "firstServiceYear",
                    "sellingPriceNew",
                    "name",
                    "address",
                    "city",
                    "zipCode",
                    "stateAbbr",
                ],
                properties: {
                    leaseNumber: {
                        $ref: "#/$defs/nonEmptyString",
                    },
                    description: {
                        $ref: "#/$defs/nonEmptyString",
                    },
                    leaseTermInMonths: {
                        minimum: 1,
                        type: "integer",
                    },
                    monthlyRental: {
                        type: "integer",
                        minimum: 1,
                    },
                    firstServiceYear: {
                        type: "string",
                        format: "year",
                    },
                    sellingPriceNew: {
                        type: "integer",
                        minimum: 1,
                    },
                    name: {
                        $ref: "#/$defs/nonEmptyString",
                    },
                    address: {
                        $ref: "#/$defs/nonEmptyString",
                    },
                    city: {
                        $ref: "#/$defs/nonEmptyString",
                    },
                    zipCode: {
                        type: "string",
                        format: "zipCode",
                    },
                    stateAbbr: {
                        type: "string",
                        format: "state",
                    },
                },
            },
        },
    },
};
exports.default = sectionJ;
