/* eslint-disable max-len */
import React, { FC, PropsWithChildren, ReactNode } from "react";
import { Link } from "react-router-dom";
import { useCurrentNavRoute } from "@taxscribe/form";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Container, Row } from "react-bootstrap";

import DeadlineAlert from "components/GenericDeadlineAlert";
import {
  useJurisdictionContext,
  useMostRecentFormPeriodForRoute,
} from "@taxscribe/ui";
import DeadlineGuard, { GuardMessageProps } from "components/DeadlineGuard";
import GenericDeadlinePastPage from "components/GenericDeadlinePastPage";
import PDFView from "./PDFView";
import PageSpinner from "./PageSpinner";

export interface GenericFormWrapperProps {
  SideNav?: FC;
  header: string;
  noPdf?: boolean;
  formType: string;
  formUuid?: string;
  filingYear?: number | string;
  headerActions?: React.ReactNode | null;
  FormGuard?: FC<PropsWithChildren>;
  GuardMessage?: ReactNode | FC<GuardMessageProps>;
}

interface FormSegmentHeaderProps {
  header: string;
}

const FormSegmentHeader: FC<FormSegmentHeaderProps> = ({ header }) => {
  const [currentNav] = useCurrentNavRoute() || [];

  if (currentNav) {
    return <h1 className="fw-light h3">{currentNav.text}</h1>;
  }

  return <h1 className="fw-light h3">{header}</h1>;
};

const GenericFormWrapper: FC<PropsWithChildren<GenericFormWrapperProps>> = ({
  noPdf,
  header,
  SideNav,
  children,
  formType,
  formUuid,
  filingYear,
  headerActions,
  FormGuard = DeadlineGuard,
  GuardMessage = GenericDeadlinePastPage,
}) => {
  const { formPeriod, loading, called } = useMostRecentFormPeriodForRoute({});
  const currentYear = formPeriod?.filingYear || new Date().getFullYear();
  const { webPath } = useJurisdictionContext();

  if (!called || loading) {
    return <PageSpinner />;
  }

  if (!noPdf && filingYear && Number(filingYear) !== currentYear) {
    return <PDFView uuid={formUuid || ""} />;
  }

  return (
    <div role="main">
      <Container
        fluid
        className="p-3 mb-5"
      >
        <Row>
          {SideNav && (
            <Col
              sm={2}
              md={3}
            >
              <FormGuard formType={formType}>
                <SideNav />
              </FormGuard>
            </Col>
          )}
          <Col
            sm={SideNav ? 10 : 12}
            md={SideNav ? 9 : 12}
          >
            <Container fluid="xl">
              <FormGuard
                formType={formType}
                guardForExisting
                GuardMessage={DeadlineAlert}
              />
              <FormGuard formType={formType}>
                <Row className="mb-1">
                  <Col md={10}></Col>
                  <Col
                    md={2}
                    className="text-end"
                  >
                    <Link
                      to={`/${webPath}/dashboard`}
                      className="align-self-center text-nowrap"
                    >
                      <Button
                        variant="link"
                        size="sm"
                        className="text-decoration-none"
                      >
                        <FontAwesomeIcon
                          icon={faHome}
                          size="sm"
                          className="me-2"
                        />
                        <strong>Dashboard</strong>
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </FormGuard>
              <Row>
                <Col>
                  <Card>
                    <Card.Header className="d-flex align-items-center justify-content-between">
                      <FormSegmentHeader header={header} />
                      <FormGuard formType={formType}>{headerActions}</FormGuard>
                    </Card.Header>
                    <FormGuard
                      formType={formType}
                      GuardMessage={GuardMessage}
                    >
                      <Card.Body>{children}</Card.Body>
                    </FormGuard>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GenericFormWrapper;
