/* eslint-disable max-len */
import React, { FC } from "react";
import Image from "react-bootstrap/Image";
import TSQuill from "../../images/color-quill.png";
import Footer from "components/Footer";
import Navigation from "components/Navigation";
import { useJurisdictionContext } from "@taxscribe/ui";
import config from "config/hostnames";
import ProAlert from "components/ProAlert";

const NoEfile: FC = () => {
  const { jurisdictionName, jurisdictionType } = useJurisdictionContext();
  return (
    <div id="wrapper">
      <Navigation />
      <ProAlert pageTitle="Upgrade to TaxScribe Pro">
        <p className="mb-0">
          {jurisdictionName} {jurisdictionType} does not currently accept E-File
          forms. However,{" "}
          <a
            className="fw-bold text-pro-teal"
            href={config.professionalUri}
          >
            <Image
              width="10"
              height="auto"
              src={TSQuill}
              className="ms-1"
            />
            TaxScribe Pro
          </a>{" "}
          is available to help generate your form.
        </p>
      </ProAlert>
      <Footer />
    </div>
  );
};

export default NoEfile;
