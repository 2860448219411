"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.asNumber = void 0;
const asNumber = (value, defaultValue = 0) => {
    const numValue = Number(value);
    if (isNaN(numValue)) {
        return defaultValue;
    }
    return numValue;
};
exports.asNumber = asNumber;
