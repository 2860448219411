"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    {
        properties: {
            group7: {
                type: "object",
                properties: {
                    supplies: {
                        type: ["string", "number"],
                        format: "numeric",
                    },
                    fuelsHeld: {
                        type: ["string", "number"],
                        format: "numeric",
                    },
                    parts: {
                        type: ["string", "number"],
                        format: "numeric",
                    },
                    restaurantHotelItems: {
                        type: ["string", "number"],
                        format: "numeric",
                    },
                    rentalItems: {
                        type: ["string", "number"],
                        format: "numeric",
                    },
                    other: {
                        type: ["string", "number"],
                        format: "numeric",
                    },
                },
            },
        },
    },
];
