import { Jurisdiction, JurisdictionOption } from "types/jurisdiction";
import checkEfileDeep from "./checkEfileDeep";

const sortByHasEfile =
  (jurisdictions: Jurisdiction[] | null) =>
    (countyA: JurisdictionOption, countyB: JurisdictionOption) => {
      if (
        checkEfileDeep(jurisdictions, countyA) &&
      !checkEfileDeep(jurisdictions, countyB)
      ) {
        return -1;
      }
      return 0;
    };

export default sortByHasEfile;
