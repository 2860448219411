import { fetchPrePopData as ogFetchPrePopData } from "@taxscribe/ui";

const fetchPrePopData =
  <FormShape>(formType: string) =>
    async (args: Parameters<ReturnType<typeof ogFetchPrePopData>>[0]) => {
      const results = await ogFetchPrePopData<FormShape>(formType)(args);
      return results;
    };

export default fetchPrePopData;
