"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const leasedPropertyInfo = {
    type: ["object", "null"],
    default: {},
    title: "The leasedPropertyInfo Schema",
    required: [
        "address",
        "city",
        "zipCode",
        "stateAbbr",
        "rentalStartDate",
        "leaseExpirationDate",
        "squareFeetOccupied",
        "monthlyRentalAmount",
        "renewalOptions",
        "tenantPaidExpenses",
    ],
    properties: {
        address: {
            $ref: "#/$defs/nonEmptyString",
        },
        city: {
            $ref: "#/$defs/nonEmptyString",
        },
        zipCode: {
            $ref: "#/$defs/zipCode",
        },
        stateAbbr: {
            $ref: "#/$defs/stateAbbr",
        },
        rentalStartDate: {
            type: "string",
            format: "calendar",
        },
        leaseExpirationDate: {
            type: "string",
            format: "calendar",
        },
        squareFeetOccupied: {
            type: "integer",
            default: 0,
            title: "The squareFeetOccupied Schema",
        },
        monthlyRentalAmount: {
            type: "integer",
            default: 0,
            title: "The monthlyRentalAmount Schema",
        },
        renewalOptions: {
            type: "boolean",
            default: false,
            title: "The renewalOptions Schema",
        },
        tenantPaidExpenses: {
            $ref: "#/$defs/nonEmptyString",
        },
    },
};
exports.default = leasedPropertyInfo;
