"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const businessInfoData = {
    name: "",
    dbaName: "",
    dbaCheckbox: false,
    accountPrimary: "",
    accountSecondary: "",
    jurisdictionName: "",
    year: "",
    mailingAddress: {
        street: "",
        city: "",
        state: "",
        zipcode: "",
        addressCheckbox: false,
    },
    physicalAddress: {
        street: "",
        city: "",
        state: "",
        zipcode: "",
    },
    ownedBy: "",
    previousName: "",
    personalPropertyCounties: "",
    principalBusiness: "",
    sicCode: "",
    naicsCode: "",
    startedDate: "",
    fiscalYearEnd: "",
    typeOf: "",
    typeOfExplanation: "",
    category: "",
    categoryExplanation: "",
    outOrInBusiness: false,
    outOfBusiness: {
        reason: "",
        ceased: "",
        buyer: "",
        buyerAddress: "",
        buyerPhone: "",
    },
};
exports.default = businessInfoData;
