"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const physicalLocation = {
    type: "object",
    required: [
        "allPropertiesPopulatedAddress",
        "addressOne",
        "city",
        "state",
        "zipCode",
    ],
    properties: {
        allPropertiesPopulatedAddress: {
            type: "boolean",
        },
        addressOne: {
            $ref: "#/$defs/nonEmptyString",
        },
        addressTwo: {
            type: "string",
        },
        city: {
            $ref: "#/$defs/nonEmptyString",
        },
        state: {
            $ref: "#/$defs/nonEmptyString",
        },
        zipCode: {
            $ref: "#/$defs/nonEmptyString",
            format: "zipCode",
        },
    },
};
exports.default = physicalLocation;
