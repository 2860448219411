import React, { FC } from "react";

import Image from "./Image";
import { TaxpayerNavBar, useJurisdictionContext } from "@taxscribe/ui";

interface NavBannerProps {
  fullSlug: string;
  name: string;
}

const NavBanner: FC<NavBannerProps> = ({ fullSlug, name }) => {
  return (
    <>
      <Image
        fallbackSrc="/images/seals/taxscribe-bus-logo.png"
        src={`/images/seals/${fullSlug.replace(/ /g, "-")}.png`}
        alt="County Seal"
        height="50"
        loading="lazy"
        className="me-2"
      />{" "}
      {name} Business Tax Portal
    </>
  );
};

const Navigation: FC = () => {
  const { fullSlug, name, webPath } = useJurisdictionContext();
  const logoutPath = `/${webPath}/login`;
  return (
    <TaxpayerNavBar
      logoutPath={logoutPath}
      banner={
        <NavBanner
          fullSlug={fullSlug}
          name={name}
        />
      }
    />
  );
};

export default Navigation;
