"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.manufacturedHomeData = exports.manufacturedHomeDataEntry = void 0;
exports.manufacturedHomeDataEntry = {
    parcelId: "",
    year: "",
    make: "",
    model: "",
    body: "",
    width: 0,
    length: 0,
    yearAcquired: "",
    cost: 0,
    vin: "",
    serialNumber: "",
    plateNumber: "",
    manufacturedHomePark: "",
    lotNumber: "",
    titleNumber: "",
    occupant: "",
    titleNameOne: "",
    titleNameTwo: "",
    movingPermitNumber: "",
    movingPermitIssueDate: "",
    buildingPermitNumber: "",
    buildingPermitIssueDate: "",
};
exports.manufacturedHomeData = {
    needToAdd: null,
    homes: [],
};
