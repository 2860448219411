"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const contactInformation = {
    type: "object",
    required: ["name", "phone"],
    properties: {
        name: {
            type: "string",
            minLength: 1,
        },
        phone: {
            format: "phone",
            type: "string",
        },
    },
};
exports.default = contactInformation;
