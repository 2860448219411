import { Link } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { ErrorMessage, Field, Form, Formik } from "formik";

export function RegistrationSuccess({
  redirectPath,
}: {
  redirectPath: string;
}) {
  return (
    <div className="text-center">
      <h2 className="display-6">Registration complete!</h2>
      <p>Check your email to activate your account</p>
      <Link to={redirectPath}>
        <Button
          variant="info"
          className="btn btn-info w-100 mb-3"
        >
          Go to Dashboard
        </Button>
      </Link>
    </div>
  );
}

export function RegistrationForm({ initialValues, submitHandler }: any) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitHandler}
    >
      {() => (
        <Form>
          <Row className="g-3 mb-3">
            <Col lg={6}>
              {/* eslint-disable-next-line */}
              <label
                htmlFor="firstName"
                className="form-label"
              >
                First Name
              </label>
              <Field
                name="firstName"
                type="text"
                className="form-control text-white bg-transparent"
                placeholder="First Name"
              />
              <ErrorMessage name="firstName" />
            </Col>
            <Col lg={6}>
              {/* eslint-disable-next-line */}
              <label
                htmlFor="lastName"
                className="form-label"
              >
                Last Name
              </label>
              <Field
                name="lastName"
                type="text"
                className="form-control text-white bg-transparent"
                placeholder="Last Name"
              />
              <ErrorMessage name="lastName" />
            </Col>
          </Row>
          <Row className="g-3 mb-3">
            <Col>
              {/* eslint-disable-next-line */}
              <label
                htmlFor="email"
                className="form-label"
              >
                Email Address
              </label>
              <Field
                name="email"
                type="email"
                className="form-control text-white bg-transparent"
                placeholder="Email Address"
              />
              <ErrorMessage name="email" />
            </Col>
          </Row>
          <Row className="g-3 mb-3">
            <Col lg={6}>
              {/* eslint-disable-next-line */}
              <label
                htmlFor="password"
                className="form-label"
              >
                Password
              </label>
              <Field
                name="password"
                label="Password"
                type="password"
                className="form-control text-white bg-transparent"
                placeholder="Password"
              />
              <ErrorMessage name="password" />
            </Col>
            <Col lg={6}>
              {/* eslint-disable-next-line */}
              <label
                htmlFor="passwordConfirmation"
                className="form-label"
              >
                Password Confirmation
              </label>
              <Field
                name="passwordConfirmation"
                label="Password Confirmation"
                type="password"
                className="form-control text-white bg-transparent"
                placeholder="Password Confirmation"
              />
              <ErrorMessage name="passwordConfirmation" />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Button
                type="submit"
                className="btn btn-info w-100 mb-3"
              >
                Create Account
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
