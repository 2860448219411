"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const attachment = {
    type: "object",
    properties: {
        fileName: {
            type: "string",
            description: "The file's name",
        },
        uuid: {
            type: "string",
            description: "The file's uuid",
        },
    },
};
const nullableAttachment = Object.assign(Object.assign({}, attachment), { type: ["object", "null"] });
const attachments = {
    attachment,
    nullableAttachment,
};
exports.default = attachments;
