"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constructionInProgress = [
    {
        type: "object",
        required: [
            "hasUnallocatedCosts",
            "hasConnectedTangiblePersonalProperty",
            "constructionInProgress",
        ],
        properties: {
            hasUnallocatedCosts: {
                enum: [true],
            },
            hasConnectedTangiblePersonalProperty: {
                enum: [true],
            },
            constructionInProgress: {
                type: "array",
                minItems: 1,
                items: {
                    type: "object",
                    required: ["description", "cost", "yearAcquired", "usefulLife"],
                    properties: {
                        description: {
                            type: "string",
                            minLength: 1,
                        },
                        cost: {
                            type: "number",
                            minimum: 1,
                        },
                        yearAcquired: {
                            type: "string",
                            format: "year",
                        },
                        usefulLife: {
                            type: "number",
                            minimum: 1,
                        },
                    },
                },
            },
        },
    },
    {
        type: "object",
        required: ["hasUnallocatedCosts", "hasConnectedTangiblePersonalProperty"],
        properties: {
            hasUnallocatedCosts: {
                enum: [true],
            },
            hasConnectedTangiblePersonalProperty: {
                enum: [false],
            },
        },
    },
    {
        type: "object",
        required: ["hasUnallocatedCosts"],
        properties: {
            hasUnallocatedCosts: {
                enum: [false],
            },
        },
    },
];
exports.default = constructionInProgress;
