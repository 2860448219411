import styled, { ThemeProvider } from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";
import { SUBContextProvider } from "@reasongcp/react-fire-sub";
import {
  AppToasts,
  AppToastProvider,
  EnvBanner,
  toastComponents,
  AppModalProvider,
  AppModal,
} from "@taxscribe/ui";
import { TaxbaseApolloProvider } from "@taxscribe/apollo-helpers";

import config from "config/hostnames";
import AppRoutes from "components/shared/AppRoutes";
import appColors from "config/appColors";

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const theme = {
  ...appColors,
};

export default function App() {
  return (
    <AppWrapper>
      <ThemeProvider theme={theme}>
        <AppToastProvider
          position="top-end"
          defaultTimeout={4000}
          ToastComponents={toastComponents}
        >
          <SUBContextProvider
            config={config.firebaseConfig}
            authCookieName={config.authCookieName}
            emulate={config.releaseStage === "development"}
          >
            <EnvBanner releaseStage={config.releaseStage} />
            <TaxbaseApolloProvider uri={config.taxbaseUri}>
              <AppModalProvider>
                <Router>
                  <AppRoutes />
                </Router>
                <AppModal />
              </AppModalProvider>
            </TaxbaseApolloProvider>
          </SUBContextProvider>
          <AppToasts />
        </AppToastProvider>
      </ThemeProvider>
    </AppWrapper>
  );
}
