"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.contactInformationData = void 0;
exports.contactInformationData = {
    firstName: "",
    lastName: "",
    suffix: "",
    middleName: "",
    additionalName: "",
    businessName: "",
    phoneType: "",
    phoneNumber: "",
    email: "",
};
