"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const environmentalExclusions = [
    {
        required: ["environmentalExclusions"],
        properties: {
            environmentalExclusions: {
                type: ["string", "boolean"],
                enum: ["no", false],
            },
        },
    },
    {
        required: ["environmentalExclusions", "exclusionsList"],
        properties: {
            environmentalExclusions: {
                enum: ["yes", true],
            },
            exclusionsList: {
                type: "array",
                title: "The exclusionsList Schema",
                minItems: 1,
                items: {
                    type: "object",
                    title: "exclusionListItem Schema",
                    properties: {
                        certificateNumber: {
                            $ref: "#/$defs/nonEmptyString",
                        },
                        dateOfIssuance: {
                            $ref: "#/$defs/nonEmptyString",
                        },
                        amount: {
                            type: "number",
                        },
                    },
                },
            },
        },
    },
];
exports.default = environmentalExclusions;
