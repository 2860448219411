/* eslint-disable max-len */
import { FC } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useJurisdictionContext } from "@taxscribe/ui";
import { ResetPasswordForm } from "./components";

import EmailHelp from "components/EmailHelp";
import UnauthenticatedLayout from "components/UnauthenticatedLayout";

const ResetPassword: FC = () => {
  const { name, fullSlug } = useJurisdictionContext();
  const year = new Date().getFullYear();

  return (
    <main>
      <div
        className={`d-flex h-100 text-white min-vh-100 ${fullSlug} bg-bpp-primary`}
      >
        <Container className="mt-5">
          <Row>
            <Col
              lg={8}
              className="d-flex p-3 mx-auto flex-column align-items-center"
            >
              <Card className={`p-5 text-white ${fullSlug} bg-county-dark`}>
                <Card.Body>
                  <UnauthenticatedLayout>
                    <h1 className="login-heading my-4 h3 fw-light text-center text-capitalize">
                      {name} <br /> Business Personal Property Reset
                    </h1>
                    <ResetPasswordForm />
                    <EmailHelp />
                  </UnauthenticatedLayout>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-lg-3">
            <Col className="pb-lg-4 pt-lg-5 pb-3">
              <p className="text-center mb-0">
                {" "}
                &copy; {year} Reason Consulting Corporation
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </main>
  );
};

export default ResetPassword;
