import React, { FC, PropsWithChildren, useCallback, useState } from "react";
import styled from "styled-components";
import { Alert, Image, Container, Row, Col } from "react-bootstrap";

import ProLogo from "../images/logos/taxscribe-pro-logo.png";

interface AlertProps {
  pageTitle: string;
  className?: string;
}

const StyledContainer = styled(Container)`
  a:hover {
    color: #2d809b !important;
  }
`;

const ProAlert: FC<PropsWithChildren<AlertProps>> = ({
  children,
  pageTitle,
  className,
}) => {
  const [show, setShow] = useState(true);
  const handleClose = useCallback(() => {
    setShow(false);
  }, [setShow]);

  if (show) {
    return (
      <>
        <StyledContainer
          fluid
          className="p-3"
        >
          <Row className="justify-content-center">
            <Col lg={10}>
              <Alert
                dismissible
                variant="light"
                onClose={handleClose}
                id="upgrade-pro-alert"
                className="rounded-4 text-dark shadow"
              >
                <div className="d-flex flex-column flex-md-row">
                  <div className="me-5">
                    <Image
                      className="mb-3 mb-md-0"
                      fluid
                      width="100"
                      height="auto"
                      src={ProLogo}
                    />
                  </div>
                  <div>
                    <h6 className="text-capitalize ls-1 fw-bold fs-3 ">
                      {pageTitle}
                    </h6>
                    <div>{children}</div>
                  </div>
                </div>
              </Alert>
            </Col>
          </Row>
        </StyledContainer>
      </>
    );
  }
  return null;
};

export default ProAlert;
