"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const b2Items = [
    {
        type: ["null", "object"],
        properties: {
            PropertyPk: {
                type: "string",
                minLength: 1,
            },
        },
        required: ["PropertyPk"],
    },
    {
        type: ["null", "object"],
        properties: {
            typeOf: {
                type: "string",
                minLength: 1,
            },
            year: {
                type: "string",
                format: "year",
            },
            make: {
                type: "string",
                minLength: 1,
            },
            model: {
                type: "string",
                minLength: 1,
            },
            lengthSize: {
                type: "string",
                minLength: 1,
            },
            registrationNumber: {
                type: "string",
                minLength: 1,
            },
            marinaName: {
                type: "string",
                // minLength: 1,
            },
            slipNumber: {
                type: "string",
            },
            slipExtension: {
                type: "string",
            },
            engineType: {
                type: "string",
                minLength: 1,
            },
            yearAcquired: {
                type: "string",
                format: "year",
            },
            originalCost: {
                $ref: "#/$defs/nonZeroPositiveInteger",
            },
        },
        required: [
            "typeOf",
            "year",
            "make",
            "model",
            "lengthSize",
            "registrationNumber",
            // "location",
            "engineType",
            "yearAcquired",
            "originalCost",
        ],
    },
    {
        type: ["null", "object"],
        properties: {
            typeOf: {
                type: "string",
                minLength: 1,
            },
            year: {
                type: "string",
                format: "year",
            },
            make: {
                type: "string",
                minLength: 1,
            },
            model: {
                type: "string",
                minLength: 1,
            },
            lengthSize: {
                type: "string",
                minLength: 1,
            },
            registrationNumber: {
                type: "string",
                minLength: 1,
            },
            location: {
                type: "string",
                // minLength: 1,
            },
            engineType: {
                type: "string",
                minLength: 1,
            },
            yearAcquired: {
                type: "string",
                format: "year",
            },
            originalCost: {
                $ref: "#/$defs/nonZeroPositiveInteger",
            },
        },
        required: [
            "typeOf",
            "year",
            "make",
            "model",
            "lengthSize",
            "registrationNumber",
            "location",
            "engineType",
            "yearAcquired",
            "originalCost",
        ],
    },
];
exports.default = [
    {
        properties: {
            watercraft: {
                enum: [false, "no"],
            },
            b2Attachment: {
                $ref: "#/$defs/nullableAttachment",
            },
        },
        required: ["watercraft"],
    },
    {
        properties: {
            watercraft: {
                enum: [true, "yes"],
            },
            b2Attachment: {
                $ref: "#/$defs/nullableAttachment",
            },
            b2s: {
                type: "array",
                minItems: 1,
                items: { anyOf: b2Items },
            },
        },
        required: ["watercraft", "b2s"],
    },
    {
        properties: {
            watercraft: {
                enum: [true, "yes"],
            },
            b2Attachment: {
                $ref: "#/$defs/attachment",
            },
        },
        required: ["watercraft", "b2Attachment"],
    },
];
