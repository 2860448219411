"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable max-len */
const moveIns = {
    type: "object",
    anyOf: [
        {
            properties: {
                hasMoveIns: {
                    enum: [false],
                },
            },
        },
        {
            properties: {
                hasMoveIns: {
                    enum: [true],
                },
                moveInsAttachment: {
                    $ref: "#/$defs/attachment",
                },
            },
        },
    ],
};
exports.default = moveIns;
