"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    type: "object",
    anyOf: [
        {
            required: ["acquisitionsDisposals"],
            properties: {
                acquisitionsDisposals: {
                    enum: [false, "no"],
                },
            },
        },
        {
            required: ["acquisitionsDisposals", "g1Attachment"],
            properties: {
                acquisitionsDisposals: {
                    enum: [true, "yes"],
                },
                g1Attachment: {
                    $ref: "#/$defs/attachment",
                },
            },
        },
        {
            required: ["acquisitionsDisposals", "g1s"],
            properties: {
                acquisitionsDisposals: {
                    enum: [true, "yes"],
                },
                g1s: {
                    type: "array",
                    minItems: 1,
                    items: {
                        type: "object",
                        properties: {
                            acquisitions: {
                                type: "string",
                            },
                            acquisitionOriginalCost: {
                                type: ["string", "number"],
                                format: "numeric",
                            },
                            disposals: {
                                type: "string",
                            },
                            yearAcquired: {
                                type: "string",
                                format: "year",
                            },
                            disposalsOriginalCost: {
                                type: ["string", "number"],
                                format: "numeric",
                            },
                        },
                        // anyOf: [
                        //   {
                        //     required: ["acquisitions", "acquisitionOriginalCost"],
                        //     maxProperties: 2,
                        //   },
                        //   {
                        //     required: [
                        //       "disposals",
                        //       "disposalsOriginalCost",
                        //       "yearAcquired",
                        //     ],
                        //     maxProperties: 3,
                        //   },
                        //   {
                        //     required: [
                        //       "acquisitions",
                        //       "acquisitionOriginalCost",
                        //       "disposals",
                        //       "disposalsOriginalCost",
                        //       "yearAcquired",
                        //     ],
                        //   },
                        // ],
                    },
                },
            },
        },
    ],
};
