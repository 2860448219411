"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    {
        properties: {
            group2: {
                type: "object",
                properties: {
                    constructionInProgress: {
                        type: ["string", "number"],
                        format: "numeric",
                    },
                },
            },
        },
    },
];
