"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFieldAs = void 0;
const lodash_1 = require("lodash");
/*
 * Utilizes lodash's get function to retrieve a value from an object.
 * If the value is not of the same type as the defaultValue, an error is thrown.
 * If suppressError is true, the defaultValue is returned instead.
 */
const getFieldAs = ({ obj, path, defaultValue, suppressError = false, }) => {
    const val = (0, lodash_1.get)(obj, path, defaultValue);
    const isExpectedType = typeof val === typeof defaultValue;
    if (!isExpectedType && !suppressError) {
        throw new Error(`Expected ${path} to be ${typeof defaultValue}, got ${typeof val}`);
    }
    if (!isExpectedType)
        return defaultValue;
    return val;
};
exports.getFieldAs = getFieldAs;
