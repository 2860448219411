"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const additionsOrTransfers = [
    {
        type: "object",
        required: ["hasAdditionsOrTransfers"],
        properties: {
            hasAdditionsOrTransfers: {
                enum: [false],
            },
        },
    },
    {
        type: "object",
        required: ["hasAdditionsOrTransfers", "additionsOrTransfers"],
        properties: {
            hasAdditionsOrTransfers: {
                enum: [true],
            },
            additionsOrTransfers: {
                type: "array",
                minItems: 1,
                items: {
                    type: "object",
                    required: [
                        "description",
                        "cost",
                        "yearAcquired",
                    ],
                    properties: {
                        description: {
                            $ref: "#/$defs/nonEmptyString",
                        },
                        cost: {
                            type: "number",
                            minimum: 1,
                        },
                        yearAcquired: {
                            type: "string",
                            format: "year",
                        },
                    },
                },
            },
        },
    },
];
exports.default = additionsOrTransfers;
