"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.roundToTwoFixedDecimal = exports.roundToTwo = void 0;
const roundToTwo = (num) => {
    return +(Math.round(Number(num + "e+2")) + "e-2");
};
exports.roundToTwo = roundToTwo;
const roundToTwoFixedDecimal = (num) => {
    return (0, exports.roundToTwo)(num).toFixed(2);
};
exports.roundToTwoFixedDecimal = roundToTwoFixedDecimal;
