/* eslint-disable max-len */
import Image from "./Image";
import TSBusLogo from "images/logos/taxscribe-bus-logo.png";

export const TaxScribeLogo = () => {
  return (
    <Image
      alt="taxscribe-logo"
      className="mt-4 mx-auto d-block"
      src={TSBusLogo}
      fallbackSrc={TSBusLogo}
      width="250"
      height="auto"
    />
  );
};

export const PrivateLogoImage = ({
  labelPath,
  width = "100",
  height = "auto",
}: any) => {
  return (
    <Image
      alt="county-logo"
      className="mx-auto mb-4 d-block"
      fallbackSrc={TSBusLogo}
      src={`/images/seals/${labelPath}.png`}
      width={width}
      height={height}
    />
  );
};
