"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.processFormName = exports.mappingByState = void 0;
exports.mappingByState = {
    MI: { LISTING: "Statement" },
};
const processFormName = ({ stateAbbr, shortName, }) => {
    const stateMappings = exports.mappingByState[stateAbbr.toUpperCase()];
    if (!stateMappings)
        return shortName;
    const name = stateMappings[shortName.toUpperCase()];
    return name || shortName;
};
exports.processFormName = processFormName;
