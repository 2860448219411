"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ownershipInformationData = void 0;
exports.ownershipInformationData = {
    firstName: "",
    lastName: "",
    middleName: "",
    suffix: "",
    additionalName: "",
    accountNumber: "",
    email: "",
    phoneNumber: "",
    addressOne: "",
    addressTwo: "",
    city: "",
    state: "",
    zipCode: "",
};
