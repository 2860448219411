"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const b1Items = [
    {
        type: ["null", "object"],
        properties: {
            PropertyPk: {
                type: "string",
                minLength: 1,
            },
        },
        required: ["PropertyPk"],
    },
    {
        type: ["null", "object"],
        properties: {
            year: {
                type: "string",
                format: "year",
            },
            make: {
                type: "string",
                minLength: 1,
            },
            model: {
                type: "string",
                minLength: 1,
            },
            bodySize: {
                type: "string",
                // minLength: 1,
            },
            titleNumber: {
                type: "string",
            },
            vin: {
                type: "string",
                minLength: 1,
            },
            cost: {
                $ref: "#/$defs/nonZeroPositiveInteger",
            },
        },
        required: ["year", "make", "model", "vin", "cost"],
    },
];
exports.default = [
    {
        properties: {
            ownsUnregisteredMotorVehicle: {
                enum: [false, "no"],
            },
            ownsMultiYearOrRegisteredTrailer: {
                enum: [false, "no"],
            },
            ownsSpecialBodiesOnVehicle: {
                enum: [false, "no"],
            },
            ownsIRPPlatedVehicle: {
                enum: [false, "no"],
            },
            b1Attachment: {
                $ref: "#/$defs/nullableAttachment",
            },
        },
        required: [
            "ownsUnregisteredMotorVehicle",
            "ownsMultiYearOrRegisteredTrailer",
            "ownsSpecialBodiesOnVehicle",
            "ownsIRPPlatedVehicle",
        ],
    },
    {
        properties: {
            ownsUnregisteredMotorVehicle: {
                type: ["string", "boolean"],
                format: "conditional",
            },
            ownsMultiYearOrRegisteredTrailer: {
                type: ["string", "boolean"],
                format: "conditional",
            },
            ownsSpecialBodiesOnVehicle: {
                type: ["string", "boolean"],
                format: "conditional",
            },
            ownsIRPPlatedVehicle: {
                type: ["string", "boolean"],
                format: "conditional",
            },
            b1Attachment: {
                $ref: "#/$defs/attachment",
            },
        },
        required: [
            "b1Attachment",
            "ownsUnregisteredMotorVehicle",
            "ownsMultiYearOrRegisteredTrailer",
            "ownsSpecialBodiesOnVehicle",
            "ownsIRPPlatedVehicle",
        ],
    },
    {
        properties: {
            ownsUnregisteredMotorVehicle: {
                type: ["string", "boolean"],
                format: "conditional",
            },
            ownsMultiYearOrRegisteredTrailer: {
                type: ["string", "boolean"],
                format: "conditional",
            },
            ownsSpecialBodiesOnVehicle: {
                type: ["string", "boolean"],
                format: "conditional",
            },
            ownsIRPPlatedVehicle: {
                type: ["string", "boolean"],
                format: "conditional",
            },
            b1Attachment: {
                $ref: "#/$defs/nullableAttachment",
            },
            b1s: {
                type: "array",
                minItems: 1,
                items: { anyOf: b1Items },
            },
        },
        required: [
            "b1s",
            "ownsUnregisteredMotorVehicle",
            "ownsMultiYearOrRegisteredTrailer",
            "ownsSpecialBodiesOnVehicle",
            "ownsIRPPlatedVehicle",
        ],
    },
];
