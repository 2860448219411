import { Row, Col } from "react-bootstrap";
import { FC } from "react";
import { Link } from "react-router-dom";
import { useJurisdictionContext } from "@taxscribe/ui";

const AlreadySent: FC = () => {
  const { webPath } = useJurisdictionContext();

  return (
    <>
      <Row>
        <Col className="text-center">
          <p className="mt-3">
            If there is an account associated with your email you will receive a
            password reset link in your inbox.
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <p className="mt-3">
            <Link
              to={`/${webPath}/login`}
              className="text-white"
            >
              <strong>Back to login</strong>
            </Link>
          </p>
        </Col>
      </Row>
    </>
  );
};

export default AlreadySent;
