import React, { FC } from "react";
import { useCurrentOrganization } from "@reasongcp/react-fire-sub";

import TSQuill from "../../../images/color-quill.png";
import ProAlert from "components/ProAlert";
import { Image } from "react-bootstrap";
import config from "config/hostnames";

const OrganizationInfo: FC = () => {
  const currentOrg = useCurrentOrganization();

  if (!currentOrg) return null;
  const link = `${config.professionalUri}forms`;

  return (
    <ProAlert pageTitle={currentOrg.name}>
      <div>
        Your organization has an account with
        <a
          className="fw-bold text-pro-teal"
          href={link}
        >
          <Image
            width="10"
            height="auto"
            src={TSQuill}
            className="ms-1"
          />
          TaxScribe PRO
        </a>
        ! File all your forms for multiple jurisdictions here.
      </div>
    </ProAlert>
  );
};

export default OrganizationInfo;
