"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bppSample11_10_2023 = void 0;
exports.bppSample11_10_2023 = {
    AbstractNumber: "0008232223202320230000",
    Value: {
        OwnershipInformation: {
            Owners: [
                {
                    OwnerPk: "11232120",
                    COACode: "",
                    OwnerOrder: "PRIMARY",
                    OwnerOrderNCPTSCode: "OWORPRI",
                    OwnershipType: "BUSINESS",
                    OwnershipTypeNCPTSCode: "OWTYBUS",
                    PercentOwnership: "100",
                    SendMail: "Y",
                    Confidential: "False",
                    EligibleForTaxRelief: "False",
                    GroupNumber: "1",
                    OwnerNames: [
                        {
                            IsDefaultName: "Y",
                            IsNameOnAbstract: "Y",
                            IsDBANameOnAbstract: "",
                            BusinessName: "INIZIO PIZZA NAPOLETANA HUNTERSVILLE",
                            BusinessTypeNCPTSLookupCode: "BUNTDBA",
                            BusinessType: "DOING BUSINESS AS",
                        },
                    ],
                    OwnerAddresses: [
                        {
                            IsDefaultAddress: "Y",
                            IsAddressOnAbstract: "Y",
                            InCareOf: "INIZIOS III LLC",
                            AddressLine1: "3425 LAKE EASTBROOK BV SE",
                            AddressLine2: "",
                            AddressLine3: "",
                            City: "GRAND RAPIDS",
                            StateNCPTSCode: "STATMI",
                            State: "MICHIGAN",
                            PostalCode: "49546",
                            PostalCodeExtension: "",
                            CountyNCPTSLookupCode: "",
                            County: "",
                        },
                    ],
                    OwnerPhoneNumbers: [
                        {
                            IsDefaultPhone: "Y",
                            AreaCode: "704",
                            Phone1: "649",
                            Phone2: "4582",
                            Extension: "",
                            TypeNCPTSLookupCode: "PHTYEPAY",
                            Type: "E-PAYER",
                        },
                    ],
                },
            ],
        },
        SitusInformation: {
            IsDetailed: "Y",
            SitusPk: "5767823",
            City: "HUNTERSVILLE",
            CityNCPTSCode: "JURSHUNT",
            State: "NC",
            StateNCPTSCode: "STATNC",
            PostalCode: "28078",
            PostalCodeExtension: "",
            County: "MECKLENBURG",
            CountyNCPTSCode: "CNTYMECK",
            Address1: "16627 BIRKDALE COMMONS PKWY",
            Address2: "",
            Address3: "",
            // Added by hand...
            UnitNumber: "",
            TaxDistricts: [
                {
                    TaxPercentage: "100",
                    CountyNCPTSLookupCode: "JURSMECK",
                    County: "MECKLENBURG",
                },
                {
                    TaxPercentage: "100",
                    CountyNCPTSLookupCode: "JURSHUNT",
                    County: "HUNTERSVILLE",
                },
            ],
        },
        AbstractInfo: {
            ListingDate: "3/10/2023 12:00:00 AM",
            ExtensionRequestDate: "1/9/2023 12:00:00 AM",
            ExtensionDate: "4/17/2023 12:00:00 AM",
            Status: "ABSSBILLED",
        },
        ContactInfo: {
            ContactName: {
                BusinessName: "INIZIO PIZZA NAPOLETANA",
                BusinessTypeNCPTSLookupCode: "",
                BusinessType: "",
                FirstName: "",
                MiddleName: "",
                LastName: "",
                Suffix: "",
            },
            ContactAddress: {
                InCareOf: null,
                AddressLine1: null,
                AddressLine2: null,
                AddressLine3: null,
                City: null,
                StateNCPTSCode: null,
                State: null,
                PostalCode: null,
                PostalCodeExtension: null,
                CountyNCPTSLookupCode: null,
                County: null,
            },
            ContactEmail: "",
            ContactPhone: {
                AreaCode: "704",
                Phone1: "649",
                Phone2: "4582",
                Extension: "",
                TypeNCPTSLookupCode: "PHTYWRK",
                Type: "WORK",
            },
        },
        BusinessInfo: {
            NAICSCode: "",
            BusinessBeganDate: "6/23/2020 12:00:00 AM",
            BusinessYearEnd: "12/31/2022 12:00:00 AM",
            BusinessClassNCPTSLookupCode: "BUCLOTHR",
            BusinessClass: "Other",
        },
        OutOfBusinessInfo: {
            OutOfBusinessDate: "",
            OutOfBusinessReasonNCPTSCode: "",
            OutOfBusinessReason: "",
        },
        CountiesWhereBusinessIsListed: [
            {
                CountyNCPTSLookupCode: "",
                County: "",
            },
        ],
        PropertyInformation: {
            ScheduleA: {
                Group1: [
                    {
                        PropertyPK: "18612827",
                        GroupDescription: "MACHINERY AND EQUIPMENT",
                        PropertyDescription: "MACHINERY AND EQUIPMENT",
                        GroupNumber: "1",
                        Schedule: {
                            ScheduleName: "E10",
                            CategoryNumber: "5460",
                            CategoryDescription: "RESTAURANTS BARS SODA FOUNTAIN EQPT-5460",
                        },
                        Years: [
                            {
                                Year: "2022",
                                PriorYearCost: "0",
                                Additions: "4355",
                                Deletions: "0",
                                CurrentYearCost: "4355",
                            },
                            {
                                Year: "2021",
                                PriorYearCost: "21019",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "21019",
                            },
                            {
                                Year: "2020",
                                PriorYearCost: "91747",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "91747",
                            },
                            {
                                Year: "2019",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2018",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2017",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2016",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2015",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2014",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "PRIOR",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                        ],
                        VoidEffectiveDate: "",
                    },
                ],
                Group2: [
                    {
                        PropertyPK: "17437413",
                        GroupDescription: "Construction  in Progress",
                        PropertyDescription: "CONSTRUCTION  IN PROGRESS",
                        AppraisedValue: "105000",
                        PriorYearValue: "0",
                        VoidEffectiveDate: "",
                    },
                ],
                Group3: [
                    {
                        PropertyPK: "18612828",
                        GroupDescription: "OFFICE FURNITURE",
                        PropertyDescription: "OFFICE FURNITURE",
                        GroupNumber: "3",
                        Schedule: {
                            ScheduleName: "K10",
                            CategoryNumber: "5334",
                            CategoryDescription: "OFC FURNITURE, FIXTURES-5334",
                        },
                        Years: [
                            {
                                Year: "2022",
                                PriorYearCost: "0",
                                Additions: "373",
                                Deletions: "0",
                                CurrentYearCost: "373",
                            },
                            {
                                Year: "2021",
                                PriorYearCost: "25504",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "25504",
                            },
                            {
                                Year: "2020",
                                PriorYearCost: "14848",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "14848",
                            },
                            {
                                Year: "2019",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2018",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2017",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2016",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2015",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2014",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "PRIOR",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                        ],
                        VoidEffectiveDate: "",
                    },
                ],
                Group4: [
                    {
                        PropertyPK: "18612829",
                        GroupDescription: "COMPUTERS & SOFTWARE",
                        PropertyDescription: "COMPUTERS & SOFTWARE",
                        GroupNumber: "4",
                        Schedule: {
                            ScheduleName: "U5",
                            CategoryNumber: "5335",
                            CategoryDescription: "COMPUTERS AND SOFTWARE-5335",
                        },
                        Years: [
                            {
                                Year: "2022",
                                PriorYearCost: "0",
                                Additions: "445",
                                Deletions: "0",
                                CurrentYearCost: "445",
                            },
                            {
                                Year: "2021",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2020",
                                PriorYearCost: "1145",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "1145",
                            },
                            {
                                Year: "2019",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "PRIOR",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                        ],
                        VoidEffectiveDate: "",
                    },
                ],
                Group5: [
                    {
                        PropertyPK: "18612830",
                        GroupDescription: "LEASEHOLD IMPROVEMENTS",
                        PropertyDescription: "LEASEHOLD IMPROVEMENTS",
                        GroupNumber: "5",
                        Schedule: {
                            ScheduleName: "N20",
                            CategoryNumber: "5382",
                            CategoryDescription: "LEASEHOLD IMPROVEMENTS 25 PERCENT RESIDUAL-5382",
                        },
                        Years: [
                            {
                                Year: "2022",
                                PriorYearCost: "0",
                                Additions: "3950",
                                Deletions: "0",
                                CurrentYearCost: "3950",
                            },
                            {
                                Year: "2021",
                                PriorYearCost: "235239",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "235239",
                            },
                            {
                                Year: "2020",
                                PriorYearCost: "18440",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "18440",
                            },
                            {
                                Year: "2019",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2018",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2017",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2016",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2015",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2014",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2013",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2012",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2011",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2010",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2009",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2008",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2007",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2006",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2005",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2004",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "PRIOR",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                        ],
                        VoidEffectiveDate: "",
                    },
                ],
                Group6: [
                    {
                        PropertyPK: "18624620",
                        GroupDescription: "EXPENSED ITEMS",
                        PropertyDescription: "EXPENSED ITEMS",
                        GroupNumber: "6",
                        Schedule: {
                            ScheduleName: "A8",
                            CategoryNumber: "5573",
                            CategoryDescription: "EXPENSED ITEMS A8-5573",
                        },
                        Years: [
                            {
                                Year: "2022",
                                PriorYearCost: "0",
                                Additions: "456",
                                Deletions: "0",
                                CurrentYearCost: "456",
                            },
                            {
                                Year: "2021",
                                PriorYearCost: "5975",
                                Additions: "0",
                                Deletions: "5229",
                                CurrentYearCost: "746",
                            },
                            {
                                Year: "2020",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2019",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2018",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2017",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2016",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2015",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "PRIOR",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                        ],
                        VoidEffectiveDate: "",
                    },
                ],
                Group7: [
                    {
                        PropertyPK: "18624619",
                        GroupDescription: "Supplies",
                        PropertyDescription: "SUPPLIES",
                        AppraisedValue: "6300",
                        PriorYearValue: "7700",
                        VoidEffectiveDate: "",
                    },
                ],
                Group8: [
                    {
                        PropertyPK: "",
                        GroupDescription: "",
                        PropertyDescription: "",
                        GroupNumber: "",
                        Schedule: {
                            ScheduleName: "",
                            CategoryNumber: "",
                            CategoryDescription: "",
                        },
                        Years: [
                            {
                                Year: "2022",
                                PriorYearCost: "0",
                                Additions: "456",
                                Deletions: "0",
                                CurrentYearCost: "456",
                            },
                            {
                                Year: "2021",
                                PriorYearCost: "5975",
                                Additions: "0",
                                Deletions: "5229",
                                CurrentYearCost: "746",
                            },
                            {
                                Year: "2020",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2019",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2018",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2017",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2016",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "2015",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Year: "PRIOR",
                                PriorYearCost: "0",
                                Additions: "0",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                        ],
                        VoidEffectiveDate: "",
                    },
                ],
            },
            ScheduleB: {
                UnregisteredVehicles: [
                    {
                        VehicleSubtype: "Multi-Year",
                        Lesee: {
                            CountyCode: "",
                            AbstractLesseeId: null,
                            MailingAddr3: "",
                            MailingAddr2: "",
                            MailingAddr1: "",
                            State: "",
                            FullName: "",
                            InCareOf: "",
                            PostalCode: "",
                            City: "",
                            PostalCodeExt: "",
                        },
                        Size: {
                            Length: "",
                            Width: "",
                        },
                        Make: "FONT",
                        RegistrationIssueDate: null,
                        Plate: "AE35272",
                        PropertyPk: "18616292",
                        PropertyDescription: "MULTI-YEAR 2006 FONT AE35272 TRAILER",
                        Year: "2006",
                        AcquisitionCost: "16150",
                        Model: "AE35272",
                        VehicleSubtypeNCPTSCode: "VEHSMULTIYEAR",
                        VIN: "***********536998",
                        BodyDescription: "TRAILER",
                        VoidEffectiveDate: "",
                        AppraisedValue: "2180",
                        BodyDescriptionNCPTSCode: "BODTTL",
                        YearAcquired: "2016",
                        TitleNumber: "",
                    },
                ],
                VehicleValidValues: {
                    VehicleSubtypes: [
                        {
                            VehicleSubtypeNCPTSCode: "VEHSIRP",
                            VehicleSubtype: "IRP",
                        },
                        {
                            VehicleSubtypeNCPTSCode: "VEHSGAP",
                            VehicleSubtype: "GAP",
                        },
                        {
                            VehicleSubtypeNCPTSCode: "VEHSUV",
                            VehicleSubtype: "Unregistered",
                        },
                        {
                            VehicleSubtypeNCPTSCode: "VEHSMULTIYEAR",
                            VehicleSubtype: "Multi-Year",
                        },
                    ],
                },
                Watercraft: [
                    {
                        Size: {
                            LengthInFeet: "23",
                            LengthInInches: "",
                        },
                        Make: "MANITOU",
                        PropertyPk: "18613640",
                        PropertyDescription: "2019 MANITOU 23 AURORA VP PONTOON 23'",
                        Year: "2019",
                        AcquisitionCost: "",
                        Model: "23 aurora vp",
                        RegistrationNumber: "9023EK",
                        EngineTypeNCPTSCode: "WPCDOB",
                        EngineType: "Outboard",
                        MotorInformation: [
                            {
                                Value: "9879",
                                Make: "",
                                HorsePower: "150.00",
                            },
                        ],
                        VoidEffectiveDate: "",
                        AppraisedValue: "23400",
                        YearAcquired: "",
                        Location: {
                            SlipNumber: "",
                            MarinaPk: "",
                            MarinaName: "",
                            SlipExtension: "",
                        },
                    },
                ],
                ManufacturedHomes: [
                    {
                        PropertyDescription: "1983 TAYLOR  14X52 MH26 (LOT 49)",
                        Year: "1983",
                        Size: {
                            Length: "52",
                            Width: "14",
                        },
                        AcquisitionCost: "",
                        VIN: "*************122601",
                        Make: "TAYLOR",
                        VoidEffectiveDate: "",
                        AppraisedValue: "1000",
                        YearAcquired: "",
                        Location: {
                            MHParkName: "MH26 ",
                            MHParkPk: "2256",
                            LotExtension: "",
                            LotNumber: "49",
                        },
                        PropertyPk: "18048296",
                    },
                ],
                Aircraft: [
                    {
                        PropertyDescription: "2015  FALCON 900EX",
                        Year: "2015",
                        AcquisitionCost: "",
                        Model: "900ex",
                        TailNumber: "n532cc",
                        Make: "dassault falcon",
                        VoidEffectiveDate: "",
                        AppraisedValue: "34660494",
                        YearAcquired: "",
                        Location: {
                            AirportPk: "2208",
                            HangarExtension: "",
                            AirportName: "AP01 ",
                            HangarNumber: "30",
                        },
                        PropertyPk: "18617960",
                    },
                ],
            },
            ScheduleC: [],
            ScheduleE: [
                {
                    ModelYear: "2020",
                    Make: "MAKE",
                    Model: "MODEL",
                    FuelCode: "",
                    FuelCodeNCPTSCode: "",
                    YearAcquired: "2020",
                    OriginalCost: "",
                    PropertyPK: "",
                    GroupDescription: "Miscellaneous",
                    PropertyDescription: "2020 MAKE MODEL",
                    AppraisedValue: "0",
                    PriorYearValue: "0",
                    VoidEffectiveDate: "",
                },
            ],
            Other: {
                MISC: [],
                EXEMPT: [],
                INDEX: [],
            },
        },
    },
};
