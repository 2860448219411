import React, { FC, useCallback } from "react";
import { Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";

const StyledP = styled.p`
  margin-top: 1rem;
`;

export interface PinFieldProps {
  show: boolean;
  value: string;
  setPin: (pin: string) => void;
}

const PinField: FC<PinFieldProps> = ({
  show,
  value,
  setPin,
}) => {
  const handlePinChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPin(e.target.value || "");
    },
    [setPin],
  );

  if (!show) return null;

  return (
    <>
      <Row>
        <Col>
          <StyledP>
            Enter the PIN provided by your taxing authority or start a new form
            from scratch.
          </StyledP>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>PIN</Form.Label>
            <Form.Control
              type="text"
              value={value}
              onChange={handlePinChange}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default PinField;
