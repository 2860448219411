"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const sectionK = {
    type: "object",
    required: ["personalPropertyInfo"],
    properties: {
        personalPropertyInfo: {
            type: "array",
            items: {
                type: "object",
                required: [
                    "age",
                    "description",
                    "sellingPriceNew",
                    "name",
                    "address",
                    "city",
                    "zipCode",
                    "stateAbbr",
                ],
                properties: {
                    description: {
                        $ref: "#/$defs/nonEmptyString",
                    },
                    age: {
                        minimum: 1,
                        type: "integer",
                    },
                    sellingPriceNew: {
                        minimum: 1,
                        type: "integer",
                    },
                    name: {
                        $ref: "#/$defs/nonEmptyString",
                    },
                    address: {
                        $ref: "#/$defs/nonEmptyString",
                    },
                    city: {
                        $ref: "#/$defs/nonEmptyString",
                    },
                    zipCode: {
                        type: "string",
                        format: "zipCode",
                    },
                    stateAbbr: {
                        type: "string",
                        format: "state",
                    },
                },
            },
        },
    },
};
exports.default = sectionK;
