"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const newOwnerInformation = {
    type: "object",
    properties: {
        name: {
            type: "string",
        },
        street: {
            type: "string",
        },
        city: {
            type: "string",
        },
        state: {
            type: "string",
        },
        zip: {
            type: "string",
            format: "optionalZipCode",
        },
    },
};
exports.default = newOwnerInformation;
