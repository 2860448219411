"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const matchesSiblingValue = (schema, sig, parentSchema, data) => {
    const maybeSchema = schema;
    if (!(maybeSchema === null || maybeSchema === void 0 ? void 0 : maybeSchema.sibling) || !(maybeSchema === null || maybeSchema === void 0 ? void 0 : maybeSchema.errorMessage)) {
        const errMessage = `matchesSiblingValue received an invalid schema: ${schema}`;
        console.error(errMessage);
        throw new Error(errMessage);
    }
    matchesSiblingValue.errors = [{
            keyword: "matchesSiblingValue",
            message: maybeSchema.errorMessage,
            params: { keywords: "matchesSiblingValue" },
        }];
    const { parentData } = data || {};
    if (!parentData)
        return false;
    const sibling = parentData[maybeSchema.sibling];
    if (!sibling)
        return false;
    return sig === sibling;
};
const keywords = {
    // Expects a sibling attribute of `email`.
    // DEPRECATED: Use `matchesSiblingValue` instead.
    signatureEmailMatches: {
        keyword: "signatureMatches",
        validate: (schema, sig, parentSchema, data) => {
            const { parentData } = data || {};
            const email = (parentData === null || parentData === void 0 ? void 0 : parentData.email) || "";
            if (!email)
                return false;
            return sig === email;
        },
        errors: true,
    },
    matchesSiblingValue: {
        keyword: "matchesSiblingValue",
        errors: true,
        validate: matchesSiblingValue,
    },
};
exports.default = keywords;
