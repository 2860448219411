import { DocumentNode, useQuery } from "@apollo/client";
import { CurrentSubmission } from "@taxscribe/gql-queries";
import { omit } from "lodash";
import { useMemo } from "react";

interface ResultData extends Record<"__typename", string> {}

interface Results<Data extends ResultData> {
  form: {
    data: Data;
    source: string;
    filingYear: number;
    currentSubmission: CurrentSubmission;
    formPeriod?: {
      filingDeadline: string;
      filingDeadlineTimestamp: string;
      filingDeadlineFormattedDay: string;
    };
  };
}

interface UseExistingFormDataResults<Data extends ResultData> {
  loading: boolean;
  data?: Results<Data>;
  formData?: Omit<Data, "__typename">;
  currentSubmission?: CurrentSubmission;
}

export interface UseExistingFormDataArgs {
  uuid?: string;
  formUuid?: string;
  query: DocumentNode;
}

const getVariables = ({
  uuid,
  formUuid,
}: Pick<UseExistingFormDataArgs, "uuid" | "formUuid">) => {
  if (uuid) {
    return { uuid };
  }

  if (formUuid) {
    return { formUuid };
  }

  throw new Error("Either uuid or formUuid must be provided");
};

const useExistingFormData = <Data extends ResultData>({
  uuid,
  query,
  formUuid,
}: UseExistingFormDataArgs): UseExistingFormDataResults<Data> => {
  const variables = useMemo(() => {
    return getVariables({ uuid, formUuid });
  }, [uuid, formUuid]);

  const { data, loading } = useQuery<Results<Data>>(query, {
    variables,
    fetchPolicy: "network-only",
  });

  const form = data?.form;
  const formData = form?.data ? omit(form.data, ["__typename"]) : undefined;
  const currentSubmission = form?.currentSubmission;

  return { loading, formData, currentSubmission, data };
};

export default useExistingFormData;
