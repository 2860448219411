import React, { FC } from "react";
import {
  PreviewPdfButton,
  SaveFormButton,
  ViewPdfButton,
} from "@taxscribe/form";

interface HeaderActionsProps {
  readOnly?: boolean;
  formUuid?: string;
  metadata?: Record<string, any>;
}

const FormHeaderActions: FC<HeaderActionsProps> = ({
  formUuid,
  readOnly,
  metadata,
}) => {
  if (readOnly && metadata?.pdfUrl) {
    return (
      <div>
        <ViewPdfButton uuid={formUuid} />
      </div>
    );
  }

  if (readOnly) return null;
  return (
    <div>
      <SaveFormButton />
      <PreviewPdfButton uuid={formUuid} />
    </div>
  );
};

export default FormHeaderActions;
