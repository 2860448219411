/* eslint-disable max-len */
import React, { FC, useState } from "react";
import { Alert, Container, Row, Col, Card } from "react-bootstrap";
import { RegistrationForm } from "components/Registration";
import { useJurisdictionContext } from "@taxscribe/ui";
import { firestore as db } from "config/firebaseInit";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { useSignup } from "@reasongcp/react-fire-sub";

import UnauthenticatedLayout from "../components/UnauthenticatedLayout";
import EmailHelp from "../components/EmailHelp";
import { Link, useNavigate } from "react-router-dom";

const errCodeEmailInUse = "auth/email-already-in-use";

const EmailExistsAlert: FC<{ webPath: string }> = ({ webPath }) => {
  return (
    <Alert variant="danger">
      <strong>
        That email is already in use. Please{" "}
        <Link
          to={`/${webPath}/reset`}
          className="text-dark"
        >
          reset your password
        </Link>
        .
      </strong>
    </Alert>
  );
};

const RegistrationPage: FC = () => {
  const signup = useSignup();
  const jurisdiction = useJurisdictionContext();
  const timestamp = serverTimestamp();
  const navigate = useNavigate();
  const [emailInUse, setEmailInUse] = useState(false);

  const { name, fullSlug, webPath } = jurisdiction;

  const year = new Date().getFullYear();

  const defaultRegistration = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    passwordConfirmation: "",
  };

  const handleSubmit = async (values: any) => {
    const { email, firstName, lastName, password } = values;
    const validationUrl = `${window.location.origin}/#/${webPath}/dashboard`;

    const userData = {
      email,
      firstName,
      lastName,
      createdAt: timestamp,
    };

    try {
      const createdUser = await signup({
        email,
        password,
        validationUrl,
      });

      if (createdUser) {
        const userId = createdUser.uid;
        const docRef = doc(db, "users", userId);
        await setDoc(docRef, { userId, ...userData });
        navigate("/verify");
      }
    } catch (error: any) {
      console.warn(error);
      if (error.code && error.code === errCodeEmailInUse) {
        setEmailInUse(true);
      }
    }
  };

  return (
    <main>
      <div
        className={`d-flex h-100 text-white min-vh-100 ${fullSlug} bg-bpp-primary`}
      >
        <Container>
          <Row>
            <Col
              lg={8}
              className="d-flex p-3 mx-auto flex-column align-items-center"
            >
              <Card
                className={`mt-5 p-5 text-white ${fullSlug} bg-county-dark`}
              >
                <Card.Body>
                  <UnauthenticatedLayout>
                    <h1 className="login-heading my-4 h3 fw-light text-center text-capitalize">
                      {name} <br /> Business Personal Property Registration
                    </h1>
                    {emailInUse && <EmailExistsAlert webPath={webPath} />}
                    <RegistrationForm
                      initialValues={defaultRegistration}
                      submitHandler={handleSubmit}
                    />
                    <EmailHelp />
                  </UnauthenticatedLayout>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-lg-3">
            <Col className="pb-lg-4 pt-lg-5 pb-3">
              <p className="text-center mb-0">
                {" "}
                &copy; {year} Reason Consulting Corporation
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </main>
  );
};

export default RegistrationPage;
