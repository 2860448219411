"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const homeEntry = {
    type: "object",
    required: [
        "year",
        "body",
        "make",
        "model",
        "width",
        "length",
    ],
    properties: {
        parcelId: {
            type: "string",
        },
        year: {
            type: "string",
            format: "year",
        },
        make: {
            $ref: "#/$defs/nonEmptyString",
        },
        model: {
            $ref: "#/$defs/nonEmptyString",
        },
        body: {
            $ref: "#/$defs/nonEmptyString",
        },
        width: {
            type: "number",
            minimum: 1,
        },
        length: {
            type: "number",
            minimum: 1,
        },
        yearAcquired: {
            type: "string",
            format: "optionalYear",
        },
        cost: {
            type: "number",
        },
        vin: {
            type: "string",
        },
        serialNumber: {
            type: "string",
        },
        plateNumber: {
            type: "string",
        },
        manufacturedHomePark: {
            type: "string",
        },
        lotNumber: {
            type: "string",
        },
        titleNumber: {
            type: "string",
        },
        occupant: {
            type: "string",
        },
        titleNameOne: {
            type: "string",
        },
        titleNameTwo: {
            type: "string",
        },
        movingPermitNumber: {
            type: "string",
        },
        movingPermitIssueDate: {
            type: "string",
            format: "optionalCalendar",
        },
        buildingPermitNumber: {
            type: "string",
        },
        buildingPermitIssueDate: {
            type: "string",
            format: "optionalCalendar",
        },
    },
};
const manufacturedHome = {
    type: "object",
    oneOf: [
        {
            required: ["needToAdd"],
            properties: {
                needToAdd: {
                    enum: [false],
                },
            },
        },
        {
            required: ["needToAdd", "homes"],
            properties: {
                needToAdd: {
                    enum: [true],
                },
                homes: {
                    type: "array",
                    items: homeEntry,
                    minItems: 1,
                },
            },
        },
    ],
};
exports.default = manufacturedHome;
