"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    type: "object",
    properties: {
        name: {
            $ref: "#/$defs/nonEmptyString",
        },
        accountNumber: {
            type: "string",
        },
        dbaCheckbox: {
            type: ["string", "boolean"],
            format: "conditional",
        },
        dbaName: {
            $ref: "#/$defs/nonEmptyString",
        },
        accountPrimary: {
            type: "string",
        },
        accountSecondary: {
            type: ["string", "null"],
        },
        jurisdictionName: {
            type: ["string", "null"],
        },
        mailingAddress: {
            type: "object",
            properties: {
                addressCheckbox: {
                    type: ["string", "boolean"],
                    format: "conditional",
                },
                street: {
                    $ref: "#/$defs/nonEmptyString",
                },
                city: {
                    $ref: "#/$defs/nonEmptyString",
                },
                state: {
                    type: "string",
                    format: "state",
                },
                zipcode: {
                    type: "string",
                    format: "zipCode",
                },
            },
            anyOf: [
                {
                    if: {
                        properties: {
                            addressCheckbox: {
                                enum: [true],
                            },
                        },
                    },
                    then: {
                        required: ["addressCheckbox"],
                    },
                },
                {
                    required: ["street", "city", "state", "zipcode"],
                },
            ],
        },
        physicalAddress: {
            type: "object",
            properties: {
                street: {
                    $ref: "#/$defs/nonEmptyString",
                },
                city: {
                    $ref: "#/$defs/nonEmptyString",
                },
                state: {
                    type: "string",
                    format: "state",
                },
                zipcode: {
                    type: "string",
                    format: "zipCode",
                },
            },
            required: ["street", "city", "state", "zipcode"],
        },
        ownedBy: {
            type: "string",
        },
        previousName: {
            type: "string",
        },
        personalPropertyCounties: {
            type: "string",
        },
        principalBusiness: {
            type: "string",
        },
        sicCode: {
            type: "string",
        },
        naicsCode: {
            type: "string",
            format: "optionalNaics",
        },
        startedDate: {
            type: "string",
            format: "optionalCalendar",
        },
        fiscalYearEnd: {
            type: "string",
            format: "optionalCalendar",
        },
        typeOf: {
            type: "string",
        },
        category: {
            type: "string",
        },
        outOrInBusiness: {
            type: ["string", "boolean"],
            format: "conditional",
        },
        outOfBusiness: {
            type: ["null", "object"],
            properties: {
                reason: {
                    type: "string",
                },
                ceased: {
                    type: "string",
                    format: "optionalCalendar",
                },
                buyer: {
                    type: "string",
                },
                buyerAddress: {
                    type: "string",
                },
                buyerPhone: {
                    type: "string",
                },
            },
            required: ["reason"],
        },
    },
    required: ["name", "mailingAddress", "outOrInBusiness", "physicalAddress"],
    anyOf: [
        {
            required: ["dbaName"],
        },
        {
            required: ["dbaCheckbox"],
        },
    ],
};
