"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    type: "object",
    anyOf: [
        {
            properties: {
                ownsFarmEquipment: {
                    enum: [false, "no"],
                },
            },
            required: ["ownsFarmEquipment"],
        },
        {
            required: ["e1Attachment", "ownsFarmEquipment"],
            properties: {
                ownsFarmEquipment: {
                    enum: [true, "yes"],
                },
                e1Attachment: {
                    $ref: "#/$defs/attachment",
                },
            },
        },
        {
            properties: {
                ownsFarmEquipment: {
                    enum: [true, "yes"],
                },
                e1s: {
                    type: "array",
                    minItems: 1,
                    items: {
                        type: ["null", "object"],
                        properties: {
                            year: {
                                type: "string",
                                format: "year",
                                minLength: 1,
                            },
                            descriptionOrMake: {
                                type: "string",
                                minLength: 1,
                            },
                            modelOrSeries: {
                                type: "string",
                            },
                            fuelType: {
                                type: "string",
                                minLength: 1,
                            },
                            yearAcquired: {
                                type: "string",
                                format: "optionalYear",
                            },
                            originalCost: {
                                type: "number",
                            },
                        },
                        required: ["year", "descriptionOrMake", "fuelType"],
                    },
                },
            },
            required: ["ownsFarmEquipment", "e1s"],
        },
    ],
};
