import { Button } from "react-bootstrap";
import { ErrorMessage, Field, Form, Formik } from "formik";

export function LoginForm({ initialValues, submitHandler }: any) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitHandler}
    >
      {() => (
        <Form
          id="form-signin-box"
          noValidate
          autoComplete="off"
        >
          <div className="mb-3">
            {/* eslint-disable-next-line */}
            <label
              htmlFor="email"
              className="form-label"
            >
              Email Address
            </label>
            <Field
              name="email"
              type="email"
              className="form-control text-white bg-transparent"
              placeholder="Email Address"
            />
            <ErrorMessage name="email" />
          </div>

          <div className="mb-3">
            {/* eslint-disable-next-line */}
            <label
              htmlFor="password"
              className="form-label"
            >
              Password
            </label>
            <Field
              name="password"
              label="Password"
              type="password"
              className="form-control text-white bg-transparent"
              placeholder="Password"
            />
            <ErrorMessage name="password" />
          </div>
          <Button
            type="submit"
            className="btn btn-info w-100"
          >
            Login
          </Button>
        </Form>
      )}
    </Formik>
  );
}
