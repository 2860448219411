import { Button, Row, Col } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import { FC, useState } from "react";
import { useSendPasswordResetEmail } from "../hooks/useSendPasswordResetEmail";
import AlreadySent from "./AlreadySent";

interface ResetPasswordPayload {
  email: string;
}

const ResetPasswordForm: FC = () => {
  const [alreadySent, setAlreadySent] = useState<boolean>(false);

  const resetPassword = useSendPasswordResetEmail();

  const handleSubmit = (values: ResetPasswordPayload) => {
    resetPassword(values.email).finally(() => setAlreadySent(true));
  };

  return !alreadySent ? (
    <>
      <Row>
        <Col className="text-center">
          <p>We&apos;ll send you instructions to reset your password.</p>
        </Col>
      </Row>
      <Row>
        <Formik<ResetPasswordPayload>
          initialValues={{ email: "" }}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form
              id="form-forgot-box"
              autoComplete="off"
            >
              <div className="mb-3">
                <label
                  htmlFor="email"
                  className="form-label"
                >
                  Email Address
                </label>
                <Field
                  name="email"
                  type="email"
                  className="form-control text-white bg-transparent"
                  placeholder="Email Address"
                />
              </div>
              <Button
                type="submit"
                className="btn btn-info w-100"
              >
                Reset Password
              </Button>
            </Form>
          )}
        </Formik>
      </Row>
    </>
  ) : (
    <AlreadySent />
  );
};

export { ResetPasswordForm };
