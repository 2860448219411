import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAppToastContext, useJurisdictionContext } from "@taxscribe/ui";

const useAfterSubmit = () => {
  const { webPath } = useJurisdictionContext();
  const { addToastMessage } = useAppToastContext();
  const navigate = useNavigate();

  return useCallback(
    async () => {
      addToastMessage({
        type: "success",
        header: "Submitted",
        body: "Thank You For Submitting Your Form",
      });
      navigate(`/${webPath}/dashboard`);
    },
    [addToastMessage, navigate, webPath],
  );
};

export default useAfterSubmit;
