export const config = {
  apiKey: "AIzaSyBCnuJszvgsrNyB88Im18t04F5-Gs7zGbo",
  authDomain: "taxscribesuite.firebaseapp.com",
  databaseURL: "https://taxscribesuite.firebaseio.com",
  projectId: "taxscribesuite",
  storageBucket: "taxscribesuite.appspot.com",
  messagingSenderId: "683533368867",
  appId: "1:683533368867:web:8b63bc981b68bfb39e7b96",
  measurementId: "G-654H714DN5",
};
