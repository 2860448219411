import { gql } from "@apollo/client";
import { PT50P } from "@taxscribe/form";
import { fetchPrePopDataByPin } from "@taxscribe/ui";
import { cleanFormData, deepPurgeValues } from "@taxscribe/utils";
import createFormPage, { FormDataInjector } from "components/shared/createFormPage";
import createFormView from "components/shared/createFormView";
import { getAuth } from "firebase/auth";
import { merge } from "lodash";

const CREATE_FORM = gql`
  mutation CreatePT50P(
    $app: String!
    $businessName: String
    $source: String!
    $jurisdiction: String!
    $formData: PT50PDataInput!
  ) {
    createPT50P(
      app: $app
      source: $source
      formData: $formData
      businessName: $businessName
      jurisdiction: $jurisdiction
    )
  }
`;

const formType = "PT50P";
const PT50PReport = createFormView({
  Form: PT50P,
  formType,
  formName: "PT50P",
  header: "GA PT50P",
  creationQuery: CREATE_FORM,
});

const injectFormData: FormDataInjector = ({
  formData,
  formUuid,
  currentSubmission,
}) => {
  if (!formData) return formData;
  if (!currentSubmission?.id) {
    const user = getAuth().currentUser;
    const email = user?.email;
    const extraState: Record<string, any> = {
      pt50p: { affirmation: { preparerEmail: email || undefined } },
    };
    return deepPurgeValues(null, merge(cleanFormData(formData), extraState));
  }

  return deepPurgeValues(null, cleanFormData(formData));
};

const GAPT50PPage = createFormPage({
  formType,
  injectFormData,
  ViewForm: PT50PReport,
  prePopForm: fetchPrePopDataByPin(formType),
});

export default GAPT50PPage;
