import { ApolloClient } from "@apollo/client";
import { useAuth } from "@reasongcp/react-fire-sub";
import { useApolloClientWithAuth } from "@taxscribe/apollo-helpers";
import { CurrentSubmission, GET_MY_FORM, NCListingFormResult } from "@taxscribe/gql-queries";
import { useFormDeadline, useJurisdictionContext } from "@taxscribe/ui";
import { cleanFormData, deepPurgeValues } from "@taxscribe/utils";
import config from "config/hostnames";
import useExistingFormData from "hooks/useExistingFormData";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import createFormView from "./createFormView";

interface ExistingFormArgs {
  formUuid: string;
}

export interface FetchPrePopDataArgs {
  email: string;
  jurisdictionId: number;
  client: ApolloClient<any>;
  accountNumberPrimary: string;
}

export type PrePopFetcher = (args: FetchPrePopDataArgs) => Promise<{
  data: any,
  rawData: any,
}>;
export type FormDataInjector = (args: {
  formData?: Record<string, unknown> | null;
  formUuid?: string | null;
  currentSubmission?: CurrentSubmission;
}) => Record<string, any> | null | undefined;

export type FormMetadataInjector = (args: {
  formData?: Record<string, unknown> | null;
  metadata?: Record<string, unknown> | null;
}) => Record<string, any> | null | undefined;

interface CreateFormPageArgs {
  formType: string;
  prePopForm?: PrePopFetcher;
  injectFormData?: FormDataInjector;
  injectMetadata?: FormMetadataInjector;
  ViewForm: ReturnType<typeof createFormView>;
}

interface PrePopFormProps extends CreateFormPageArgs {
  prePopForm: PrePopFetcher;
  accountNumberPrimary: string;
}

const PrePopForm: FC<PrePopFormProps> = ({
  ViewForm,
  prePopForm,
  accountNumberPrimary,
}) => {
  const client = useApolloClientWithAuth(config.taxbaseUri);
  const { email } = useAuth() || {};
  const { jurisdictionId } = useJurisdictionContext();
  const [prePopData, setPrePopData] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!email) return;
    setLoading(true);
    prePopForm({
      email,
      client,
      jurisdictionId,
      accountNumberPrimary,
    })
      .then((data) => {
        setPrePopData(data?.data || data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    email,
    client,
    prePopForm,
    setLoading,
    setPrePopData,
    jurisdictionId,
    accountNumberPrimary,
  ]);

  if (loading || !prePopData) return null;

  return (
    <ViewForm
      source="Jurisdiction"
      initialState={prePopData as any}
    />
  );
};

const createFormPage = ({
  formType,
  ViewForm,
  prePopForm,
  injectFormData,
  injectMetadata,
}: CreateFormPageArgs) => {
  const ExistingForm: FC<ExistingFormArgs> = ({ formUuid }) => {
    const { afterDeadline } = useFormDeadline(formType);
    const { data, loading, formData, currentSubmission } =
      useExistingFormData<NCListingFormResult>({
        uuid: formUuid,
        query: GET_MY_FORM,
      });

    const metadata = useMemo(() => {
      if (injectMetadata) {
        return injectMetadata({
          metadata: {
            ...currentSubmission,
            filingYear: data?.form.filingYear,
            source: data?.form.source,
          },
          formData,
        });
      }

      return {
        ...currentSubmission,
        filingYear: data?.form.filingYear,
        source: data?.form.source,
      };
    }, [data, formData, currentSubmission]);

    const source = data?.form?.source;
    const filteredFormData = useMemo(() => {
      if (!formData) return formData;
      const purgedData = deepPurgeValues(null, cleanFormData(formData));
      if (injectFormData) {
        return injectFormData({
          formUuid,
          currentSubmission,
          formData: purgedData,
        });
      }
      return purgedData;
    }, [formData, formUuid, currentSubmission]);

    if (loading) return null;

    return (
      <ViewForm
        source={source}
        formUuid={formUuid}
        metadata={metadata}
        hideSubmit={afterDeadline}
        initialState={filteredFormData}
        filingYear={data?.form?.filingYear}
      />
    );
  };

  const FormPage: FC = () => {
    const { formUuid, accountNumberPrimary } = useParams();

    if (formUuid) {
      return <ExistingForm formUuid={formUuid} />;
    }

    if (accountNumberPrimary && prePopForm) {
      return (
        <PrePopForm
          formType={formType}
          ViewForm={ViewForm}
          prePopForm={prePopForm}
          accountNumberPrimary={accountNumberPrimary}
        />
      );
    }

    return <ViewForm />;
  };

  return FormPage;
};

export default createFormPage;
