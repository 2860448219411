import React, { FC } from "react";
import styled from "styled-components";
import { Spinner } from "react-bootstrap";

const HorizontalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
`;

const LoadingPage: FC = () => {
  return (
    <div role="main">
      <HorizontalWrapper>
        <Spinner
          animation="border"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </HorizontalWrapper>
    </div>
  );
};

export default LoadingPage;
