"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const scheduleBData = {
    b1s: [],
    b2s: [],
    b3s: [],
    b4s: [],
    numberVehicles: 0,
};
exports.default = scheduleBData;
