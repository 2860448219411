"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    type: "object",
    anyOf: [
        {
            required: ["realEstateImprovements"],
            properties: {
                realEstateImprovements: {
                    enum: [false, "no"],
                },
            },
        },
        {
            required: ["realEstateImprovements", "h1Attachment"],
            properties: {
                realEstateImprovements: {
                    enum: [true, "yes"],
                },
                h1Attachment: {
                    $ref: "#/$defs/attachment",
                },
            },
        },
        {
            required: ["realEstateImprovements", "h1s"],
            properties: {
                realEstateImprovements: {
                    enum: [true, "yes"],
                },
                h1s: {
                    type: "array",
                    minItems: 1,
                    items: {
                        type: "object",
                        properties: {
                            locationParcelNumber: {
                                type: "string",
                            },
                            description: {
                                type: "string",
                            },
                            constructionCost: {
                                type: ["string", "number"],
                                format: "numeric",
                            },
                            percentCompleteOnJan1: {
                                type: ["string", "number"],
                                format: "percentage",
                            },
                        },
                        // required: [
                        //   "locationParcelNumber",
                        //   "description",
                        //   "constructionCost",
                        //   "percentCompleteOnJan1",
                        // ],
                    },
                },
            },
        },
    ],
};
