import React, { FC, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { DocumentNode, gql } from "@apollo/client";
import { useAuth } from "@reasongcp/react-fire-sub";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { MultiPageForm, NavigateButton, SinglePageForm } from "@taxscribe/form";
import {
  useToasts,
  useCurrentFormPeriod,
  useTaxbaseFormActions,
  useJurisdictionContext,
  useGetFormFieldSignature,
} from "@taxscribe/ui";

import GenericFormWrapper from "./GenericFormWrapper";
import useSubmitForm from "hooks/useSubmitForm";
import FormHeaderActions from "components/FormHeaderActions";
import TSBPenaltyBanner from "./TSBPenaltyBanner";

interface CreateFormViewProps {
  noPdf?: boolean;
  formName: string;
  Form: MultiPageForm | SinglePageForm;
  header: string;
  formType: string;
  creationQuery: DocumentNode;
}

interface ConditionalButtonsProps {
  readOnly?: boolean;
}

const ConditionalButtons: FC<ConditionalButtonsProps> = ({ readOnly }) => {
  if (readOnly) return null;
  return (
    <Row className="justify-content-between mt-3">
      <Col>
        <NavigateButton
          saveOnNavigate
          direction="back"
          variant="link"
          className="text-decoration-none fw-bold"
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            aria-hidden="true"
            className="me-2"
          />
          Back
        </NavigateButton>
      </Col>
      <Col className="align-self-end">
        <div className="px-2 text-end">
          <NavigateButton
            variant="outline-primary"
            saveOnNavigate
            className="ms-2"
            direction="forward"
          >
            Continue
          </NavigateButton>
        </div>
      </Col>
    </Row>
  );
};

const isMultiPageForm = (
  form: MultiPageForm | SinglePageForm,
): form is MultiPageForm => {
  return (form as MultiPageForm).Nav !== undefined;
};

const refetchQueries = ["GetMyForm"];

// TODO: Type this better
const createFormView = <PropType extends Record<string, any>>({
  Form,
  noPdf,
  header,
  formName,
  formType,
  creationQuery,
}: CreateFormViewProps) => {
  const creationMutationName = `create${formName}`;

  const UPDATE_FORM = gql`
    mutation Update${formName}(
      $formUuid: String!,
      $formData: ${formName}DataInput!,
    ) {
      update${formName}(uuid: $formUuid, formData: $formData)
    }
  `;

  const ViewForm: FC<PropType> = ({
    source,
    rawNcpts,
    formUuid,
    metadata,
    hideSubmit,
    filingYear,
    initialState,
    readOnly: readOnlyOverride,
  }) => {
    const { toastError } = useToasts();
    const currentPeriod = useCurrentFormPeriod();
    const { displayName, email } = useAuth() || {};
    const { name: jurisdictionName, hasEfile } = useJurisdictionContext();
    const submit = useSubmitForm();
    const {
      saveAndSubmit,
      handleSave,
      readOnly: defaultReadOnly,
    } = useTaxbaseFormActions<typeof creationMutationName>({
      source,
      submit,
      formUuid,
      rawNcpts,
      app: "TSB",
      refetchQueries,
      creationMutationName,
      status: metadata?.status,
      updateMutation: UPDATE_FORM,
      createMutation: creationQuery,
      jurisdiction: jurisdictionName,
    });

    const readOnly = readOnlyOverride || defaultReadOnly;

    const handleSignaturePrompt = useGetFormFieldSignature();

    const metadataWithUserInfo = useMemo(() => {
      // Favor the metadata attached to the form submission if it exists.
      if (Object.keys(metadata || {}).length) return metadata;
      return {
        ...metadata,
        source,
        email: metadata?.email || email,
        contactName: displayName || email || "",
        filingYear: currentPeriod?.filingYear,
      };
    }, [currentPeriod, source, metadata, displayName, email]);

    if (isMultiPageForm(Form)) {
      return (
        <Form.Provider
          hasEfile={hasEfile}
          onSave={handleSave}
          readOnly={readOnly}
          errorToast={toastError}
          hideSubmit={hideSubmit}
          onSubmit={saveAndSubmit}
          initialState={initialState}
          metadata={metadataWithUserInfo}
          onSignaturePrompt={handleSignaturePrompt}
        >
          <TSBPenaltyBanner
            readOnly={readOnly}
            shortName={formType}
          />
          <GenericFormWrapper
            noPdf={noPdf}
            header={header}
            SideNav={Form.Nav}
            formType={formType}
            formUuid={formUuid}
            filingYear={filingYear}
            headerActions={
              <FormHeaderActions
                metadata={metadata}
                formUuid={formUuid}
                readOnly={readOnly}
              />
            }
          >
            <Form.Router />
            <ConditionalButtons readOnly={readOnly} />
          </GenericFormWrapper>
        </Form.Provider>
      );
    }

    const SinglePageForm = Form as SinglePageForm;
    return (
      <SinglePageForm.Provider
        hasEfile={hasEfile}
        onSave={handleSave}
        hideSubmit={hideSubmit}
        onSubmit={saveAndSubmit}
        initialState={initialState}
        readOnly={readOnly}
        metadata={metadataWithUserInfo}
        onSignaturePrompt={handleSignaturePrompt}
      >
        <TSBPenaltyBanner
          readOnly={readOnly}
          shortName={formType}
        />
        <GenericFormWrapper
          noPdf={noPdf}
          header={header}
          formType={formType}
          formUuid={formUuid}
          filingYear={filingYear}
          headerActions={
            <FormHeaderActions
              formUuid={formUuid}
              readOnly={readOnly}
              metadata={metadata}
            />
          }
        >
          <Row>
            <Col>
              <SinglePageForm.FormPage />
            </Col>
          </Row>
        </GenericFormWrapper>
      </SinglePageForm.Provider>
    );
  };

  return ViewForm;
};

export default createFormView;
