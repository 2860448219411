import { useAppToastContext, useJurisdictionContext, usePrePopulationByPin } from "@taxscribe/ui";
import React, { FC, useCallback } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

export interface StartButtonsProps {
  pin?: string;
  show: boolean;
  formType?: string;
}

const StyledRow = styled(Row)`
  margin-top: 1rem;
`;

const StyledCol = styled(Col)`
  display: flex;
  justify-content: end;
  gap: 1rem;
`;

const StartButtons: FC<StartButtonsProps> = ({ formType, show, pin }) => {
  const { webPath, jurisdictionId } = useJurisdictionContext();
  const navigate = useNavigate();
  const { addToastMessage } = useAppToastContext();

  const [checkPrePopData] = usePrePopulationByPin({
    pin: pin || "",
    formType: formType || "",
    jurisdictionId,
  });

  const handlePinClick = useCallback(() => {
    if (!formType) return;
    const pinUrl = `/${webPath}/${formType.toLowerCase()}/new-for/${pin}`;
    checkPrePopData().then(({ data, error }) => {
      if (error) {
        addToastMessage({
          type: "error",
          header: "Invalid PIN",
          body: "We were unable to locate an account with the provided PIN.",
        });
        return;
      }
      navigate(pinUrl);
    }).catch((err) => {
      console.error(err);
      addToastMessage({
        type: "error",
        header: "Error",
        body: "An error occurred while checking the PIN. Please try again.",
      });
    });
  }, [pin, webPath, navigate, checkPrePopData, formType, addToastMessage]);

  if (!show || !formType) return null;

  const blankUrl = `/${webPath}/${formType.toLowerCase()}/new`;

  return (
    <StyledRow>
      <StyledCol>
        <Link to={blankUrl}>
          <Button variant="primary">Start Blank Form</Button>
        </Link>
        <Button
          variant="success"
          onClick={handlePinClick}
        >
          Start With PIN
        </Button>
      </StyledCol>
    </StyledRow>
  );
};

export default StartButtons;
