import React, { FC } from "react";
import { useParams } from "react-router-dom";
import NCExtensionPage from "pages/NCExtension";
import TXExtensionPage from "pages/TXExtension";
import MIListingPage from "pages/MIListing";
import NCListingPage from "pages/NCListing";
import MIExemptionPage from "pages/MIExemption";
import GAPT50APage from "pages/GAPT50A";
import GAPT50PPage from "pages/GAPT50P";
import GAPT50MPage from "pages/GAPT50M";
import GAPT50PFPage from "pages/GAPT50PF";
import TXRendition144Page from "pages/TXRendition144";

const FormSwitch: FC = () => {
  const { stateAbbr = "", formType = "" } = useParams();
  switch (`${stateAbbr.toLowerCase()} ${formType.toLowerCase()}`) {
  case "nc extension":
    return <NCExtensionPage />;
  case "nc listing":
    return <NCListingPage />;
  case "mi listing":
    return <MIListingPage />;
  case "mi exemption":
    return <MIExemptionPage />;
  case "ga pt50p":
    return <GAPT50PPage />;
  case "ga pt50a":
    return <GAPT50APage />;
  case "ga pt50m":
    return <GAPT50MPage />;
  case "ga pt50pf":
    return <GAPT50PFPage />;
  case "tx extension":
    return <TXExtensionPage />;
  case "tx rendition 144":
    return <TXRendition144Page />;
  default:
    console.error("Unable to determine form type for:", stateAbbr, formType);
    return <div>An error occurred</div>;
  }
};

export default FormSwitch;
