"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const contactData = {
    auditContact: {
        name: "",
        phoneNumber: "",
        street: "",
        city: "",
        state: "",
        zipcode: "",
    },
    paymentContact: {
        name: "",
        phoneNumber: "",
        street: "",
        city: "",
        state: "",
        zipcode: "",
    },
};
exports.default = contactData;
