"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.locateFirst = exports.locate = void 0;
const lodash_1 = require("lodash");
const valueCheck = (value) => (entityValue) => {
    if (value === entityValue)
        return true;
    if (typeof value === "object" && value) {
        return (0, lodash_1.isEqual)(value, entityValue);
    }
    return false;
};
const getCheck = (opts) => {
    return (opts.check ||
        valueCheck(opts.value));
};
const locate = (opts, entity) => {
    const { parentKey } = opts;
    const check = getCheck(opts);
    const entries = Object.entries(entity);
    const checks = entries.map(([key, value]) => {
        const currentKey = [parentKey, key].filter(Boolean).join(".");
        if (check(value))
            return currentKey;
        if (value && typeof value === "object") {
            return (0, exports.locate)(Object.assign(Object.assign({}, opts), { parentKey: currentKey }), value);
        }
        return null;
    });
    return (0, lodash_1.flattenDeep)(checks).filter(Boolean);
};
exports.locate = locate;
const locateFirst = (opts, entity) => (0, exports.locate)(opts, entity)[0] || null;
exports.locateFirst = locateFirst;
