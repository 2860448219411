"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const motorDetails = {
    type: "object",
    required: ["make", "year", "startType", "purchased"],
    properties: {
        make: {
            $ref: "#/$defs/nonEmptyString",
        },
        model: {
            type: "string",
        },
        year: {
            type: "string",
            format: "year",
        },
        horsepower: {
            type: "number",
        },
        startType: {
            $ref: "#/$defs/nonEmptyString",
        },
        datePurchased: {
            type: "string",
            format: "optionalCalendar",
        },
        purchased: {
            $ref: "#/$defs/nonEmptyString",
        },
        cost: {
            type: "number",
        },
    },
};
exports.default = motorDetails;
