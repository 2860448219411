import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Jurisdiction } from "../../../types/jurisdiction";

const GET_STATE = gql`
  query GetState($stateAbbr: String!) {
    state(stateAbbr: $stateAbbr) {
      abbr
      name
      jurisdictions {
        id
        name
        jurisdictionType
        parentId
        hasEfile
      }
    }
  }
`;

interface UseCurrentStateResults {
  state: {
    abbr: string;
    name: string;
    jurisdictions: Jurisdiction[] | null;
  };
}

const useCurrentState = () => {
  const { stateAbbr = "" } = useParams();
  return useQuery<UseCurrentStateResults>(GET_STATE, {
    variables: { stateAbbr },
  });
};

export default useCurrentState;
