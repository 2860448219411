"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.awaitGcpFileExistence = void 0;
/**
 * Await the existence of a file in GCP Storage File object.
 * @param {File} file - GCP Storage File object
 * @param {number} interval - Interval in ms to check for file existence
 * @param {number} maxAttempts - Max number of attempts to check for file existence
 * @param {number} currentAttempt - Current attempt number
 * @returns {Promise<boolean>} - Promise that resolves to true if file exists
 * @throws {Error} - Throws error if file does not exist after maxAttempts
 * @example
 * const file = bucket.file("test.csv");
 * await awaitGcpFileExistence({ file });
 */
const awaitGcpFileExistence = ({ file, interval = 1000, maxAttempts = 3, currentAttempt = 0 }) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve, reject) => __awaiter(void 0, void 0, void 0, function* () {
        const [exists] = yield file.exists();
        if (exists) {
            return resolve(true);
        }
        if (currentAttempt >= maxAttempts) {
            return reject(new Error("File does not exist"));
        }
        setTimeout(() => __awaiter(void 0, void 0, void 0, function* () {
            const result = yield (0, exports.awaitGcpFileExistence)({
                file,
                interval,
                maxAttempts,
                currentAttempt: currentAttempt + 1
            });
            resolve(result);
        }), interval);
    }));
});
exports.awaitGcpFileExistence = awaitGcpFileExistence;
