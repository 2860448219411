import { useAuth } from "@reasongcp/react-fire-sub";
import React, { FC, PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";

const VerificationRedirect: FC<PropsWithChildren> = ({ children }) => {
  const user = useAuth();
  const location = useLocation();

  if (user && !user.emailVerified) {
    return (
      <Navigate
        to="/verify"
        state={{ from: location }}
        replace
      />
    );
  }

  return <>{children}</>;
};

export default VerificationRedirect;
