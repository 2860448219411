"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.physicalLocationData = void 0;
exports.physicalLocationData = {
    allPropertiesPopulatedAddress: null,
    addressOne: "",
    addressTwo: "",
    city: "",
    state: "",
    zipCode: "",
};
