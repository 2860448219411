export const config = {
  apiKey: "AIzaSyAFD-ZOD7O8oK9J_2fja8cJQiVw2UU0v2s",
  authDomain: "taxscribesuite-qa.firebaseapp.com",
  databaseURL: "https://taxscribesuite-qa.firebaseio.com",
  projectId: "taxscribesuite-qa",
  storageBucket: "taxscribesuite-qa.appspot.com",
  messagingSenderId: "910879869934",
  appId: "1:910879869934:web:b0de65b535ed0849d21cff",
  measurementId: "G-QL48NZG28Q",
};
