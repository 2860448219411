"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.verifyArgs = void 0;
const verifyArgs = (args, descriptions, parentKeys = []) => {
    Object.entries(descriptions).forEach(([key, description]) => {
        const receivedValue = args[key];
        const receivedType = typeof receivedValue;
        const fullPath = [...parentKeys, key].join(".");
        if (receivedType !== description.typeOf) {
            throw new Error(`Invalid argument type for ${fullPath}: ${receivedType}. Expected ${description}`);
        }
        if (description.required && !receivedValue) {
            throw new Error(`Missing required argument: ${fullPath}`);
        }
        if (description.childAttributes) {
            (0, exports.verifyArgs)(receivedValue, description.childAttributes, parentKeys === null || parentKeys === void 0 ? void 0 : parentKeys.concat(key));
        }
    });
    return args;
};
exports.verifyArgs = verifyArgs;
