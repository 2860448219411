"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeFlatObject = void 0;
const lodash_1 = require("lodash");
const makeFlatObject = (obj, prefix = "") => {
    return Object.entries(obj).reduce((acc, [key, val]) => {
        if (val === null)
            return acc;
        if (typeof val === "object" && val !== null) {
            return Object.assign(Object.assign({}, acc), (0, exports.makeFlatObject)(val, `${prefix}${key}.`));
        }
        return Object.assign(Object.assign({}, acc), { [(0, lodash_1.camelCase)(prefix + key)]: val });
    }, {});
};
exports.makeFlatObject = makeFlatObject;
