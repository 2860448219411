import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import useAfterSubmit from "./useAfterSubmit";

const SUBMIT_FORM = gql`
  mutation SubmitForm($formUuid: String!) {
    submitForm(uuid: $formUuid) {
      id
    }
  }
`;

const useSubmitForm = () => {
  const afterSubmit = useAfterSubmit();
  const [submit] = useMutation<{ Result: number }>(SUBMIT_FORM, {
    refetchQueries: ["GetForm", "GetMyForm"],
  });

  return useCallback(
    async (formUuid: string) => {
      await submit({
        variables: { formUuid },
      }).then((data) => {
        afterSubmit();
        return data;
      });
    },
    [submit, afterSubmit],
  );
};

export default useSubmitForm;
