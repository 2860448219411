import { useFormDeadline } from "@taxscribe/ui";
import React, { FC, PropsWithChildren, ReactNode } from "react";
import { useParams } from "react-router-dom";

export interface GuardMessageProps {
  timezone?: string;
  formType?: string;
  deadline: Date | null;
}

export interface DeadlineGuardProps {
  formType?: string;
  guardForExisting?: boolean;
  GuardMessage?: ReactNode | FC<GuardMessageProps>;
}

const DeadlineGuard: FC<PropsWithChildren<DeadlineGuardProps>> = ({
  children,
  formType = "",
  GuardMessage,
  guardForExisting,
}) => {
  const { error, parsedDeadline, afterDeadline, timezone } =
    useFormDeadline(formType);
  const { formUuid } = useParams();
  const isCandidate = !formUuid || guardForExisting;
  const shouldShowMessage = !error && afterDeadline && isCandidate;

  if (shouldShowMessage && typeof GuardMessage === "function") {
    return (
      <GuardMessage
        timezone={timezone}
        formType={formType}
        deadline={parsedDeadline}
      />
    );
  }

  if (shouldShowMessage) {
    return <>{GuardMessage || null}</>;
  }

  return <>{children}</>;
};

export default DeadlineGuard;
