"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const sectionI = {
    type: "object",
    default: {},
    title: "The sectionI Schema",
    required: ["personalPropertyInfo"],
    properties: {
        personalPropertyInfo: {
            type: "array",
            default: [],
            title: "The personalPropertyInfo Schema",
            items: {
                type: "object",
                default: {},
                title: "A Schema",
                required: [
                    "equipmentInfo",
                    "costInstalled",
                    "installationDate",
                    "leaseTermInMonths",
                    "manufactureYear",
                    "averageMonthlyRental",
                    "name",
                    "address",
                    "city",
                    "zipCode",
                    "stateAbbr",
                ],
                properties: {
                    equipmentInfo: {
                        $ref: "#/$defs/nonEmptyString",
                    },
                    costInstalled: {
                        type: "integer",
                        minimum: 1,
                    },
                    installationDate: {
                        type: "string",
                        format: "calendar",
                    },
                    leaseTermInMonths: {
                        type: "integer",
                        minimum: 1,
                    },
                    manufactureYear: {
                        type: "string",
                        format: "year",
                    },
                    averageMonthlyRental: {
                        type: "integer",
                        minimum: 1,
                    },
                    rate: {
                        minimum: 1,
                        type: "number",
                        format: "percentage",
                    },
                    name: {
                        $ref: "#/$defs/nonEmptyString",
                    },
                    address: {
                        $ref: "#/$defs/nonEmptyString",
                    },
                    city: {
                        $ref: "#/$defs/nonEmptyString",
                    },
                    zipCode: {
                        type: "string",
                        format: "zipCode",
                    },
                    stateAbbr: {
                        type: "string",
                        format: "state",
                    },
                },
            },
        },
    },
};
exports.default = sectionI;
