import React, { FC } from "react";
import { startCase, kebabCase } from "lodash";
import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

interface JurisCardProps {
  stateAbbr: string;
  county: string;
}

const JurisCard: FC<JurisCardProps> = ({ county, stateAbbr }) => {
  const countyName = `${startCase(county)} County`;
  const countySlug = kebabCase(county.toLowerCase());

  return (
    <Card className="h-100 text-center px-3 py-5 bg-bpp-primary rounded-0">
      <Card.Body className="p-0">
        <h5 className="card-title mb-4 text-white">{countyName}</h5>
        <Link
          to={`/${stateAbbr.toLowerCase()}/county/${countySlug}`}
          className="align-self-center text-nowrap"
        >
          <Button className="px-4 text-white">Launch</Button>
        </Link>
      </Card.Body>
    </Card>
  );
};

export default JurisCard;
