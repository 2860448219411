import { useFormGroupFilingYear } from "@taxscribe/ui";
import config from "config/hostnames";
import { useMemo } from "react";

const useNewFormMetadata = () => {
  const [err, { loading, called, filingYear }] =
    useFormGroupFilingYear({
      formGroup: config.formGroup,
    });

  const newFormMetadata = useMemo(() => {
    if (err) console.error(err);
    return {
      filingYear: String(filingYear || new Date().getFullYear()),
      app: "TSB",
    };
  }, [filingYear, err]);

  return {
    metadata: newFormMetadata,
    loading: loading || !called,
  };
};

export default useNewFormMetadata;
