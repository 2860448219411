"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const sectionE = {
    type: "object",
    default: {},
    title: "The sectionE Schema",
    required: ["rows"],
    properties: {
        rows: {
            type: "array",
            default: [],
            title: "The rows Schema",
            items: {
                type: "object",
                title: "A Schema",
                required: ["year", "rate", "total"],
                properties: {
                    year: {
                        type: "string",
                        title: "The year Schema",
                    },
                    value: {
                        type: "integer",
                        title: "The value Schema",
                    },
                    rate: {
                        type: "number",
                        title: "The rate Schema",
                    },
                    total: {
                        type: "integer",
                        title: "The total Schema",
                    },
                },
            },
        },
    },
};
exports.default = sectionE;
