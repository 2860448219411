import { gql } from "@apollo/client";
import { Rendition144 } from "@taxscribe/form";
import { fetchPrePopDataByPin } from "@taxscribe/ui";
import { cleanFormData, deepPurgeValues } from "@taxscribe/utils";
import createFormPage, { FormDataInjector } from "components/shared/createFormPage";
import createFormView from "components/shared/createFormView";
import { merge } from "lodash";

const CREATE_FORM = gql`
  mutation CreateRendition144(
    $app: String!
    $businessName: String
    $source: String!
    $jurisdiction: String!
    $formData: Rendition144DataInput!
  ) {
    createRendition144(
      app: $app
      source: $source
      formData: $formData
      businessName: $businessName
      jurisdiction: $jurisdiction
    )
  }
`;

const formType = "Rendition144";
const Rendition144Report = createFormView({
  Form: Rendition144,
  formType,
  formName: "Rendition144",
  header: "TX Rendition144",
  creationQuery: CREATE_FORM,
});

const injectFormData: FormDataInjector = ({
  formData,
  formUuid,
  currentSubmission,
}) => {
  if (!formData) return formData;
  if (!currentSubmission?.id) {
    const extraState: Record<string, any> = {};
    return deepPurgeValues(null, merge(cleanFormData(formData), extraState));
  }

  return deepPurgeValues(null, cleanFormData(formData));
};

const TXRendition144Page = createFormPage({
  formType,
  injectFormData,
  ViewForm: Rendition144Report,
  prePopForm: fetchPrePopDataByPin(formType),
});

export default TXRendition144Page;

