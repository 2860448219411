import React, { FC } from "react";
import styled from "styled-components";
import { Button, Card } from "react-bootstrap";

import { useJurisdictionContext } from "@taxscribe/ui";
import { GuardMessageProps } from "./DeadlineGuard";
import { Link } from "react-router-dom";
import { useProcessFormName } from "@taxscribe/ui";

const StyledDeadlineHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GenericDeadlinePastPage: FC<GuardMessageProps> = ({
  formType,
  deadline,
  timezone,
}) => {
  const { webPath, jurisdictionName } = useJurisdictionContext();
  const processFormName = useProcessFormName();
  const formName = processFormName(formType || "").toLowerCase();

  return (
    <Card.Body>
      <StyledDeadlineHeader>
        <h4 className="display-6 mb-4">Filing Deadline Has Passed</h4>
        <Link
          to={`/${webPath}/dashboard`}
          className="text-dark text-nowrap py-0 text-decoration-none"
        >
          <Button variant="info">Return to dashboard</Button>
        </Link>
      </StyledDeadlineHeader>
      <p className="text-muted">
        The deadline for filing a(n) {formName} for {jurisdictionName} has
        passed as of{" "}
        {deadline?.toLocaleDateString("en-US", { timeZone: timezone })}.
      </p>
    </Card.Body>
  );
};

export default GenericDeadlinePastPage;
