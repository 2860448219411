"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.affirmationData = void 0;
exports.affirmationData = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    signature: "",
};
