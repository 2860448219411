"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const dailyRental = [
    {
        required: ["dailyRental"],
        properties: {
            dailyRental: {
                enum: [false],
            },
        },
    },
    {
        required: ["dailyRental", "dailyRentalPropertiesList"],
        properties: {
            dailyRental: {
                enum: [true],
            },
            dailyRentalPropertiesList: {
                type: "array",
                minItems: 1,
                items: {
                    type: "object",
                    properties: {
                        idNumber: {
                            $ref: "#/$defs/nonEmptyString",
                        },
                        description: {
                            $ref: "#/$defs/nonEmptyString",
                        },
                        manufacturerName: {
                            $ref: "#/$defs/nonEmptyString",
                        },
                        make: {
                            $ref: "#/$defs/nonEmptyString",
                        },
                        model: {
                            $ref: "#/$defs/nonEmptyString",
                        },
                        serialNumber: {
                            $ref: "#/$defs/nonEmptyString",
                        },
                        yearInService: {
                            type: "string",
                            format: "year",
                        },
                        cost: {
                            type: "number",
                        },
                    },
                },
            },
        },
    },
];
exports.default = dailyRental;
