import { Container, Col, Card, Row } from "react-bootstrap";

export default function TermsOfService() {
  return (
    <Container className="py-4">
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Header
              as="h5"
              className="p-3"
            >
              Terms and Conditions of Use
            </Card.Header>
            <Card.Body>
              <p>
                Welcome to the <b>TaxScribe</b> website operated by Reason
                Consulting Corporation. Please review these Terms and Conditions
                carefully (“Terms”). Your access and use of this website (“Site”
                or &quot;Website&quot;) constitute your consent to the Terms and
                your agreement to be legally bound by them. There may be
                additional or different terms and conditions stated elsewhere on
                the Site that apply to your access and use of the Site. Unless
                otherwise stated in these Terms, any reference to
                &quot;Reason&quot; or &quot;Reason Consulting&quot; is also a
                reference to Reason Consulting Corporation.
              </p>
              <p className="fw-bold">1. INTRODUCTION</p>
              <p>
                These Website Standard Terms And Conditions (these “Terms” or
                these “Website Standard Terms And Conditions”) contained herein
                on this webpage, shall govern your use of this website,
                including all pages within this website (collectively referred
                to herein below as this “Website”). These Terms apply in full
                force and effect to your use of this Website and by using this
                Website, you expressly accept all terms and conditions contained
                herein in full. You must not use this Website, if you have any
                objection to any of these Website Standard Terms And Conditions.
                <br />
                This Website is not for use by any minors (defined as those who
                are not at least 18 years of age), and you must not use this
                Website if you are a minor.
                <br />
                Reason Consulting welcomes your comments and suggestions about
                the Site and the TaxScribe services, but asks that you do not
                send Reason Consulting any confidential or proprietary ideas,
                suggestions, materials or other information relating to
                developing, designing, redesigning, modifying, providing, or
                marketing any of Reason Consulting’s products or services or any
                new products or services. You agree that any information or
                materials that you or individuals acting on your behalf send to
                Reason Consulting, including but not limited to feedback, data,
                questions, comments, product or service ideas, know-how, or
                suggestions (collectively, “Information”), will not be
                considered confidential or proprietary, even if the Information
                is labeled “Confidential” or “Proprietary.” Reason Consulting
                will own all Information you submit to it via the Site, and by
                submitting the Information to Reason Consulting, you assign to
                Reason Consulting all worldwide rights, title, and interest in
                and to that Information. Reason Consulting will not have any
                obligation to keep Information confidential and will be free to
                reproduce, use, disclose and distribute Information to others
                without limitation or liability. Reason Consulting may use any
                ideas, concepts, know-how or techniques in the Information for
                any purpose whatsoever, including developing and marketing
                products or services that incorporate or otherwise embody
                Information, without providing any notice, compensation or
                attribution to you. You are prohibited from posting on or
                transmitting to the Site any unlawful, threatening, libelous,
                defamatory, obscene or pornographic material, or any material
                that would violate any law.
              </p>
              <p className="fw-bold">2. INTELLECTUAL PROPERTY RIGHTS</p>
              <p>
                Other than content you own, which you may have opted to include
                on this Website, under these Terms, Reason Consulting
                Corporation and/or its licensors own all rights to the
                intellectual property and material contained in this Website,
                and all such rights are reserved. You are granted a limited
                license only, subject to the restrictions provided in these
                Terms, for purposes of viewing the material contained on this
                Website.
              </p>
              <ol type="a">
                <li>
                  <b>Copyright</b>. Reason Consulting owns the Site and owns or
                  has the right to use all of the content on the Site, including
                  all text, images, graphics, photographs, video clips, audio
                  clips, designs, icons, wallpaper, characters, artwork, sounds,
                  information, software, data, and other materials, and all HTML
                  design, layouts, configurations, CGI, and other code and
                  scripts in any format used to implement the Site, and Reason
                  Consulting owns the proprietary formulas used to generated the
                  estimated tax value for business entities (collectively, the
                  “Content”), all of which are protected by copyright. Absent
                  Reason Consulting’s express written permission as to
                  particular Content on the Site, you may use the Content only
                  for limited personal, noncommercial, informational purposes,
                  and you may, subject to any stated restrictions or
                  limitations, print a single copy of a limited amount of
                  Content solely for these purposes, if the copy bears all
                  copyright and other intellectual property and proprietary
                  notices displayed on the web page.
                </li>
                <li>
                  <b>Restrictions</b>. You may not distribute, publish,
                  transmit, reverse engineer, decompile, modify, create
                  derivative works from, or in any way exploit, any of the
                  Content, in whole or in part, for any purpose without Reason
                  Consulting’s prior written consent. Additionally, framing
                  pages or parts of pages on the Site and deep linking to pages
                  in the Site are prohibited. You may not use the Site to
                  provide service bureau or other access or use of the Site to
                  third parties. You will not scrape information or data from
                  the Site, copy databases from the Site, or access the Site by
                  any means except registering as a user under the normal
                  functionality provided as a service to all site users.
                </li>
                <li>
                  <b>Trademarks</b>. Reason Consulting or its affiliates own or
                  license all trademarks, service marks, and trade names on the
                  Site, including “TaxScribe” “BizWorks” “BizLink” “TaxScribe
                  Plus” and “TaxScribe Pro” unless stated otherwise on the Site.
                  You may not use these marks without Reason Consulting’s prior
                  express written permission. Reason Consulting grants a limited
                  license to print media publishers only to use the trademarks
                  and service marks found on the Site solely for the purpose of
                  illustrating an article.
                </li>
                <li>
                  <b>Rights of User</b>. Except as expressly provided above,
                  Reason Consulting is not granting you a license under any
                  copyright, trademark, service mark, patent, or other
                  intellectual property or proprietary right in Content, and
                  nothing in these Terms will be interpreted as conferring by
                  implication, estoppel, or otherwise any license or right under
                  any patent, trademark, copyright, or other proprietary,
                  intellectual, or other right of Reason Consulting, its
                  affiliates, or any third party. You obtain no rights in the
                  products, services, processes, or technology described on this
                  Site by accessing or using the Site. Reason Consulting and its
                  affiliates, and all third party owners, retain all of those
                  rights.
                </li>
                <li>
                  <b>Copyright Infringement Claims</b>. If you are a copyright
                  owner and believe that your work has been copied and posted on
                  the Site in a way that constitutes copyright infringement,
                  please provide us with notice containing the following
                  information: (i) a physical or electronic signature of a
                  person authorized to act on behalf of the owner of an
                  exclusive right that is allegedly infringed; (ii)
                  identification of the copyrighted work or works claimed to
                  have been infringed; (iii) identification of the material that
                  you claim is infringing and a description of where the
                  material that you claim is infringing is located on the Site;
                  (iv) your address, telephone number, and e-mail address; (v) a
                  written statement that you have a good faith belief that use
                  of the material in the manner complained of is not authorized
                  by the copyright owner, its agent, or the law; and (vi) a
                  statement that the information in the notification is
                  accurate, and, under penalty of perjury, that you are
                  authorized to act on behalf of the owner of an exclusive right
                  that is allegedly infringed. Any notification by a copyright
                  owner or a person authorized to act on its behalf that fails
                  to comply with these requirements will not be considered
                  sufficient notice and will not be deemed to confer on Reason
                  Consulting actual knowledge of facts or circumstances from
                  which infringing material or acts are evident. You must send
                  the written notice to: Reason Consulting Corporation, 5121
                  Parkway Plaza Blvd, Charlotte, NC 28217, Attn: CAVS
                  Administrator.
                </li>
              </ol>
              <p className="fw-bold">3. RESTRICTIONS</p>
              <p>
                You are expressly and emphatically restricted from all of the
                following:
              </p>
              <ol>
                <li>publishing any Website material in any media;</li>
                <li>
                  selling, sublicensing and/or otherwise commercializing any
                  Website material;
                </li>
                <li>
                  publicly performing and/or showing any Website material;
                </li>
                <li>
                  using this Website in any way that is, or may be, damaging to
                  this Website;
                </li>
                <li>
                  using this Website in any way that impacts user access to this
                  Website;
                </li>
                <li>
                  using this Website contrary to applicable laws and
                  regulations, or in a way that causes, or may cause, harm to
                  the Website, or to any person or business entity;{" "}
                </li>
                <li>
                  engaging in any data mining, data harvesting, data extracting
                  or any other similar activity in relation to this Website, or
                  while using this Website;{" "}
                </li>
                <li>
                  using this Website to engage in any advertising or marketing
                  unless expressly permitted by Reason Consulting under an
                  additional agreement;
                </li>
              </ol>
              <p>
                Certain areas of this Website are restricted from access by you
                and Reason Consulting Corporation may further restrict access by
                you to any areas of this Website, at any time, in its sole and
                absolute discretion. Any user ID and password you may have for
                this Website are condential and you must maintain
                confidentiality of such information.
              </p>
              <p className="fw-bold">4. YOUR CONTENT</p>
              <p>
                In these Website Standard Terms And Conditions, “Your Content”
                shall mean any audio, video, text, images or other material you
                choose to display on this Website. With respect to Your Content,
                by displaying it, you grant Reason Consulting Corporation a
                non-exclusive, worldwide, irrevocable, royalty-free,
                sublicensable license to use, reproduce, adapt, publish,
                translate and distribute it in any and all media.
                <br />
                Your Content must be your own and must not be infringing on any
                third party’s rights. Reason Consulting Corporation reserves the
                right to remove any of Your Content from this Website at any
                time, and for any reason, without notice.
              </p>
              <p className="fw-bold">5. NO WARRANTIES</p>
              <p>
                This Website is provided “as is,” with all faults, and Reason
                Consulting Corporation makes no express or implied
                representations or warranties, of any kind related to this
                Website or the materials contained on this Website.
                Additionally, nothing contained on this Website shall be
                construed as providing consultation or advice to you.
              </p>
              <p className="fw-bold">6. LIMITATION OF LIABILITY</p>
              <p>
                In no event shall Reason Consulting Corporation , nor any of its
                officers, directors and employees, be liable to you for anything
                arising out of or in any way connected with your use of this
                Website, whether such liability is under contract, tort or
                otherwise, and Reason Consulting Corporation, including its
                officers, directors and employees shall not be liable for any
                indirect, consequential or special liability arising out of or
                in any way related to your use of this Website.
                <br />
                EXCEPT AS EXPRESSLY PROVIDED IN THESE TERMS, IN NO EVENT WILL
                REASON CONSULTING, ITS AFFILIATES, ITS DATA PROVIDERS, OR ANYONE
                ELSE INVOLVED IN CREATING, PRODUCING, DELIVERING, OR MANAGING
                THE CONTENT (COLLECTIVELY, THE “SITE PROVIDERS”), BE LIABLE TO
                YOU OR ANY THIRD PARTY FOR ANY CLAIMS WHATSOEVER, IN CONTRACT,
                TORT, STRICT LIABILITY, OR OTHERWISE, INCLUDING DIRECT,
                INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL
                DAMAGES, LOST REVENUES, LOST PROFITS, LOST OPPORTUNITIES, AND
                LOSS OF PROSPECTIVE ECONOMIC ADVANTAGE, ARISING OUT OF OR IN
                CONNECTION WITH (A) YOUR ACCESS OR USE OF THE SITE, INCLUDING
                THE CAVS SERVICE, (B) ANY COMMUNICATIONS SENT TO YOU VIA THE
                SITE OR OTHERWISE FROM Reason Consulting, OR ANY DAMAGES
                SUFFERED AS A RESULT OF OMISSIONS OR INACCURACIES IN SUCH
                INFORMATION, (C) THE TRANSMISSION OF CONFIDENTIAL OR SENSITIVE
                INFORMATION TO OR FROM THE SITE PROVIDERS, (D) INCONVENIENCE,
                DELAY, OR LOSS OF USE OF THE SITE OR ANY SERVICE, EVEN IF SOME
                OR ALL OF THE SITE PROVIDERS ARE ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGES, OR (E) ANY BUSINESS PROPERTY TAX VALUATIONS
                PROVIDED BY THE SITE. WITHOUT LIMITING THE FOREGOING, THE SITE
                PROVIDERS ASSUME NO LIABILITY OR RESPONSIBILITY FOR DAMAGE OR
                INJURY TO PERSONS OR PROPERTY ARISING FROM ANY ACCESS OR USE OF
                ANY PRODUCT, INFORMATION, IDEA, OR INSTRUCTION IN THE CONTENT.
                If your jurisdiction does not permit such a disclaimer of
                liability, the parties agree that Reason Consulting’s maximum
                liability under these Terms will not exceed the amount that you
                have paid Reason Consulting to use the Site, it being
                acknowledged that actual damages would be difficult to
                ascertain.
              </p>
              <p className="fw-bold">7. INDEMNIFICATION</p>
              <p>
                You hereby indemnify to the fullest extent Reason Consulting
                Corporation from and against any and all liabilities, costs,
                demands, causes of action, damages and expenses (including
                reasonable attorney’s fees) arising out of or in any way related
                to your breach of any of the provisions of these Terms.
              </p>
              <p className="fw-bold">8. SEVERABILITY</p>
              <p>
                If any provision of these Terms is found to be unenforceable or
                invalid under any applicable law, such unenforceability or
                invalidity shall not render these Terms unenforceable or invalid
                as a whole, and such provisions shall be deleted without
                affecting the remaining provisions herein.
              </p>
              <p className="fw-bold">9. VARIATION OF TERMS</p>
              <p>
                Reason Consulting Corporation is permitted to revise these Terms
                at any time as it sees fit, and by using this Website you are
                expected to review such Terms on a regular basis to ensure you
                understand all terms and conditions governing use of this
                Website.
              </p>
              <p className="fw-bold">10. ASSIGNMENT</p>
              <p>
                Reason Consulting Corporation shall be permitted to assign,
                transfer, and subcontract its rights and/or obligations under
                these Terms without any notification or consent required.
                However, you shall not be permitted to assign, transfer, or
                subcontract any of your rights and/or obligations under these
                Terms.
              </p>
              <p className="fw-bold">11. ENTIRE AGREEMENT</p>
              <p>
                These Terms, including any legal notices and disclaimers
                contained on this Website, constitute the entire agreement
                between Reason Consulting Corporation and you in relation to
                your use of this Website, and supersede all prior agreements and
                understandings with respect to the same.
              </p>
              <p className="fw-bold">12. GOVERNING LAW &amp; JURISDICTION</p>
              <p>
                These Terms will be governed by and construed in accordance with
                the laws of the State of North Carolina, and you submit to the
                non-exclusive jurisdiction of the state and federal courts
                located in North Carolina for the resolution of any disputes.
              </p>
              <p className="fw-bold">13. LINKS</p>
              <p>
                The Site may contain links to third-party websites or resources.
                You acknowledge and agree that Reason Consulting is not
                responsible or liable for (a) the availability or accuracy of
                such websites or resources, or (b) the content, products, or
                services on or available from such websites or resources. Links
                to such websites or resources do not imply any endorsement by
                Reason Consulting of such websites or resources or the content,
                products, or services available from such websites or resources.
                You accept sole responsibility for and assume all risk arising
                from your use of any such websites or resources.
              </p>
              <p className="fw-bold">14. PUBLICITY</p>
              <p>
                You may not link to the Site, mention its content, or use its
                name, URL, or other identifying information within publically
                accessible resources without the express written consent of a
                corporate officer of Reason Consulting. This includes linking to
                the Site, posting the URL of the site to a webpage, or
                mentioning it by name in advertising or other electronic formats
                that are available on the world wide web.
              </p>
              <p className="fw-bold">15. RECORD-KEEPING</p>
              <p>
                Reason Consulting will store all forms you submit via the Site
                for a commercially reasonable period, which will meet or exceed
                any obligations imposed on Reason Consulting by applicable law.
              </p>
              <p className="fw-bold">16. NOT A TAX PREPARER; NO TAX ADVICE</p>
              <p>
                Under no circumstances will Reason Consulting be considered a
                “tax preparer” with respect to the delivery of any tax forms.
                Further, by using the Site, you acknowledge and agree that
                Reason Consulting is not: (a) providing any tax services for
                you; or (b) providing you tax advice of any kind in connection
                with the Site or any form.
              </p>
              <p className="fw-bold">17. NO CONFLICT OF INTEREST</p>
              <p>
                Notwithstanding anything in these Terms to the contrary, you
                acknowledge and agree that neither these Terms nor Reason
                Consulting’s performance of any services related to the
                TaxScribe program constitutes or creates any conflict of
                interest, including any conflict of interest which would (i)
                prohibit Reason Consulting from auditing, or otherwise impact
                Reason Consulting’s ability to audit, tax listings, statements,
                filings, and returns filed with any jurisdiction, or (ii)
                prohibit Reason Consulting from bidding upon, or otherwise
                impact Reason Consulting’s qualifications to bid upon, any
                proposal to audit tax listings, statements, filings, and returns
                filed with any jurisdiction.
              </p>
              <p className="fw-bold">18. FORCE MAJEURE</p>
              <p>
                Neither party will be liable for any breach or delay resulting
                from any cause beyond its reasonable control, including acts of
                God, war, insurrection, the public enemy, acts or omissions of
                any government, labor disputes or strikes, failure of power
                supply, or changes in the law.
              </p>
              <p className="fw-bold">19. PRICE</p>
              <p>
                You will pay Reason Consulting Corporation an annual
                subscription fee for access to and the right to use the
                TaxScribe website. The fee is published on the site and is based
                on a single user license and the associated features to which
                you elect to have access as selected by you on the site. You may
                pay the fee once a year, annually renewable on the anniversary
                of this agreement, or monthly based on recurring fee guaranteed
                by a stored payment method.
              </p>
              <p className="fw-bold">20. PAYMENT</p>
              <p>
                Reason Consulting will send you an invoice promptly after you
                register, and thereafter Reason Consulting will send you an
                invoice on or about the anniversary of the initial agreement
                date. All invoices must be paid within 30 days of the invoice
                date, and interest will accrue on any unpaid invoices at a rate
                of 1.5% per month until the amount is paid in full. If you do
                not pay the invoiced amount as and when due, Reason Consulting
                may disable your access to the TaxScribe website. Payment may be
                made online as presented in the invoice, and may be made in
                monthly installments if a recurring payment method is provided.
              </p>
              <p className="fw-bold">21. TERM</p>
              <p>
                The term of this agreement will begin when Reason Consulting
                approves your request for registration and will continue until
                either party gives the other party written notice of
                termination, or if you terminate this agreement by disabling
                your account using the functionality of the site.
              </p>
              <p className="fw-bold">22. NOTICE</p>
              <p>
                Any notice Reason Consulting is required or permitted to give
                you under these Terms will be effective if sent by e-mail to the
                e-mail address you provide when registering with the Site. Any
                notice you are required or permitted to give Reason Consulting
                under these Terms will be effective if sent by mail to the
                address listed below:
              </p>
              <p className="ps-3">
                Reason Consulting Corporation
                <br />
                5121 Parkway Plaza Blvd
                <br />
                Charlotte, NC 28217
                <br />
                Attn: TaxScribe Administrator
              </p>
              <p className="fw-bold">23. GENERAL</p>
              <p>
                Any waiver of a breach of these Terms must be in an express
                writing signed by the waiving party, and no waiver of any breach
                of any provision of these Terms will be deemed to be a waiver of
                any subsequent breach of that provision. The term “including” in
                these Terms will not be construed to be limiting. The
                unenforceability of any provision of these Terms will not affect
                the enforceability of any other provisions of these Terms, which
                will remain in full force and effect. If any of the covenants or
                provisions of these Terms are determined to be unenforceable by
                reason of its extent, duration, scope, or otherwise, the court
                making such determination will reduce such extent, duration,
                scope, or other provision and enforce them in their reduced form
                for all purposes contemplated by these Terms. Section headings
                in these Terms are provided for convenience only and will not
                affect its construction or interpretation. Signatures of the
                parties transmitted electronically will be deemed to be their
                original signatures for any purpose whatsoever. These Terms are
                the entire agreement between the parties with respect to the
                subject matter and supersede all prior agreements, whether
                written or oral, between the parties with respect to the subject
                matter. If any term or condition in these Terms conflicts with a
                term or condition in the website terms and conditions, the term
                or condition in these Terms will control. Except as expressly
                provided in these Terms, the use of the terms “hereunder,”
                “hereof,” “hereto,” “herein,” and words of similar import shall
                refer to these Terms as a whole and not to any particular
                section or clause in these Terms.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
