"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const standardAddress = {
    type: "object",
    properties: {
        street: {
            type: "string",
        },
        city: {
            type: "string",
        },
        state: {
            type: "string",
        },
        zipCode: {
            type: "string",
            format: "zipCode",
        },
    },
};
const nullableStandardAddress = Object.assign(Object.assign({}, standardAddress), { type: ["object", "null"] });
const standardAddressWithRequiredFields = {
    properties: {
        street: {
            $ref: "#/$defs/nonEmptyString",
        },
        city: {
            $ref: "#/$defs/nonEmptyString",
        },
        state: {
            $ref: "#/$defs/nonEmptyString",
        },
        zipCode: {
            type: "string",
            format: "zipCode",
            minLength: 1,
        },
    },
    required: ["street", "city", "state", "zipCode"],
};
const nullableStandardAddressWithRequiredFields = Object.assign(Object.assign({}, standardAddressWithRequiredFields), { type: ["object", "null"] });
const addresses = {
    standardAddress,
    nullableStandardAddress,
    standardAddressWithRequiredFields,
    nullableStandardAddressWithRequiredFields,
};
exports.default = addresses;
