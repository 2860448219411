"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ncE500Data = void 0;
const ncE536_1 = require("../ncE536");
exports.ncE500Data = {
    accountNumberPrimary: "",
    generalInformation: {
        name: "",
        periodBeginning: "",
        periodEnding: "",
        address: {
            street: "",
            city: "",
            state: "",
            zipcode: "",
        },
    },
    receipts: {
        grossReceipts: 0,
        salesForResale: 0,
        exemptReceipts: 0,
    },
    taxInformation: {
        excessCollections: 0,
        penalty: 0,
        interest: 0,
        currentPeriodPrepayment: 0,
        nextPeriodPrepayment: 0,
        credit: 0,
        creditExplanation: "",
        purchasesAndReceipts: {
            generalStateRate: {
                purchasesForUse: 0,
                receipts: 0,
            },
            threePercentStateRate: {
                purchasesForUse: 0,
                receipts: 0,
            },
            modularHomes: {
                purchasesForUse: 0,
                receipts: 0,
            },
            manufacturedHomes: {
                purchasesForUse: 0,
                receipts: 0,
            },
            twoPercentFoodRate: {
                purchasesForUse: 0,
                receipts: 0,
            },
            twoPercentCountyRate: {
                purchasesForUse: 0,
                receipts: 0,
            },
            twoAndOneQuarterPercentCountyRate: {
                purchasesForUse: 0,
                receipts: 0,
            },
            halfPercentTransitCountyRate: {
                purchasesForUse: 0,
                receipts: 0,
            },
            quarterPercentTransitCountyRate: {
                purchasesForUse: 0,
                receipts: 0,
            },
        },
    },
    ncE536: ncE536_1.ncE536Data,
};
