"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bppSample11_14_2024 = void 0;
exports.bppSample11_14_2024 = {
    Value: {
        OwnershipInformation: {
            // OwnerValidValues: {
            //   OwnershipTypes: [
            //     {
            //       DisplayValue: "GOVERNMENT",
            //       NcptsCode: "OWTYGOV",
            //     },
            //     {
            //       DisplayValue: "INDIVIDUAL",
            //       NcptsCode: "OWTYIND",
            //     },
            //     {
            //       DisplayValue: "BUSINESS",
            //       NcptsCode: "OWTYBUS",
            //     },
            //     {
            //       DisplayValue: "OTHERS",
            //       NcptsCode: "OWTYOTH",
            //     },
            //     {
            //       DisplayValue: "EXEMPT",
            //       NcptsCode: "OWTYXPT",
            //     },
            //   ],
            //   States: [
            //     {
            //       DisplayValue: null,
            //       NcptsCode: null,
            //     },
            //     {
            //       DisplayValue: "MARYLAND",
            //       NcptsCode: "STATMD",
            //     },
            //     {
            //       DisplayValue: "INDIANA",
            //       NcptsCode: "STATIN",
            //     },
            //     {
            //       DisplayValue: "SOUTH CAROLINA",
            //       NcptsCode: "STATSC",
            //     },
            //     {
            //       DisplayValue: "CALIFORNIA",
            //       NcptsCode: "STATCA",
            //     },
            //     {
            //       DisplayValue: "GEORGIA",
            //       NcptsCode: "STATGA",
            //     },
            //     {
            //       DisplayValue: "ILLINOIS",
            //       NcptsCode: "STATIL",
            //     },
            //     {
            //       DisplayValue: "ARMED FORCES AFRICA, CANADA, EUROPE, MIDDLE EAST",
            //       NcptsCode: "STATAE",
            //     },
            //     {
            //       DisplayValue: "SOUTH DAKOTA",
            //       NcptsCode: "STATSD",
            //     },
            //     {
            //       DisplayValue: "MARSHALL ISLANDS",
            //       NcptsCode: "STATMH",
            //     },
            //     {
            //       DisplayValue: "ARMED FORCES PACIFIC",
            //       NcptsCode: "STATAP",
            //     },
            //     {
            //       DisplayValue: "PENNSYLVANIA",
            //       NcptsCode: "STATPA",
            //     },
            //     {
            //       DisplayValue: "WISCONSIN",
            //       NcptsCode: "STATWI",
            //     },
            //     {
            //       DisplayValue: "CONNECTICUT",
            //       NcptsCode: "STATCT",
            //     },
            //     {
            //       DisplayValue: "TEXAS",
            //       NcptsCode: "STATTX",
            //     },
            //     {
            //       DisplayValue: "NEW MEXICO",
            //       NcptsCode: "STATNM",
            //     },
            //     {
            //       DisplayValue: "WEST VIRGINIA",
            //       NcptsCode: "STATWV",
            //     },
            //     {
            //       DisplayValue: "IDAHO",
            //       NcptsCode: "STATID",
            //     },
            //     {
            //       DisplayValue: "FLORIDA",
            //       NcptsCode: "STATFL",
            //     },
            //     {
            //       DisplayValue: "NORTH CAROLINA",
            //       NcptsCode: "STATNC",
            //     },
            //     {
            //       DisplayValue: "MICHIGAN",
            //       NcptsCode: "STATMI",
            //     },
            //     {
            //       DisplayValue: "OKLAHOMA",
            //       NcptsCode: "STATOK",
            //     },
            //     {
            //       DisplayValue: "NEW YORK",
            //       NcptsCode: "STATNY",
            //     },
            //     {
            //       DisplayValue: "TENNESSEE",
            //       NcptsCode: "STATTN",
            //     },
            //     {
            //       DisplayValue: "AMERICAN SAMOA",
            //       NcptsCode: "STATAS",
            //     },
            //     {
            //       DisplayValue: "LOUISIANA",
            //       NcptsCode: "STATLA",
            //     },
            //     {
            //       DisplayValue: "IOWA",
            //       NcptsCode: "STATIA",
            //     },
            //     {
            //       DisplayValue: "VERMONT",
            //       NcptsCode: "STATVT",
            //     },
            //     {
            //       DisplayValue: "VIRGINIA",
            //       NcptsCode: "STATVA",
            //     },
            //     {
            //       DisplayValue: "NEBRASKA",
            //       NcptsCode: "STATNE",
            //     },
            //     {
            //       DisplayValue: "NORTH DAKOTA",
            //       NcptsCode: "STATND",
            //     },
            //     {
            //       DisplayValue: "NEW HAMPSHIRE",
            //       NcptsCode: "STATNH",
            //     },
            //     {
            //       DisplayValue: "FEDERATED STATES OF MICRONESIA",
            //       NcptsCode: "STATFM",
            //     },
            //     {
            //       DisplayValue: "GUAM",
            //       NcptsCode: "STATGU",
            //     },
            //     {
            //       DisplayValue: "NORTHERN MARIANA ISLANDS",
            //       NcptsCode: "STATMP",
            //     },
            //     {
            //       DisplayValue: "NEW JERSEY",
            //       NcptsCode: "STATNJ",
            //     },
            //     {
            //       DisplayValue: "MISSOURI",
            //       NcptsCode: "STATMO",
            //     },
            //     {
            //       DisplayValue: "UTAH",
            //       NcptsCode: "STATUT",
            //     },
            //     {
            //       DisplayValue: "ONTARIO",
            //       NcptsCode: "STATON",
            //     },
            //     {
            //       DisplayValue: "MINNESOTA",
            //       NcptsCode: "STATMN",
            //     },
            //     {
            //       DisplayValue: "KANSAS",
            //       NcptsCode: "STATKS",
            //     },
            //     {
            //       DisplayValue: "WASHINGTON",
            //       NcptsCode: "STATWA",
            //     },
            //     {
            //       DisplayValue: "COLORADO",
            //       NcptsCode: "STATCO",
            //     },
            //     {
            //       DisplayValue: "OHIO",
            //       NcptsCode: "STATOH",
            //     },
            //     {
            //       DisplayValue: "RHODE ISLAND",
            //       NcptsCode: "STATRI",
            //     },
            //     {
            //       DisplayValue: "VIRGIN ISLANDS",
            //       NcptsCode: "STATVI",
            //     },
            //     {
            //       DisplayValue: "ARKANSAS",
            //       NcptsCode: "STATAR",
            //     },
            //     {
            //       DisplayValue: "DELAWARE",
            //       NcptsCode: "STATDE",
            //     },
            //     {
            //       DisplayValue: "ALABAMA",
            //       NcptsCode: "STATAL",
            //     },
            //     {
            //       DisplayValue: "HAWAII",
            //       NcptsCode: "STATHI",
            //     },
            //     {
            //       DisplayValue: "ARMED FORCES AMERICA",
            //       NcptsCode: "STATAA",
            //     },
            //     {
            //       DisplayValue: "MISSISSIPPI",
            //       NcptsCode: "STATMS",
            //     },
            //     {
            //       DisplayValue: "MASSACHUSETTS",
            //       NcptsCode: "STATMA",
            //     },
            //     {
            //       DisplayValue: "PALAU",
            //       NcptsCode: "STATPW",
            //     },
            //     {
            //       DisplayValue: "OREGON",
            //       NcptsCode: "STATOR",
            //     },
            //     {
            //       DisplayValue: "NEVADA",
            //       NcptsCode: "STATNV",
            //     },
            //     {
            //       DisplayValue: "KENTUCKY",
            //       NcptsCode: "STATKY",
            //     },
            //     {
            //       DisplayValue: "DISTRICT OF COLUMBIA",
            //       NcptsCode: "STATDC",
            //     },
            //     {
            //       DisplayValue: "MAINE",
            //       NcptsCode: "STATME",
            //     },
            //     {
            //       DisplayValue: "WYOMING",
            //       NcptsCode: "STATWY",
            //     },
            //     {
            //       DisplayValue: "PUERTO RICO",
            //       NcptsCode: "STATPR",
            //     },
            //     {
            //       DisplayValue: "ARIZONA",
            //       NcptsCode: "STATAZ",
            //     },
            //     {
            //       DisplayValue: "MONTANA",
            //       NcptsCode: "STATMT",
            //     },
            //     {
            //       DisplayValue: "ALASKA",
            //       NcptsCode: "STATAK",
            //     },
            //   ],
            //   PhoneTypes: [
            //     {
            //       DisplayValue: "PHONETREE",
            //       NcptsCode: "PHTYPHTR",
            //     },
            //     {
            //       DisplayValue: "HOME",
            //       NcptsCode: "PHTYHOM",
            //     },
            //     {
            //       DisplayValue: "FAX",
            //       NcptsCode: "PHTYFAX",
            //     },
            //     {
            //       DisplayValue: "E-PAYER",
            //       NcptsCode: "PHTYEPAY",
            //     },
            //     {
            //       DisplayValue: "CELLULAR",
            //       NcptsCode: "PHTYCEL",
            //     },
            //     {
            //       DisplayValue: "UNKNOWN",
            //       NcptsCode: "PHTYUNK",
            //     },
            //     {
            //       DisplayValue: "WORK",
            //       NcptsCode: "PHTYWRK",
            //     },
            //   ],
            //   BusinessTypes: [
            //     {
            //       DisplayValue: null,
            //       NcptsCode: null,
            //     },
            //     {
            //       DisplayValue: "FORMER",
            //       NcptsCode: "BUNTFRMR",
            //     },
            //     {
            //       DisplayValue: "DOING BUSINESS AS",
            //       NcptsCode: "BUNTDBA",
            //     },
            //     {
            //       DisplayValue: "TRADE",
            //       NcptsCode: "BUNTTRAD",
            //     },
            //     {
            //       DisplayValue: "AS KNOWN AS",
            //       NcptsCode: "BUNTAKA",
            //     },
            //   ],
            //   Counties: [
            //     {
            //       DisplayValue: null,
            //       NcptsCode: null,
            //     },
            //     {
            //       DisplayValue: "FORSYTH",
            //       NcptsCode: "CNTYFORSY",
            //     },
            //     {
            //       DisplayValue: "TRANSYLVANIA",
            //       NcptsCode: "CNTYTRANS",
            //     },
            //     {
            //       DisplayValue: "ONSLOW",
            //       NcptsCode: "CNTYONSLO",
            //     },
            //     {
            //       DisplayValue: "POLK",
            //       NcptsCode: "CNTYPOLK",
            //     },
            //     {
            //       DisplayValue: "EDGECOMBE",
            //       NcptsCode: "CNTYEDGEC",
            //     },
            //     {
            //       DisplayValue: "VANCE",
            //       NcptsCode: "CNTYVANCE",
            //     },
            //     {
            //       DisplayValue: "CUMBERLAND",
            //       NcptsCode: "CNTYCUMBE",
            //     },
            //     {
            //       DisplayValue: "CHOWAN",
            //       NcptsCode: "CNTYCHOWA",
            //     },
            //     {
            //       DisplayValue: "DURHAM",
            //       NcptsCode: "CNTYDURHA",
            //     },
            //     {
            //       DisplayValue: "ROBESON",
            //       NcptsCode: "CNTYROBES",
            //     },
            //     {
            //       DisplayValue: "ASHE",
            //       NcptsCode: "CNTYASHE",
            //     },
            //     {
            //       DisplayValue: "TYRRELL",
            //       NcptsCode: "CNTYTYRRE",
            //     },
            //     {
            //       DisplayValue: "FRANKLIN",
            //       NcptsCode: "CNTYFRANK",
            //     },
            //     {
            //       DisplayValue: "CURRITUCK",
            //       NcptsCode: "CNTYCURRI",
            //     },
            //     {
            //       DisplayValue: "GREENE",
            //       NcptsCode: "CNTYGREEN",
            //     },
            //     {
            //       DisplayValue: "ALAMANCE",
            //       NcptsCode: "CNTYALAMA",
            //     },
            //     {
            //       DisplayValue: "BRUNSWICK",
            //       NcptsCode: "CNTYBRUNS",
            //     },
            //     {
            //       DisplayValue: "UNION",
            //       NcptsCode: "CNTYUNION",
            //     },
            //     {
            //       DisplayValue: "MONTGOMERY",
            //       NcptsCode: "CNTYMONTG",
            //     },
            //     {
            //       DisplayValue: "PASQUOTAWK",
            //       NcptsCode: "CNTYPASQU",
            //     },
            //     {
            //       DisplayValue: "GRAHAM",
            //       NcptsCode: "CNTYGRAHA",
            //     },
            //     {
            //       DisplayValue: "WAKE",
            //       NcptsCode: "CNTYWAKE",
            //     },
            //     {
            //       DisplayValue: "AVERY",
            //       NcptsCode: "CNTYAVERY",
            //     },
            //     {
            //       DisplayValue: "YADKIN",
            //       NcptsCode: "CNTYYADKI",
            //     },
            //     {
            //       DisplayValue: "WASHINGTON",
            //       NcptsCode: "CNTYWASHI",
            //     },
            //     {
            //       DisplayValue: "DUPLIN",
            //       NcptsCode: "CNTYDUPLI",
            //     },
            //     {
            //       DisplayValue: "LINCOLN",
            //       NcptsCode: "CNTYLINCO",
            //     },
            //     {
            //       DisplayValue: "GUILFORD",
            //       NcptsCode: "CNTYGUILF",
            //     },
            //     {
            //       DisplayValue: "WILSON",
            //       NcptsCode: "CNTYWILSO",
            //     },
            //     {
            //       DisplayValue: "MECKLENBURG",
            //       NcptsCode: "CNTYMECKL",
            //     },
            //     {
            //       DisplayValue: "ALEXANDER",
            //       NcptsCode: "CNTYALEXA",
            //     },
            //     {
            //       DisplayValue: "NEW HANOVER",
            //       NcptsCode: "CNTYNEW H",
            //     },
            //     {
            //       DisplayValue: "SURRY",
            //       NcptsCode: "CNTYSURRY",
            //     },
            //     {
            //       DisplayValue: "DARE",
            //       NcptsCode: "CNTYDARE",
            //     },
            //     {
            //       DisplayValue: "HENDERSON",
            //       NcptsCode: "CNTYHENDE",
            //     },
            //     {
            //       DisplayValue: "WILKES",
            //       NcptsCode: "CNTYWILKE",
            //     },
            //     {
            //       DisplayValue: "ANSON",
            //       NcptsCode: "CNTYANSON",
            //     },
            //     {
            //       DisplayValue: "STANLEY",
            //       NcptsCode: "CNTYSTANL",
            //     },
            //     {
            //       DisplayValue: "GASTON",
            //       NcptsCode: "CNTYGASTO",
            //     },
            //     {
            //       DisplayValue: "RUTHERFORD",
            //       NcptsCode: "CNTYRUTHE",
            //     },
            //     {
            //       DisplayValue: "CABARRUS",
            //       NcptsCode: "CNTYCABAR",
            //     },
            //     {
            //       DisplayValue: "SCOTLAND",
            //       NcptsCode: "CNTYSCOTL",
            //     },
            //     {
            //       DisplayValue: "NASH",
            //       NcptsCode: "CNTYNASH",
            //     },
            //     {
            //       DisplayValue: "WARREN",
            //       NcptsCode: "CNTYWARRE",
            //     },
            //     {
            //       DisplayValue: "CHEROKEE",
            //       NcptsCode: "CNTYCHERO",
            //     },
            //     {
            //       DisplayValue: "ALLEGANY",
            //       NcptsCode: "CNTYALLEG",
            //     },
            //     {
            //       DisplayValue: "WATAUGA",
            //       NcptsCode: "CNTYWATAU",
            //     },
            //     {
            //       DisplayValue: "RANDOLPH",
            //       NcptsCode: "CNTYRANDO",
            //     },
            //     {
            //       DisplayValue: "GATES",
            //       NcptsCode: "CNTYGATES",
            //     },
            //     {
            //       DisplayValue: "CRAVEN",
            //       NcptsCode: "CNTYCRAVE",
            //     },
            //     {
            //       DisplayValue: "CALDWELL",
            //       NcptsCode: "CNTYCALDW",
            //     },
            //     {
            //       DisplayValue: "WAYNE",
            //       NcptsCode: "CNTYWAYNE",
            //     },
            //     {
            //       DisplayValue: "ORANGE",
            //       NcptsCode: "CNTYORANG",
            //     },
            //     {
            //       DisplayValue: "BERTIE",
            //       NcptsCode: "CNTYBERTI",
            //     },
            //     {
            //       DisplayValue: "HAYWOOD",
            //       NcptsCode: "CNTYHAYWO",
            //     },
            //     {
            //       DisplayValue: "PENDER",
            //       NcptsCode: "CNTYPENDE",
            //     },
            //     {
            //       DisplayValue: "HALIFAX",
            //       NcptsCode: "CNTYHALIF",
            //     },
            //     {
            //       DisplayValue: "MOORE",
            //       NcptsCode: "CNTYMOORE",
            //     },
            //     {
            //       DisplayValue: "CLEVELAND",
            //       NcptsCode: "CNTYCLEVE",
            //     },
            //     {
            //       DisplayValue: "MACON",
            //       NcptsCode: "CNTYMACON",
            //     },
            //     {
            //       DisplayValue: "YANCEY",
            //       NcptsCode: "CNTYYANCE",
            //     },
            //     {
            //       DisplayValue: "NORTHAMPTON",
            //       NcptsCode: "CNTYNORTH",
            //     },
            //     {
            //       DisplayValue: "GRANVILLE",
            //       NcptsCode: "CNTYGRANV",
            //     },
            //     {
            //       DisplayValue: "DAVIE",
            //       NcptsCode: "CNTYDAVIE",
            //     },
            //     {
            //       DisplayValue: "RICHMOND",
            //       NcptsCode: "CNTYRICHM",
            //     },
            //     {
            //       DisplayValue: "CASWELL",
            //       NcptsCode: "CNTYCASWE",
            //     },
            //     {
            //       DisplayValue: "JONES",
            //       NcptsCode: "CNTYJONES",
            //     },
            //     {
            //       DisplayValue: "JACKSON",
            //       NcptsCode: "CNTYJACKS",
            //     },
            //     {
            //       DisplayValue: "ROCKINGHAM",
            //       NcptsCode: "CNTYROCKI",
            //     },
            //     {
            //       DisplayValue: "PAMLICO",
            //       NcptsCode: "CNTYPAMLI",
            //     },
            //     {
            //       DisplayValue: "HOKE",
            //       NcptsCode: "CNTYHOKE",
            //     },
            //     {
            //       DisplayValue: "LENOIR",
            //       NcptsCode: "CNTYLENOI",
            //     },
            //     {
            //       DisplayValue: "CARTERET",
            //       NcptsCode: "CNTYCARTE",
            //     },
            //     {
            //       DisplayValue: "HERTFORD",
            //       NcptsCode: "CNTYHERTF",
            //     },
            //     {
            //       DisplayValue: "JOHNSTON",
            //       NcptsCode: "CNTYJOHNS",
            //     },
            //     {
            //       DisplayValue: "ROWAN",
            //       NcptsCode: "CNTYROWAN",
            //     },
            //     {
            //       DisplayValue: "MARTIN",
            //       NcptsCode: "CNTYMARTI",
            //     },
            //     {
            //       DisplayValue: "DAVIDSON",
            //       NcptsCode: "CNTYDAVID",
            //     },
            //     {
            //       DisplayValue: "CHATHAM",
            //       NcptsCode: "CNTYCHATH",
            //     },
            //     {
            //       DisplayValue: "PERQUIMANS",
            //       NcptsCode: "CNTYPERQU",
            //     },
            //     {
            //       DisplayValue: "PITT",
            //       NcptsCode: "CNTYPITT",
            //     },
            //     {
            //       DisplayValue: "CAMDEN",
            //       NcptsCode: "CNTYCAMDE",
            //     },
            //     {
            //       DisplayValue: "BURKE",
            //       NcptsCode: "CNTYBURKE",
            //     },
            //     {
            //       DisplayValue: "PERSON",
            //       NcptsCode: "CNTYPERSO",
            //     },
            //     {
            //       DisplayValue: "COLUMBUS",
            //       NcptsCode: "CNTYCOLUM",
            //     },
            //     {
            //       DisplayValue: "LEE",
            //       NcptsCode: "CNTYLEE",
            //     },
            //     {
            //       DisplayValue: "BLADEN",
            //       NcptsCode: "CNTYBLADE",
            //     },
            //     {
            //       DisplayValue: "CLAY",
            //       NcptsCode: "CNTYCLAY",
            //     },
            //     {
            //       DisplayValue: "BUNCOMBE",
            //       NcptsCode: "CNTYBUNCO",
            //     },
            //     {
            //       DisplayValue: "MITCHELL",
            //       NcptsCode: "CNTYMITCH",
            //     },
            //     {
            //       DisplayValue: "MCDOWELL",
            //       NcptsCode: "CNTYMCDOW",
            //     },
            //     {
            //       DisplayValue: "BEAUFORT",
            //       NcptsCode: "CNTYBEAUF",
            //     },
            //     {
            //       DisplayValue: "SWAIN",
            //       NcptsCode: "CNTYSWAIN",
            //     },
            //     {
            //       DisplayValue: "IREDELL",
            //       NcptsCode: "CNTYIREDE",
            //     },
            //     {
            //       DisplayValue: "CATAWBA",
            //       NcptsCode: "CNTYCATAW",
            //     },
            //     {
            //       DisplayValue: "STOKES",
            //       NcptsCode: "CNTYSTOKE",
            //     },
            //     {
            //       DisplayValue: "HYDE",
            //       NcptsCode: "CNTYHYDE",
            //     },
            //     {
            //       DisplayValue: "SAMPSON",
            //       NcptsCode: "CNTYSAMPS",
            //     },
            //     {
            //       DisplayValue: "HARNETT",
            //       NcptsCode: "CNTYHARNE",
            //     },
            //   ],
            //   OwnerOrders: [
            //     {
            //       DisplayValue: "SECONDARY",
            //       NcptsCode: "OWORSEC",
            //     },
            //     {
            //       DisplayValue: "OTHERS",
            //       NcptsCode: "OWOROTH",
            //     },
            //     {
            //       DisplayValue: "PRIMARY",
            //       NcptsCode: "OWORPRI",
            //     },
            //   ],
            // },
            Owners: [
                {
                    OwnershipType: "BUSINESS",
                    OwnerName: {
                        BusinessName: "ENVIRONMENTAL REFERENCE MATERIALS INC",
                        BusinessType: "",
                        BusinessTypeNCPTSLookupCode: "",
                    },
                    COACode: "",
                    GroupNumber: "",
                    OwnerOrderNCPTSCode: "OWORPRI",
                    MailingAddress: {
                        AddressLine3: "",
                        AddressLine2: "",
                        AddressLine1: "PO BOX 12527",
                        StateNCPTSCode: "STATNC",
                        State: "NORTH CAROLINA",
                        InCareOf: "",
                        PostalCode: "27709",
                        CountyNCPTSLookupCode: "CNTYDURHA",
                        City: "RTP",
                        PostalCodeExtension: "",
                        County: "DURHAM",
                    },
                    Confidential: "False",
                    OwnershipTypeNCPTSCode: "OWTYBUS",
                    SendMail: "Y",
                    DBAName: {},
                    PercentOwnership: "100.00",
                    OwnerPk: "1636322",
                    OwnerPhoneNumbers: [
                        {
                            Extension: "",
                            Phone1: "544",
                            Type: "HOME",
                            Phone2: "1669",
                            AreaCode: "919",
                            IsDefaultPhone: "Y",
                            TypeNCPTSLookupCode: "PHTYHOM",
                        },
                        {
                            Extension: "",
                            Phone1: "213",
                            Type: "WORK",
                            Phone2: "1308",
                            AreaCode: "608",
                            IsDefaultPhone: "",
                            TypeNCPTSLookupCode: "PHTYWRK",
                        },
                    ],
                    OwnerOrder: "PRIMARY",
                    EligibleForTaxRelief: "False",
                },
            ],
        },
        BusinessInfo: {
            NAICSCode: "541620",
            BusinessBeganDate: "6/1/1989 12:00:00 AM",
            BusinessYearEnd: "12/31/2009 12:00:00 AM",
            BusinessInfoValidValues: {
                BusinessClasses: [
                    {
                        DisplayValue: "Farming",
                        NcptsCode: "BUCLFARM",
                    },
                    {
                        DisplayValue: "Retail",
                        NcptsCode: "BUCLRETA",
                    },
                    {
                        DisplayValue: "MANUFACTURING",
                        NcptsCode: "BUCLMFGH",
                    },
                    {
                        DisplayValue: "WAREHOUSE STORAGE",
                        NcptsCode: "BUCLWARE",
                    },
                    {
                        DisplayValue: "Wholesale",
                        NcptsCode: "BUCLWHOL",
                    },
                    {
                        DisplayValue: "Leasing/Rental",
                        NcptsCode: "BUCLLEAS",
                    },
                    {
                        DisplayValue: "Other",
                        NcptsCode: "BUCLOTHR",
                    },
                    {
                        DisplayValue: "PROFESSIONAL",
                        NcptsCode: "BUCLPROF",
                    },
                    {
                        DisplayValue: "COMMERCIAL",
                        NcptsCode: "BUCLCOMM",
                    },
                    {
                        DisplayValue: "Service",
                        NcptsCode: "BUCLSERV",
                    },
                ],
            },
            BusinessClassNCPTSLookupCode: "BUCLSERV",
            BusinessClass: "Service",
        },
        CountiesWhereBusinessIsListed: [
            {
                CountyNCPTSLookupCode: "",
                County: "",
            },
        ],
        OutOfBusinessInfo: {
            OutOfBusinessReasonNCPTSCode: "",
            OutOfBusinessInfoValidValues: {
                OutOfBusinessReasons: [
                    {
                        DisplayValue: null,
                        NcptsCode: null,
                    },
                    {
                        DisplayValue: "Bankrupt",
                        NcptsCode: "VDRSBANK",
                    },
                    {
                        DisplayValue: "Sold",
                        NcptsCode: "VDRSSOLD",
                    },
                    {
                        DisplayValue: "Other",
                        NcptsCode: "VDRSOTHER",
                    },
                    {
                        DisplayValue: "Closed",
                        NcptsCode: "VDRSCLOSED",
                    },
                ],
            },
            OutOfBusinessDate: "",
            OutOfBusinessReason: "",
        },
        SitusInformation: {
            StateNCPTSCode: "",
            TaxDistricts: [
                {
                    TaxPercentage: "100",
                    County: "DURHAM COUNTY",
                    CountyNCPTSLookupCode: "JURS300",
                },
                {
                    TaxPercentage: "100",
                    County: "DURHAM",
                    CountyNCPTSLookupCode: "JURS360",
                },
                {
                    TaxPercentage: "100",
                    County: "CAPITAL FINANCE",
                    CountyNCPTSLookupCode: "JURS305",
                },
            ],
            Address2: "",
            PostalCode: "",
            Address3: "DURHAM COUNTY",
            City: "",
            Address1: "CARDINAL SELF STORAGE MLK PKWY UNIT D261",
            PostalCodeExtension: "",
            County: "",
            SitusValidValues: {
                States: [
                    {
                        DisplayValue: null,
                        NcptsCode: null,
                    },
                    {
                        DisplayValue: "MARYLAND",
                        NcptsCode: "STATMD",
                    },
                    {
                        DisplayValue: "INDIANA",
                        NcptsCode: "STATIN",
                    },
                    {
                        DisplayValue: "SOUTH CAROLINA",
                        NcptsCode: "STATSC",
                    },
                    {
                        DisplayValue: "CALIFORNIA",
                        NcptsCode: "STATCA",
                    },
                    {
                        DisplayValue: "GEORGIA",
                        NcptsCode: "STATGA",
                    },
                    {
                        DisplayValue: "ILLINOIS",
                        NcptsCode: "STATIL",
                    },
                    {
                        DisplayValue: "ARMED FORCES AFRICA, CANADA, EUROPE, MIDDLE EAST",
                        NcptsCode: "STATAE",
                    },
                    {
                        DisplayValue: "SOUTH DAKOTA",
                        NcptsCode: "STATSD",
                    },
                    {
                        DisplayValue: "MARSHALL ISLANDS",
                        NcptsCode: "STATMH",
                    },
                    {
                        DisplayValue: "ARMED FORCES PACIFIC",
                        NcptsCode: "STATAP",
                    },
                    {
                        DisplayValue: "PENNSYLVANIA",
                        NcptsCode: "STATPA",
                    },
                    {
                        DisplayValue: "WISCONSIN",
                        NcptsCode: "STATWI",
                    },
                    {
                        DisplayValue: "CONNECTICUT",
                        NcptsCode: "STATCT",
                    },
                    {
                        DisplayValue: "TEXAS",
                        NcptsCode: "STATTX",
                    },
                    {
                        DisplayValue: "NEW MEXICO",
                        NcptsCode: "STATNM",
                    },
                    {
                        DisplayValue: "WEST VIRGINIA",
                        NcptsCode: "STATWV",
                    },
                    {
                        DisplayValue: "IDAHO",
                        NcptsCode: "STATID",
                    },
                    {
                        DisplayValue: "FLORIDA",
                        NcptsCode: "STATFL",
                    },
                    {
                        DisplayValue: "NORTH CAROLINA",
                        NcptsCode: "STATNC",
                    },
                    {
                        DisplayValue: "MICHIGAN",
                        NcptsCode: "STATMI",
                    },
                    {
                        DisplayValue: "OKLAHOMA",
                        NcptsCode: "STATOK",
                    },
                    {
                        DisplayValue: "NEW YORK",
                        NcptsCode: "STATNY",
                    },
                    {
                        DisplayValue: "TENNESSEE",
                        NcptsCode: "STATTN",
                    },
                    {
                        DisplayValue: "AMERICAN SAMOA",
                        NcptsCode: "STATAS",
                    },
                    {
                        DisplayValue: "LOUISIANA",
                        NcptsCode: "STATLA",
                    },
                    {
                        DisplayValue: "IOWA",
                        NcptsCode: "STATIA",
                    },
                    {
                        DisplayValue: "VERMONT",
                        NcptsCode: "STATVT",
                    },
                    {
                        DisplayValue: "VIRGINIA",
                        NcptsCode: "STATVA",
                    },
                    {
                        DisplayValue: "NEBRASKA",
                        NcptsCode: "STATNE",
                    },
                    {
                        DisplayValue: "NORTH DAKOTA",
                        NcptsCode: "STATND",
                    },
                    {
                        DisplayValue: "NEW HAMPSHIRE",
                        NcptsCode: "STATNH",
                    },
                    {
                        DisplayValue: "FEDERATED STATES OF MICRONESIA",
                        NcptsCode: "STATFM",
                    },
                    {
                        DisplayValue: "GUAM",
                        NcptsCode: "STATGU",
                    },
                    {
                        DisplayValue: "NORTHERN MARIANA ISLANDS",
                        NcptsCode: "STATMP",
                    },
                    {
                        DisplayValue: "NEW JERSEY",
                        NcptsCode: "STATNJ",
                    },
                    {
                        DisplayValue: "MISSOURI",
                        NcptsCode: "STATMO",
                    },
                    {
                        DisplayValue: "UTAH",
                        NcptsCode: "STATUT",
                    },
                    {
                        DisplayValue: "ONTARIO",
                        NcptsCode: "STATON",
                    },
                    {
                        DisplayValue: "MINNESOTA",
                        NcptsCode: "STATMN",
                    },
                    {
                        DisplayValue: "KANSAS",
                        NcptsCode: "STATKS",
                    },
                    {
                        DisplayValue: "WASHINGTON",
                        NcptsCode: "STATWA",
                    },
                    {
                        DisplayValue: "COLORADO",
                        NcptsCode: "STATCO",
                    },
                    {
                        DisplayValue: "OHIO",
                        NcptsCode: "STATOH",
                    },
                    {
                        DisplayValue: "RHODE ISLAND",
                        NcptsCode: "STATRI",
                    },
                    {
                        DisplayValue: "VIRGIN ISLANDS",
                        NcptsCode: "STATVI",
                    },
                    {
                        DisplayValue: "ARKANSAS",
                        NcptsCode: "STATAR",
                    },
                    {
                        DisplayValue: "DELAWARE",
                        NcptsCode: "STATDE",
                    },
                    {
                        DisplayValue: "ALABAMA",
                        NcptsCode: "STATAL",
                    },
                    {
                        DisplayValue: "HAWAII",
                        NcptsCode: "STATHI",
                    },
                    {
                        DisplayValue: "ARMED FORCES AMERICA",
                        NcptsCode: "STATAA",
                    },
                    {
                        DisplayValue: "MISSISSIPPI",
                        NcptsCode: "STATMS",
                    },
                    {
                        DisplayValue: "MASSACHUSETTS",
                        NcptsCode: "STATMA",
                    },
                    {
                        DisplayValue: "PALAU",
                        NcptsCode: "STATPW",
                    },
                    {
                        DisplayValue: "OREGON",
                        NcptsCode: "STATOR",
                    },
                    {
                        DisplayValue: "NEVADA",
                        NcptsCode: "STATNV",
                    },
                    {
                        DisplayValue: "KENTUCKY",
                        NcptsCode: "STATKY",
                    },
                    {
                        DisplayValue: "DISTRICT OF COLUMBIA",
                        NcptsCode: "STATDC",
                    },
                    {
                        DisplayValue: "MAINE",
                        NcptsCode: "STATME",
                    },
                    {
                        DisplayValue: "WYOMING",
                        NcptsCode: "STATWY",
                    },
                    {
                        DisplayValue: "PUERTO RICO",
                        NcptsCode: "STATPR",
                    },
                    {
                        DisplayValue: "ARIZONA",
                        NcptsCode: "STATAZ",
                    },
                    {
                        DisplayValue: "MONTANA",
                        NcptsCode: "STATMT",
                    },
                    {
                        DisplayValue: "ALASKA",
                        NcptsCode: "STATAK",
                    },
                ],
                Cities: [
                    {
                        DisplayValue: null,
                        NcptsCode: null,
                    },
                    {
                        DisplayValue: "CHAPEL HILL",
                        NcptsCode: "JURS375",
                    },
                    {
                        DisplayValue: "MORSVILL-ANNX-9-MTH",
                        NcptsCode: "JURSM09",
                    },
                    {
                        DisplayValue: "DURHAM",
                        NcptsCode: "JURS360",
                    },
                    {
                        DisplayValue: "DURHAM-ANNX-2-MTH",
                        NcptsCode: "JURSAO2",
                    },
                    {
                        DisplayValue: "RALEIGH-ANNX-9-MTH",
                        NcptsCode: "JURSR09",
                    },
                    {
                        DisplayValue: "RALEIGH-ANNX-10-MTH",
                        NcptsCode: "JURSR10",
                    },
                    {
                        DisplayValue: "DURHAM-ANNX-4-MTH",
                        NcptsCode: "JURSAO4",
                    },
                    {
                        DisplayValue: "DURHAM-ANNX-7-MTH",
                        NcptsCode: "JURSAO7",
                    },
                    {
                        DisplayValue: "BUTNER",
                        NcptsCode: "JURSBUTNER",
                    },
                    {
                        DisplayValue: "RALEIGH-ANNX-8-MTH",
                        NcptsCode: "JURSR08",
                    },
                    {
                        DisplayValue: "DURHAM-ANNX-3-MTH",
                        NcptsCode: "JURSAO3",
                    },
                    {
                        DisplayValue: "RALEIGH-ANNX-4-MTH",
                        NcptsCode: "JURSR04",
                    },
                    {
                        DisplayValue: "RALEIGH",
                        NcptsCode: "JURS376",
                    },
                    {
                        DisplayValue: "RALEIGH-ANNX-7-MTH",
                        NcptsCode: "JURSR07",
                    },
                    {
                        DisplayValue: "ROUGEMONT",
                        NcptsCode: "JURSROUGEMONT",
                    },
                    {
                        DisplayValue: "MORSVILL-ANNX-10-MTH",
                        NcptsCode: "JURSM10",
                    },
                    {
                        DisplayValue: "HILLSBOROUGH",
                        NcptsCode: "JURSHILLSBOROUGH",
                    },
                    {
                        DisplayValue: "DURHAM-ANNX-11-MTH",
                        NcptsCode: "JURSA11",
                    },
                    {
                        DisplayValue: "CARY",
                        NcptsCode: "JURS377",
                    },
                    {
                        DisplayValue: "MORSVILL-ANNX-3-MTH",
                        NcptsCode: "JURSM03",
                    },
                    {
                        DisplayValue: "DURHAM-ANNX-6-MTH",
                        NcptsCode: "JURSAO6",
                    },
                    {
                        DisplayValue: "DURHAM-ANNX-1-MTH",
                        NcptsCode: "JURSAO1",
                    },
                    {
                        DisplayValue: "BAHAMA",
                        NcptsCode: "JURSBAHAMA",
                    },
                    {
                        DisplayValue: "RALEIGH-ANNX-5-MTH",
                        NcptsCode: "JURSR05",
                    },
                    {
                        DisplayValue: "DURHAM-ANNX-9-MTH",
                        NcptsCode: "JURSAO9",
                    },
                    {
                        DisplayValue: "DURHAM-ANNX-10-MTH",
                        NcptsCode: "JURSA10",
                    },
                    {
                        DisplayValue: "RALEIGH-ANNX-6-MTH",
                        NcptsCode: "JURSR06",
                    },
                    {
                        DisplayValue: "MORRISVILLE",
                        NcptsCode: "JURS726",
                    },
                    {
                        DisplayValue: "RALEIGH-ANNX-1-MTH",
                        NcptsCode: "JURSR01",
                    },
                    {
                        DisplayValue: "RESEARCH-TRIANGLE-PA",
                        NcptsCode: "JURSRESEARCH-TRIANGLE-PA",
                    },
                    {
                        DisplayValue: "TIMBERLAKE",
                        NcptsCode: "JURSTIMBERLAKE",
                    },
                ],
                TaxDistricts: [
                    {
                        DisplayValue: "CHAPEL HILL",
                        NcptsCode: "JURS375",
                    },
                    {
                        DisplayValue: "MORSVILL-ANNX-9-MTH",
                        NcptsCode: "JURSM09",
                    },
                    {
                        DisplayValue: "DURHAM",
                        NcptsCode: "JURS360",
                    },
                    {
                        DisplayValue: "DURHAM-ANNX-2-MTH",
                        NcptsCode: "JURSAO2",
                    },
                    {
                        DisplayValue: "REDWOOD-FD",
                        NcptsCode: "JURS325",
                    },
                    {
                        DisplayValue: "RALEIGH-ANNX-9-MTH",
                        NcptsCode: "JURSR09",
                    },
                    {
                        DisplayValue: "DURHAM COUNTY",
                        NcptsCode: "JURS300",
                    },
                    {
                        DisplayValue: "RALEIGH-ANNX-10-MTH",
                        NcptsCode: "JURSR10",
                    },
                    {
                        DisplayValue: "DURHAM-BID",
                        NcptsCode: "JURS361",
                    },
                    {
                        DisplayValue: "DURHAM-ANNX-4-MTH",
                        NcptsCode: "JURSAO4",
                    },
                    {
                        DisplayValue: "DURHAM-ANNX-7-MTH",
                        NcptsCode: "JURSAO7",
                    },
                    {
                        DisplayValue: "BUTNER",
                        NcptsCode: "JURSBUTNER",
                    },
                    {
                        DisplayValue: "RALEIGH-ANNX-8-MTH",
                        NcptsCode: "JURSR08",
                    },
                    {
                        DisplayValue: "DURHAM-ANNX-3-MTH",
                        NcptsCode: "JURSAO3",
                    },
                    {
                        DisplayValue: "CAPITAL FINANCE",
                        NcptsCode: "JURS305",
                    },
                    {
                        DisplayValue: "RALEIGH-ANNX-4-MTH",
                        NcptsCode: "JURSR04",
                    },
                    {
                        DisplayValue: "RALEIGH",
                        NcptsCode: "JURS376",
                    },
                    {
                        DisplayValue: "RTP Special",
                        NcptsCode: "JURS345",
                    },
                    {
                        DisplayValue: "RALEIGH-ANNX-7-MTH",
                        NcptsCode: "JURSR07",
                    },
                    {
                        DisplayValue: "ROUGEMONT",
                        NcptsCode: "JURSROUGEMONT",
                    },
                    {
                        DisplayValue: "BAHAMA-FD",
                        NcptsCode: "JURS340",
                    },
                    {
                        DisplayValue: "MORSVILL-ANNX-10-MTH",
                        NcptsCode: "JURSM10",
                    },
                    {
                        DisplayValue: "LEBANON-FD",
                        NcptsCode: "JURS315",
                    },
                    {
                        DisplayValue: "HILLSBOROUGH",
                        NcptsCode: "JURSHILLSBOROUGH",
                    },
                    {
                        DisplayValue: "DURHAM-ANNX-11-MTH",
                        NcptsCode: "JURSA11",
                    },
                    {
                        DisplayValue: "CARY",
                        NcptsCode: "JURS377",
                    },
                    {
                        DisplayValue: "MORSVILL-ANNX-3-MTH",
                        NcptsCode: "JURSM03",
                    },
                    {
                        DisplayValue: "DURHAM-ANNX-6-MTH",
                        NcptsCode: "JURSAO6",
                    },
                    {
                        DisplayValue: "DURHAM-ANNX-1-MTH",
                        NcptsCode: "JURSAO1",
                    },
                    {
                        DisplayValue: "RTP Public Transit",
                        NcptsCode: "JURS346",
                    },
                    {
                        DisplayValue: "BETHESDA-SD",
                        NcptsCode: "JURS311",
                    },
                    {
                        DisplayValue: "BAHAMA",
                        NcptsCode: "JURSBAHAMA",
                    },
                    {
                        DisplayValue: "RALEIGH-ANNX-5-MTH",
                        NcptsCode: "JURSR05",
                    },
                    {
                        DisplayValue: "BETHESDA-FD",
                        NcptsCode: "JURS310",
                    },
                    {
                        DisplayValue: "DURHAM-ANNX-9-MTH",
                        NcptsCode: "JURSAO9",
                    },
                    {
                        DisplayValue: "DURHAM-ANNX-10-MTH",
                        NcptsCode: "JURSA10",
                    },
                    {
                        DisplayValue: "RALEIGH-ANNX-6-MTH",
                        NcptsCode: "JURSR06",
                    },
                    {
                        DisplayValue: "MORRISVILLE",
                        NcptsCode: "JURS726",
                    },
                    {
                        DisplayValue: "RALEIGH-ANNX-1-MTH",
                        NcptsCode: "JURSR01",
                    },
                    {
                        DisplayValue: "RESEARCH-TRIANGLE-PA",
                        NcptsCode: "JURSRESEARCH-TRIANGLE-PA",
                    },
                    {
                        DisplayValue: "ENO-FD",
                        NcptsCode: "JURS335",
                    },
                    {
                        DisplayValue: "TIMBERLAKE",
                        NcptsCode: "JURSTIMBERLAKE",
                    },
                    {
                        DisplayValue: "DCFR-SD",
                        NcptsCode: "JURS400",
                    },
                    {
                        DisplayValue: "MANGUM-BUTNR-SP",
                        NcptsCode: "JURS380",
                    },
                    {
                        DisplayValue: "PARKWOOD-FD",
                        NcptsCode: "JURS320",
                    },
                    {
                        DisplayValue: "NEW-HOPE-FD",
                        NcptsCode: "JURS330",
                    },
                ],
                Counties: [
                    {
                        DisplayValue: null,
                        NcptsCode: null,
                    },
                    {
                        DisplayValue: "DURHAM",
                        NcptsCode: "CNTYDURHA",
                    },
                ],
            },
            IsDetailed: "N",
            CountyNCPTSCode: "",
            State: "",
            SitusPk: "993881",
            CityNCPTSCode: "",
        },
        ContactInfo: {
            ContactAddress: {
                AddressLine3: null,
                AddressLine2: null,
                AddressLine1: null,
                StateNCPTSCode: null,
                State: null,
                InCareOf: null,
                PostalCode: null,
                CountyNCPTSLookupCode: null,
                City: null,
                PostalCodeExtension: null,
                County: null,
            },
            ContactPhone: {
                Extension: "",
                AreaCode: "608",
                Phone1: "213",
                Type: "HOME",
                Phone2: "1308",
                TypeNCPTSLookupCode: "PHTYHOM",
            },
            ContactValidValues: {
                States: [
                    {
                        DisplayValue: null,
                        NcptsCode: null,
                    },
                    {
                        DisplayValue: "MARYLAND",
                        NcptsCode: "STATMD",
                    },
                    {
                        DisplayValue: "INDIANA",
                        NcptsCode: "STATIN",
                    },
                    {
                        DisplayValue: "SOUTH CAROLINA",
                        NcptsCode: "STATSC",
                    },
                    {
                        DisplayValue: "CALIFORNIA",
                        NcptsCode: "STATCA",
                    },
                    {
                        DisplayValue: "GEORGIA",
                        NcptsCode: "STATGA",
                    },
                    {
                        DisplayValue: "ILLINOIS",
                        NcptsCode: "STATIL",
                    },
                    {
                        DisplayValue: "ARMED FORCES AFRICA, CANADA, EUROPE, MIDDLE EAST",
                        NcptsCode: "STATAE",
                    },
                    {
                        DisplayValue: "SOUTH DAKOTA",
                        NcptsCode: "STATSD",
                    },
                    {
                        DisplayValue: "MARSHALL ISLANDS",
                        NcptsCode: "STATMH",
                    },
                    {
                        DisplayValue: "ARMED FORCES PACIFIC",
                        NcptsCode: "STATAP",
                    },
                    {
                        DisplayValue: "PENNSYLVANIA",
                        NcptsCode: "STATPA",
                    },
                    {
                        DisplayValue: "WISCONSIN",
                        NcptsCode: "STATWI",
                    },
                    {
                        DisplayValue: "CONNECTICUT",
                        NcptsCode: "STATCT",
                    },
                    {
                        DisplayValue: "TEXAS",
                        NcptsCode: "STATTX",
                    },
                    {
                        DisplayValue: "NEW MEXICO",
                        NcptsCode: "STATNM",
                    },
                    {
                        DisplayValue: "WEST VIRGINIA",
                        NcptsCode: "STATWV",
                    },
                    {
                        DisplayValue: "IDAHO",
                        NcptsCode: "STATID",
                    },
                    {
                        DisplayValue: "FLORIDA",
                        NcptsCode: "STATFL",
                    },
                    {
                        DisplayValue: "NORTH CAROLINA",
                        NcptsCode: "STATNC",
                    },
                    {
                        DisplayValue: "MICHIGAN",
                        NcptsCode: "STATMI",
                    },
                    {
                        DisplayValue: "OKLAHOMA",
                        NcptsCode: "STATOK",
                    },
                    {
                        DisplayValue: "NEW YORK",
                        NcptsCode: "STATNY",
                    },
                    {
                        DisplayValue: "TENNESSEE",
                        NcptsCode: "STATTN",
                    },
                    {
                        DisplayValue: "AMERICAN SAMOA",
                        NcptsCode: "STATAS",
                    },
                    {
                        DisplayValue: "LOUISIANA",
                        NcptsCode: "STATLA",
                    },
                    {
                        DisplayValue: "IOWA",
                        NcptsCode: "STATIA",
                    },
                    {
                        DisplayValue: "VERMONT",
                        NcptsCode: "STATVT",
                    },
                    {
                        DisplayValue: "VIRGINIA",
                        NcptsCode: "STATVA",
                    },
                    {
                        DisplayValue: "NEBRASKA",
                        NcptsCode: "STATNE",
                    },
                    {
                        DisplayValue: "NORTH DAKOTA",
                        NcptsCode: "STATND",
                    },
                    {
                        DisplayValue: "NEW HAMPSHIRE",
                        NcptsCode: "STATNH",
                    },
                    {
                        DisplayValue: "FEDERATED STATES OF MICRONESIA",
                        NcptsCode: "STATFM",
                    },
                    {
                        DisplayValue: "GUAM",
                        NcptsCode: "STATGU",
                    },
                    {
                        DisplayValue: "NORTHERN MARIANA ISLANDS",
                        NcptsCode: "STATMP",
                    },
                    {
                        DisplayValue: "NEW JERSEY",
                        NcptsCode: "STATNJ",
                    },
                    {
                        DisplayValue: "MISSOURI",
                        NcptsCode: "STATMO",
                    },
                    {
                        DisplayValue: "UTAH",
                        NcptsCode: "STATUT",
                    },
                    {
                        DisplayValue: "ONTARIO",
                        NcptsCode: "STATON",
                    },
                    {
                        DisplayValue: "MINNESOTA",
                        NcptsCode: "STATMN",
                    },
                    {
                        DisplayValue: "KANSAS",
                        NcptsCode: "STATKS",
                    },
                    {
                        DisplayValue: "WASHINGTON",
                        NcptsCode: "STATWA",
                    },
                    {
                        DisplayValue: "COLORADO",
                        NcptsCode: "STATCO",
                    },
                    {
                        DisplayValue: "OHIO",
                        NcptsCode: "STATOH",
                    },
                    {
                        DisplayValue: "RHODE ISLAND",
                        NcptsCode: "STATRI",
                    },
                    {
                        DisplayValue: "VIRGIN ISLANDS",
                        NcptsCode: "STATVI",
                    },
                    {
                        DisplayValue: "ARKANSAS",
                        NcptsCode: "STATAR",
                    },
                    {
                        DisplayValue: "DELAWARE",
                        NcptsCode: "STATDE",
                    },
                    {
                        DisplayValue: "ALABAMA",
                        NcptsCode: "STATAL",
                    },
                    {
                        DisplayValue: "HAWAII",
                        NcptsCode: "STATHI",
                    },
                    {
                        DisplayValue: "ARMED FORCES AMERICA",
                        NcptsCode: "STATAA",
                    },
                    {
                        DisplayValue: "MISSISSIPPI",
                        NcptsCode: "STATMS",
                    },
                    {
                        DisplayValue: "MASSACHUSETTS",
                        NcptsCode: "STATMA",
                    },
                    {
                        DisplayValue: "PALAU",
                        NcptsCode: "STATPW",
                    },
                    {
                        DisplayValue: "OREGON",
                        NcptsCode: "STATOR",
                    },
                    {
                        DisplayValue: "NEVADA",
                        NcptsCode: "STATNV",
                    },
                    {
                        DisplayValue: "KENTUCKY",
                        NcptsCode: "STATKY",
                    },
                    {
                        DisplayValue: "DISTRICT OF COLUMBIA",
                        NcptsCode: "STATDC",
                    },
                    {
                        DisplayValue: "MAINE",
                        NcptsCode: "STATME",
                    },
                    {
                        DisplayValue: "WYOMING",
                        NcptsCode: "STATWY",
                    },
                    {
                        DisplayValue: "PUERTO RICO",
                        NcptsCode: "STATPR",
                    },
                    {
                        DisplayValue: "ARIZONA",
                        NcptsCode: "STATAZ",
                    },
                    {
                        DisplayValue: "MONTANA",
                        NcptsCode: "STATMT",
                    },
                    {
                        DisplayValue: "ALASKA",
                        NcptsCode: "STATAK",
                    },
                ],
                PhoneTypes: [
                    {
                        DisplayValue: null,
                        NcptsCode: null,
                    },
                    {
                        DisplayValue: "PHONETREE",
                        NcptsCode: "PHTYPHTR",
                    },
                    {
                        DisplayValue: "HOME",
                        NcptsCode: "PHTYHOM",
                    },
                    {
                        DisplayValue: "FAX",
                        NcptsCode: "PHTYFAX",
                    },
                    {
                        DisplayValue: "E-PAYER",
                        NcptsCode: "PHTYEPAY",
                    },
                    {
                        DisplayValue: "CELLULAR",
                        NcptsCode: "PHTYCEL",
                    },
                    {
                        DisplayValue: "UNKNOWN",
                        NcptsCode: "PHTYUNK",
                    },
                    {
                        DisplayValue: "WORK",
                        NcptsCode: "PHTYWRK",
                    },
                ],
                Counties: [
                    {
                        DisplayValue: null,
                        NcptsCode: null,
                    },
                    {
                        DisplayValue: "DURHAM",
                        NcptsCode: "CNTYDURHA",
                    },
                ],
                BusinessTypes: [
                    {
                        DisplayValue: null,
                        NcptsCode: null,
                    },
                    {
                        DisplayValue: "FORMER",
                        NcptsCode: "BUNTFRMR",
                    },
                    {
                        DisplayValue: "DOING BUSINESS AS",
                        NcptsCode: "BUNTDBA",
                    },
                    {
                        DisplayValue: "TRADE",
                        NcptsCode: "BUNTTRAD",
                    },
                    {
                        DisplayValue: "AS KNOWN AS",
                        NcptsCode: "BUNTAKA",
                    },
                ],
            },
            ContactEmail: "ALVIAGASKILL@YAHOO.COM",
            ContactName: {
                LastName: "GASKILL",
                Suffix: "JR",
                MiddleName: "",
                Additional: "",
                FirstName: "ALVIA",
            },
        },
        CountyValidValues: [
            {
                DisplayValue: "FORSYTH",
                NcptsCode: "CNTYFORSY",
            },
            {
                DisplayValue: "TRANSYLVANIA",
                NcptsCode: "CNTYTRANS",
            },
            {
                DisplayValue: "ONSLOW",
                NcptsCode: "CNTYONSLO",
            },
            {
                DisplayValue: "POLK",
                NcptsCode: "CNTYPOLK",
            },
            {
                DisplayValue: "EDGECOMBE",
                NcptsCode: "CNTYEDGEC",
            },
            {
                DisplayValue: "VANCE",
                NcptsCode: "CNTYVANCE",
            },
            {
                DisplayValue: "CUMBERLAND",
                NcptsCode: "CNTYCUMBE",
            },
            {
                DisplayValue: "CHOWAN",
                NcptsCode: "CNTYCHOWA",
            },
            {
                DisplayValue: "DURHAM",
                NcptsCode: "CNTYDURHA",
            },
            {
                DisplayValue: "ROBESON",
                NcptsCode: "CNTYROBES",
            },
            {
                DisplayValue: "ASHE",
                NcptsCode: "CNTYASHE",
            },
            {
                DisplayValue: "TYRRELL",
                NcptsCode: "CNTYTYRRE",
            },
            {
                DisplayValue: "FRANKLIN",
                NcptsCode: "CNTYFRANK",
            },
            {
                DisplayValue: "CURRITUCK",
                NcptsCode: "CNTYCURRI",
            },
            {
                DisplayValue: "GREENE",
                NcptsCode: "CNTYGREEN",
            },
            {
                DisplayValue: "ALAMANCE",
                NcptsCode: "CNTYALAMA",
            },
            {
                DisplayValue: "BRUNSWICK",
                NcptsCode: "CNTYBRUNS",
            },
            {
                DisplayValue: "UNION",
                NcptsCode: "CNTYUNION",
            },
            {
                DisplayValue: "MONTGOMERY",
                NcptsCode: "CNTYMONTG",
            },
            {
                DisplayValue: "PASQUOTAWK",
                NcptsCode: "CNTYPASQU",
            },
            {
                DisplayValue: "GRAHAM",
                NcptsCode: "CNTYGRAHA",
            },
            {
                DisplayValue: "WAKE",
                NcptsCode: "CNTYWAKE",
            },
            {
                DisplayValue: "AVERY",
                NcptsCode: "CNTYAVERY",
            },
            {
                DisplayValue: "YADKIN",
                NcptsCode: "CNTYYADKI",
            },
            {
                DisplayValue: "WASHINGTON",
                NcptsCode: "CNTYWASHI",
            },
            {
                DisplayValue: "DUPLIN",
                NcptsCode: "CNTYDUPLI",
            },
            {
                DisplayValue: "LINCOLN",
                NcptsCode: "CNTYLINCO",
            },
            {
                DisplayValue: "GUILFORD",
                NcptsCode: "CNTYGUILF",
            },
            {
                DisplayValue: "WILSON",
                NcptsCode: "CNTYWILSO",
            },
            {
                DisplayValue: "MECKLENBURG",
                NcptsCode: "CNTYMECKL",
            },
            {
                DisplayValue: "ALEXANDER",
                NcptsCode: "CNTYALEXA",
            },
            {
                DisplayValue: "NEW HANOVER",
                NcptsCode: "CNTYNEW H",
            },
            {
                DisplayValue: "SURRY",
                NcptsCode: "CNTYSURRY",
            },
            {
                DisplayValue: "DARE",
                NcptsCode: "CNTYDARE",
            },
            {
                DisplayValue: "HENDERSON",
                NcptsCode: "CNTYHENDE",
            },
            {
                DisplayValue: "WILKES",
                NcptsCode: "CNTYWILKE",
            },
            {
                DisplayValue: "ANSON",
                NcptsCode: "CNTYANSON",
            },
            {
                DisplayValue: "STANLEY",
                NcptsCode: "CNTYSTANL",
            },
            {
                DisplayValue: "GASTON",
                NcptsCode: "CNTYGASTO",
            },
            {
                DisplayValue: "RUTHERFORD",
                NcptsCode: "CNTYRUTHE",
            },
            {
                DisplayValue: "CABARRUS",
                NcptsCode: "CNTYCABAR",
            },
            {
                DisplayValue: "SCOTLAND",
                NcptsCode: "CNTYSCOTL",
            },
            {
                DisplayValue: "NASH",
                NcptsCode: "CNTYNASH",
            },
            {
                DisplayValue: "WARREN",
                NcptsCode: "CNTYWARRE",
            },
            {
                DisplayValue: "CHEROKEE",
                NcptsCode: "CNTYCHERO",
            },
            {
                DisplayValue: "ALLEGANY",
                NcptsCode: "CNTYALLEG",
            },
            {
                DisplayValue: "WATAUGA",
                NcptsCode: "CNTYWATAU",
            },
            {
                DisplayValue: "RANDOLPH",
                NcptsCode: "CNTYRANDO",
            },
            {
                DisplayValue: "GATES",
                NcptsCode: "CNTYGATES",
            },
            {
                DisplayValue: "CRAVEN",
                NcptsCode: "CNTYCRAVE",
            },
            {
                DisplayValue: "CALDWELL",
                NcptsCode: "CNTYCALDW",
            },
            {
                DisplayValue: "WAYNE",
                NcptsCode: "CNTYWAYNE",
            },
            {
                DisplayValue: "ORANGE",
                NcptsCode: "CNTYORANG",
            },
            {
                DisplayValue: "BERTIE",
                NcptsCode: "CNTYBERTI",
            },
            {
                DisplayValue: "HAYWOOD",
                NcptsCode: "CNTYHAYWO",
            },
            {
                DisplayValue: "PENDER",
                NcptsCode: "CNTYPENDE",
            },
            {
                DisplayValue: "HALIFAX",
                NcptsCode: "CNTYHALIF",
            },
            {
                DisplayValue: "MOORE",
                NcptsCode: "CNTYMOORE",
            },
            {
                DisplayValue: "CLEVELAND",
                NcptsCode: "CNTYCLEVE",
            },
            {
                DisplayValue: "MACON",
                NcptsCode: "CNTYMACON",
            },
            {
                DisplayValue: "YANCEY",
                NcptsCode: "CNTYYANCE",
            },
            {
                DisplayValue: "NORTHAMPTON",
                NcptsCode: "CNTYNORTH",
            },
            {
                DisplayValue: "GRANVILLE",
                NcptsCode: "CNTYGRANV",
            },
            {
                DisplayValue: "DAVIE",
                NcptsCode: "CNTYDAVIE",
            },
            {
                DisplayValue: "RICHMOND",
                NcptsCode: "CNTYRICHM",
            },
            {
                DisplayValue: "CASWELL",
                NcptsCode: "CNTYCASWE",
            },
            {
                DisplayValue: "JONES",
                NcptsCode: "CNTYJONES",
            },
            {
                DisplayValue: "JACKSON",
                NcptsCode: "CNTYJACKS",
            },
            {
                DisplayValue: "ROCKINGHAM",
                NcptsCode: "CNTYROCKI",
            },
            {
                DisplayValue: "PAMLICO",
                NcptsCode: "CNTYPAMLI",
            },
            {
                DisplayValue: "HOKE",
                NcptsCode: "CNTYHOKE",
            },
            {
                DisplayValue: "LENOIR",
                NcptsCode: "CNTYLENOI",
            },
            {
                DisplayValue: "CARTERET",
                NcptsCode: "CNTYCARTE",
            },
            {
                DisplayValue: "HERTFORD",
                NcptsCode: "CNTYHERTF",
            },
            {
                DisplayValue: "JOHNSTON",
                NcptsCode: "CNTYJOHNS",
            },
            {
                DisplayValue: "ROWAN",
                NcptsCode: "CNTYROWAN",
            },
            {
                DisplayValue: "MARTIN",
                NcptsCode: "CNTYMARTI",
            },
            {
                DisplayValue: "DAVIDSON",
                NcptsCode: "CNTYDAVID",
            },
            {
                DisplayValue: "CHATHAM",
                NcptsCode: "CNTYCHATH",
            },
            {
                DisplayValue: "PERQUIMANS",
                NcptsCode: "CNTYPERQU",
            },
            {
                DisplayValue: "PITT",
                NcptsCode: "CNTYPITT",
            },
            {
                DisplayValue: "CAMDEN",
                NcptsCode: "CNTYCAMDE",
            },
            {
                DisplayValue: "BURKE",
                NcptsCode: "CNTYBURKE",
            },
            {
                DisplayValue: "PERSON",
                NcptsCode: "CNTYPERSO",
            },
            {
                DisplayValue: "COLUMBUS",
                NcptsCode: "CNTYCOLUM",
            },
            {
                DisplayValue: "LEE",
                NcptsCode: "CNTYLEE",
            },
            {
                DisplayValue: "BLADEN",
                NcptsCode: "CNTYBLADE",
            },
            {
                DisplayValue: "CLAY",
                NcptsCode: "CNTYCLAY",
            },
            {
                DisplayValue: "BUNCOMBE",
                NcptsCode: "CNTYBUNCO",
            },
            {
                DisplayValue: "MITCHELL",
                NcptsCode: "CNTYMITCH",
            },
            {
                DisplayValue: "MCDOWELL",
                NcptsCode: "CNTYMCDOW",
            },
            {
                DisplayValue: "BEAUFORT",
                NcptsCode: "CNTYBEAUF",
            },
            {
                DisplayValue: "SWAIN",
                NcptsCode: "CNTYSWAIN",
            },
            {
                DisplayValue: "IREDELL",
                NcptsCode: "CNTYIREDE",
            },
            {
                DisplayValue: "CATAWBA",
                NcptsCode: "CNTYCATAW",
            },
            {
                DisplayValue: "STOKES",
                NcptsCode: "CNTYSTOKE",
            },
            {
                DisplayValue: "HYDE",
                NcptsCode: "CNTYHYDE",
            },
            {
                DisplayValue: "SAMPSON",
                NcptsCode: "CNTYSAMPS",
            },
            {
                DisplayValue: "HARNETT",
                NcptsCode: "CNTYHARNE",
            },
        ],
        AbstractInfo: {
            Status: "ABSSMAIL",
            ListingDate: "",
            ExtensionRequestDate: "",
            ExtensionDate: "",
        },
        PropertyInformation: {
            ScheduleC: [],
            ScheduleE: [],
            ScheduleB: {
                ManufacturedHomeValidValues: {
                    Parks: [
                        null,
                        "145 : Century Mobile Home Park",
                        "146 : GLENWOOD MOBILE HOME PARK",
                        "147 : Gorman Village Mobile Home Park",
                        "148 : LITTLE BITS MOBILE HOME PARK",
                        "149 : MINERAL SPRINGS MOBILE HOME PARK LLC",
                        "151 : PARRISH MHP",
                        "150 : WHISPERING PINES MHP INC",
                    ],
                },
                VehicleValidValues: {
                    VehicleSubtypes: [
                        {
                            VehicleSubtypeNCPTSCode: "VEHSMULTIYEAR",
                            VehicleSubtype: "Multi-Year",
                        },
                        {
                            VehicleSubtypeNCPTSCode: "VEHSGAP",
                            VehicleSubtype: "GAP",
                        },
                        {
                            VehicleSubtypeNCPTSCode: "VEHSUV",
                            VehicleSubtype: "Unregistered",
                        },
                        {
                            VehicleSubtypeNCPTSCode: "VEHSIRP",
                            VehicleSubtype: "IRP",
                        },
                    ],
                    BodyTypes: [
                        {
                            DisplayValue: null,
                            NcptsCode: null,
                        },
                        {
                            DisplayValue: "HORSE/CATTLE TRAILER",
                            NcptsCode: "BODTAT",
                        },
                        {
                            DisplayValue: "ROADSTER",
                            NcptsCode: "BODTRD",
                        },
                        {
                            DisplayValue: "4 DOOR SEDAN",
                            NcptsCode: "BODT4S",
                        },
                        {
                            DisplayValue: "TRASH COMPACTOR",
                            NcptsCode: "BODTTC",
                        },
                        {
                            DisplayValue: "UTILITY TRAILER",
                            NcptsCode: "BODTUT",
                        },
                        {
                            DisplayValue: "TANDEM DUMP",
                            NcptsCode: "BODTTD",
                        },
                        {
                            DisplayValue: "MOTOR HOME",
                            NcptsCode: "BODTMH",
                        },
                        {
                            DisplayValue: "AMBULANCE",
                            NcptsCode: "BODTAM",
                        },
                        {
                            DisplayValue: "STATION WAGON",
                            NcptsCode: "BODTSW",
                        },
                        {
                            DisplayValue: "UNKNOWN",
                            NcptsCode: "BODTUK",
                        },
                        {
                            DisplayValue: "HORSE TRAILER",
                            NcptsCode: "BODTHT",
                        },
                        {
                            DisplayValue: "TRAVEL TRAILER",
                            NcptsCode: "BODTTT",
                        },
                        {
                            DisplayValue: "STAKE/FLAT",
                            NcptsCode: "BODTSF",
                        },
                        {
                            DisplayValue: "ARMORED CAR",
                            NcptsCode: "BODTAR",
                        },
                        {
                            DisplayValue: "DUMP BODY",
                            NcptsCode: "BODTDP",
                        },
                        {
                            DisplayValue: "HOUSE BOAT",
                            NcptsCode: "BODTHB",
                        },
                        {
                            DisplayValue: "CONVERTIBLE",
                            NcptsCode: "BODTCN",
                        },
                        {
                            DisplayValue: "3 DOOR SEDAN  (Hatchback)",
                            NcptsCode: "BODT3S",
                        },
                        {
                            DisplayValue: "MV",
                            NcptsCode: "BODTMV",
                        },
                        {
                            DisplayValue: "LIFT GATE",
                            NcptsCode: "BODTLG",
                        },
                        {
                            DisplayValue: "BUCKET CRANE",
                            NcptsCode: "BODTCB",
                        },
                        {
                            DisplayValue: "GLIDER",
                            NcptsCode: "BODTGL",
                        },
                        {
                            DisplayValue: "Tent Camper",
                            NcptsCode: "BODTUVTC",
                        },
                        {
                            DisplayValue: "TRAILER",
                            NcptsCode: "BODTTL",
                        },
                        {
                            DisplayValue: "WRECKER MINI",
                            NcptsCode: "BODTWM",
                        },
                        {
                            DisplayValue: "BOAT TRAILER",
                            NcptsCode: "BODTBT",
                        },
                        {
                            DisplayValue: "3 DOOR",
                            NcptsCode: "BODT3D",
                        },
                        {
                            DisplayValue: "CONCRETE MIXER",
                            NcptsCode: "BODTCM",
                        },
                        {
                            DisplayValue: "2 DOOR HARDTOP",
                            NcptsCode: "BODT2H",
                        },
                        {
                            DisplayValue: "OTHER",
                            NcptsCode: "BODTOTHER",
                        },
                        {
                            DisplayValue: "2 DOOR HARDTOP",
                            NcptsCode: "BODT2T",
                        },
                        {
                            DisplayValue: "Hot Air Balloon",
                            NcptsCode: "BODTACHB",
                        },
                        {
                            DisplayValue: "UTILITY TRAILER",
                            NcptsCode: "BODTUL",
                        },
                        {
                            DisplayValue: "MOTORCYCLE",
                            NcptsCode: "BODTMC",
                        },
                        {
                            DisplayValue: "PI",
                            NcptsCode: "BODTPI",
                        },
                        {
                            DisplayValue: "EXPERIMENTAL",
                            NcptsCode: "BODTEX",
                        },
                        {
                            DisplayValue: "Empty",
                            NcptsCode: "BODTEMPTY",
                        },
                        {
                            DisplayValue: "WRECKER",
                            NcptsCode: "BODTWK",
                        },
                        {
                            DisplayValue: "LIMOUSINE",
                            NcptsCode: "BODTLM",
                        },
                        {
                            DisplayValue: "UY",
                            NcptsCode: "BODTUY",
                        },
                        {
                            DisplayValue: "CATTLE/HORSE TRAILER",
                            NcptsCode: "BODTCH",
                        },
                        {
                            DisplayValue: "5 DOOR SEDAN  (Hatchback)",
                            NcptsCode: "BODT5S",
                        },
                        {
                            DisplayValue: "CAMPING TRAILER",
                            NcptsCode: "BODTCT",
                        },
                        {
                            DisplayValue: "HOUSE CAR",
                            NcptsCode: "BODTHC",
                        },
                        {
                            DisplayValue: "RECREATIONAL VEHICLE",
                            NcptsCode: "BODTRV",
                        },
                        {
                            DisplayValue: "WHEEL CHAIR",
                            NcptsCode: "BODTWC",
                        },
                        {
                            DisplayValue: "BOTTLE CARRIER",
                            NcptsCode: "BODTBC",
                        },
                        {
                            DisplayValue: "PERSONAL UTILITY VEHICLE",
                            NcptsCode: "BODTMP",
                        },
                        {
                            DisplayValue: "2 DOOR",
                            NcptsCode: "BODT2D",
                        },
                        {
                            DisplayValue: "VAN",
                            NcptsCode: "BODTVN",
                        },
                        {
                            DisplayValue: "PR",
                            NcptsCode: "BODTPR",
                        },
                        {
                            DisplayValue: "SINGLE SECTION",
                            NcptsCode: "BODTSS",
                        },
                        {
                            DisplayValue: "MULTI SECTION",
                            NcptsCode: "BODTMS",
                        },
                        {
                            DisplayValue: "BUS",
                            NcptsCode: "BODTBU",
                        },
                        {
                            DisplayValue: "TOURING CAR",
                            NcptsCode: "BODTTG",
                        },
                        {
                            DisplayValue: "HEARSE",
                            NcptsCode: "BODTHR",
                        },
                        {
                            DisplayValue: "BOAT MOTOR",
                            NcptsCode: "BODTBM",
                        },
                        {
                            DisplayValue: "BY",
                            NcptsCode: "BODTBY",
                        },
                        {
                            DisplayValue: "TRUCK",
                            NcptsCode: "BODTTK",
                        },
                        {
                            DisplayValue: "PROPANE TANKER",
                            NcptsCode: "BODTPT",
                        },
                        {
                            DisplayValue: "SEDAN",
                            NcptsCode: "BODTSD",
                        },
                        {
                            DisplayValue: "SPECIAL MOBILE EQUIPMENT",
                            NcptsCode: "BODTSP",
                        },
                        {
                            DisplayValue: "SEMI TRAILER",
                            NcptsCode: "BODTST",
                        },
                        {
                            DisplayValue: "HELICOPTER",
                            NcptsCode: "BODTHE",
                        },
                        {
                            DisplayValue: "TANKER",
                            NcptsCode: "BODTTN",
                        },
                        {
                            DisplayValue: "NO ADDED VALUE",
                            NcptsCode: "BODTNA",
                        },
                        {
                            DisplayValue: "4 DOOR HARDTOP",
                            NcptsCode: "BODT4H",
                        },
                        {
                            DisplayValue: "REFRIGERATED",
                            NcptsCode: "BODTRF",
                        },
                        {
                            DisplayValue: "Low Speed",
                            NcptsCode: "BODTLS",
                        },
                        {
                            DisplayValue: "WRECKER LARGE",
                            NcptsCode: "BODTWL",
                        },
                        {
                            DisplayValue: "WRECKER TILT",
                            NcptsCode: "BODTWT",
                        },
                        {
                            DisplayValue: "LIGHT TRUCK",
                            NcptsCode: "BODTLT",
                        },
                        {
                            DisplayValue: "4 DOOR HARDTOP",
                            NcptsCode: "BODT4T",
                        },
                        {
                            DisplayValue: "2 DOOR SEDAN",
                            NcptsCode: "BODT2S",
                        },
                        {
                            DisplayValue: "PLATE GLASS",
                            NcptsCode: "BODTPG",
                        },
                        {
                            DisplayValue: "COUPE",
                            NcptsCode: "BODTCP",
                        },
                        {
                            DisplayValue: "4X",
                            NcptsCode: "BODT4X",
                        },
                        {
                            DisplayValue: "GOLF CART",
                            NcptsCode: "BODTGC",
                        },
                        {
                            DisplayValue: "JEEP",
                            NcptsCode: "BODTJP",
                        },
                        {
                            DisplayValue: "4 DOOR",
                            NcptsCode: "BODT4D",
                        },
                        {
                            DisplayValue: "TRUCK TRACTOR",
                            NcptsCode: "BODTTR",
                        },
                        {
                            DisplayValue: "AIRPLANE",
                            NcptsCode: "BODTAC",
                        },
                    ],
                },
                UnregisteredVehicles: [],
                Watercraft: [],
                AircraftValidValues: {
                    Airports: [null, "153 : LAKE RIDGE AERO PARK"],
                },
                Aircraft: [],
                ManufacturedHomes: [],
                WatercraftValidValues: {
                    Marinas: [null, "152 : ROLLINGVIEW MARINA"],
                    EngineTypes: [
                        {
                            DisplayValue: null,
                            NcptsCode: null,
                        },
                        {
                            DisplayValue: "Jetdrive engine; not a jetski",
                            NcptsCode: "WPCDJS",
                        },
                        {
                            DisplayValue: "Other",
                            NcptsCode: "WPCDOT",
                        },
                        {
                            DisplayValue: "Inboard/Outboard",
                            NcptsCode: "WPCDIO",
                        },
                        {
                            DisplayValue: "Inboard",
                            NcptsCode: "WPCDIB",
                        },
                        {
                            DisplayValue: "Sailboat with auxiliary outboard",
                            NcptsCode: "WPCDSO",
                        },
                        {
                            DisplayValue: "Outboard",
                            NcptsCode: "WPCDOB",
                        },
                        {
                            DisplayValue: "Sailboat with auxiliary inboard",
                            NcptsCode: "WPCDSI",
                        },
                        {
                            DisplayValue: "Sailboat Only",
                            NcptsCode: "WPCDSB",
                        },
                    ],
                },
            },
            ScheduleA: {
                Group1: [
                    {
                        GroupDescription: "MACHINERY AND EQUIPMENT",
                        PropertyDescription: "MACHINERY AND EQUIPMENT",
                        Years: [
                            {
                                Additions: "0",
                                PriorYearCost: "0",
                                Year: "2024",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Additions: "0",
                                PriorYearCost: "0",
                                Year: "2023",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Additions: "0",
                                PriorYearCost: "0",
                                Year: "2022",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Additions: "0",
                                PriorYearCost: "0",
                                Year: "2021",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Additions: "0",
                                PriorYearCost: "0",
                                Year: "2020",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Additions: "0",
                                PriorYearCost: "0",
                                Year: "2019",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Additions: "0",
                                PriorYearCost: "0",
                                Year: "2018",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Additions: "0",
                                PriorYearCost: "0",
                                Year: "2017",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Additions: "0",
                                PriorYearCost: "5039",
                                Year: "PRIOR",
                                Deletions: "0",
                                CurrentYearCost: "5039",
                            },
                        ],
                        GroupNumber: "1",
                        Schedule: {
                            CategoryNumber: "3066",
                            CategoryDescription: "Schedule-3066",
                            ScheduleName: "A10",
                        },
                        VoidEffectiveDate: "",
                        PropertyPK: "2440545",
                    },
                ],
                Group2: [],
                Group5: [],
                Group6: [],
                Group3: [
                    {
                        GroupDescription: "OFFICE FURNITURE",
                        PropertyDescription: "OFFICE FURNITURE",
                        Years: [
                            {
                                Additions: "0",
                                PriorYearCost: "0",
                                Year: "2024",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Additions: "0",
                                PriorYearCost: "0",
                                Year: "2023",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Additions: "0",
                                PriorYearCost: "0",
                                Year: "2022",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Additions: "0",
                                PriorYearCost: "0",
                                Year: "2021",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Additions: "0",
                                PriorYearCost: "0",
                                Year: "2020",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Additions: "0",
                                PriorYearCost: "0",
                                Year: "2019",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Additions: "0",
                                PriorYearCost: "0",
                                Year: "2018",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Additions: "0",
                                PriorYearCost: "1081",
                                Year: "PRIOR",
                                Deletions: "0",
                                CurrentYearCost: "1081",
                            },
                        ],
                        GroupNumber: "3",
                        Schedule: {
                            CategoryNumber: "3050",
                            CategoryDescription: "Schedule-3050",
                            ScheduleName: "K10",
                        },
                        VoidEffectiveDate: "",
                        PropertyPK: "2440546",
                    },
                ],
                Group4: [
                    {
                        GroupDescription: "COMPUTERS & SOFTWARE",
                        PropertyDescription: "COMPUTERS & SOFTWARE",
                        Years: [
                            {
                                Additions: "0",
                                PriorYearCost: "0",
                                Year: "2023",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Additions: "0",
                                PriorYearCost: "0",
                                Year: "2022",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Additions: "0",
                                PriorYearCost: "0",
                                Year: "2021",
                                Deletions: "0",
                                CurrentYearCost: "0",
                            },
                            {
                                Additions: "0",
                                PriorYearCost: "16",
                                Year: "2020",
                                Deletions: "0",
                                CurrentYearCost: "16",
                            },
                            {
                                Additions: "0",
                                PriorYearCost: "358",
                                Year: "PRIOR",
                                Deletions: "0",
                                CurrentYearCost: "358",
                            },
                        ],
                        GroupNumber: "4",
                        Schedule: {
                            CategoryNumber: "2946",
                            CategoryDescription: "Schedule-2946",
                            ScheduleName: "U05",
                        },
                        VoidEffectiveDate: "",
                        PropertyPK: "2440547",
                    },
                ],
                Group7: [
                    {
                        GroupDescription: "Supplies",
                        PropertyDescription: "SUPPLIES",
                        PriorYearValue: "13",
                        VoidEffectiveDate: "",
                        AppraisedValue: "13",
                        PropertyPK: "2440548",
                    },
                ],
                Group8: [],
            },
            ScheduleEValidValues: {
                FuelCodes: [
                    {
                        DisplayValue: null,
                        NcptsCode: null,
                    },
                    {
                        DisplayValue: "Gas",
                        NcptsCode: "WCFTGAS",
                    },
                    {
                        DisplayValue: "Other",
                        NcptsCode: "WCFTOTH",
                    },
                    {
                        DisplayValue: "Diesel",
                        NcptsCode: "WCFTDSL",
                    },
                    {
                        DisplayValue: "Electric",
                        NcptsCode: "WCFTELC",
                    },
                ],
            },
            Other: {
                MISC: [],
                EXEMPT: [],
                INDEX: [],
            },
        },
    },
    FormUUID: "9afce542-e0c5-4a2d-885b-7c8d72fca171-ncpts",
    AbstractNumber: "0000015640202520250000",
};
