"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const boatPurchaser = {
    type: "object",
    required: ["name", "street", "city", "state", "zipCode", "salePrice", "date"],
    properties: {
        name: {
            $ref: "#/$defs/nonEmptyString",
        },
        street: {
            $ref: "#/$defs/nonEmptyString",
        },
        city: {
            $ref: "#/$defs/nonEmptyString",
        },
        state: {
            $ref: "#/$defs/nonEmptyString",
        },
        zipCode: {
            type: "string",
            format: "zipCode",
        },
        salePrice: {
            type: "number",
            minimum: 1,
        },
        date: {
            type: "string",
            format: "calendar",
        },
        description: {
            type: "string",
        },
    },
};
exports.default = boatPurchaser;
