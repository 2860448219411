import React, { FC, useMemo } from "react";
import { useQuery } from "@apollo/client";

import ViewExtension from "./ViewExtension";
import LoadingPage from "components/LoadingPage";
import { useJurisdictionContext } from "@taxscribe/ui";
import {
  GET_NEW_FORM_FOR_BUSINESS,
  NewFormForBusinessResults,
} from "./queries";
import useFilteredFormData from "hooks/useCleansedFormData";
import { Metadata } from "@taxscribe/form";

export interface NewFormForBusinessProps {
  accountNumberPrimary: string;
  metadata: Metadata;
}

const NewFormForBusiness: FC<NewFormForBusinessProps> = ({
  metadata,
  accountNumberPrimary,
}) => {
  const { jurisdictionId } = useJurisdictionContext();
  const { data, loading } = useQuery<NewFormForBusinessResults>(
    GET_NEW_FORM_FOR_BUSINESS,
    {
      variables: {
        formType: "Extension",
        accountNumberPrimary,
        jurisdictionId,
      },
    },
  );

  const filteredFormData = useFilteredFormData(data?.ncptsFormData?.data);

  const completeMetadata = useMemo(() => {
    return {
      ...metadata,
      source: "Jurisdiction",
    };
  }, [metadata]);

  if (loading || !filteredFormData) {
    return <LoadingPage />;
  }

  return (
    <ViewExtension
      metadata={completeMetadata}
      initialState={filteredFormData}
    />
  );
};

export default NewFormForBusiness;
