"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ncE536Data = void 0;
const counties = new Array(100).fill(null).map((_, i) => ({
    twoPercentTax: 0,
    twoAndAQuarterPercentTax: 0,
    transitTax: 0,
}));
exports.ncE536Data = {
    counties,
    foodTax: 0,
};
