"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bppSampleMerged = void 0;
const bppSample11_10_2023_1 = require("./bppSample11-10-2023");
const bppSample11_14_2024_1 = require("./bppSample11-14-2024");
// 11-10-2023 and 11-14-2024 manually combined to create a type that suffices
// for either potential.
exports.bppSampleMerged = Object.assign(Object.assign({}, bppSample11_14_2024_1.bppSample11_14_2024), { Value: Object.assign(Object.assign({}, bppSample11_14_2024_1.bppSample11_14_2024.Value), { ContactInfo: Object.assign(Object.assign({}, bppSample11_14_2024_1.bppSample11_14_2024.Value.ContactInfo), { ContactName: Object.assign(Object.assign({}, bppSample11_10_2023_1.bppSample11_10_2023.Value.ContactInfo.ContactName), bppSample11_14_2024_1.bppSample11_14_2024.Value.ContactInfo.ContactName) }), OwnershipInformation: Object.assign(Object.assign({}, bppSample11_14_2024_1.bppSample11_14_2024.Value.OwnershipInformation), { Owners: [
                Object.assign(Object.assign({}, bppSample11_10_2023_1.bppSample11_10_2023.Value.OwnershipInformation.Owners[0]), bppSample11_14_2024_1.bppSample11_14_2024.Value.OwnershipInformation.Owners[0]),
            ] }), PropertyInformation: Object.assign(Object.assign({}, bppSample11_14_2024_1.bppSample11_14_2024.Value.PropertyInformation), { ScheduleA: Object.assign(Object.assign({}, bppSample11_14_2024_1.bppSample11_14_2024.Value.PropertyInformation.ScheduleA), { Group2: bppSample11_10_2023_1.bppSample11_10_2023.Value.PropertyInformation.ScheduleA.Group2, Group5: bppSample11_10_2023_1.bppSample11_10_2023.Value.PropertyInformation.ScheduleA.Group5, Group6: bppSample11_10_2023_1.bppSample11_10_2023.Value.PropertyInformation.ScheduleA.Group6, Group8: bppSample11_10_2023_1.bppSample11_10_2023.Value.PropertyInformation.ScheduleA.Group8 }), ScheduleB: Object.assign(Object.assign({}, bppSample11_14_2024_1.bppSample11_14_2024.Value.PropertyInformation.ScheduleB), { Aircraft: bppSample11_10_2023_1.bppSample11_10_2023.Value.PropertyInformation.ScheduleB.Aircraft, Watercraft: bppSample11_10_2023_1.bppSample11_10_2023.Value.PropertyInformation.ScheduleB.Watercraft, ManufacturedHomes: bppSample11_10_2023_1.bppSample11_10_2023.Value.PropertyInformation.ScheduleB.ManufacturedHomes, UnregisteredVehicles: bppSample11_10_2023_1.bppSample11_10_2023.Value.PropertyInformation.ScheduleB.UnregisteredVehicles }), ScheduleE: Object.assign({}, bppSample11_10_2023_1.bppSample11_10_2023.Value.PropertyInformation.ScheduleE) }) }) });
