import React, { FC } from "react";
import Taxroll from "@taxscribe/taxroll";
import { useFormGroupFilingYear } from "@taxscribe/ui";

import config from "config/hostnames";
import PageSpinner from "components/shared/PageSpinner";

const newFormTooltip = "For New Businesses Only";
const TaxrollSearch: FC = () => {
  const [err, { called, loading, filingYear }] = useFormGroupFilingYear({
    formGroup: config.formGroup,
    fallbackFilingYear: new Date().getFullYear(),
  });

  if (err) {
    console.error(err);
  }

  if (!called || loading) {
    return <PageSpinner />;
  }

  return (
    <Taxroll
      filingYear={filingYear}
      formGroup={config.formGroup}
      newFormTooltip={newFormTooltip}
    />
  );
};

export default TaxrollSearch;
