"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.wrapWithErrorHandling = exports.encounteredError = exports.isErrorWrapper = void 0;
;
;
const isErrorWrapper = (obj) => {
    if (!obj || typeof obj !== "object")
        return false;
    const keys = Object.keys(obj);
    return keys.includes("data") && keys.includes("error");
};
exports.isErrorWrapper = isErrorWrapper;
const encounteredError = (errorWrapper) => {
    return errorWrapper.error !== null;
};
exports.encounteredError = encounteredError;
const returnAsWrappedError = (data) => {
    if ((0, exports.isErrorWrapper)(data))
        return data;
    return { data, error: null };
};
const wrapWithErrorHandling = (fn, logFn = console.error) => (...args) => {
    try {
        const results = fn(...args);
        if (results instanceof Promise) {
            return results
                .then((res) => returnAsWrappedError(res))
                .catch((error) => {
                logFn(error);
                return { data: null, error };
            });
        }
        return returnAsWrappedError(results);
    }
    catch (err) {
        const error = err;
        logFn(error);
        return { data: null, error };
    }
};
exports.wrapWithErrorHandling = wrapWithErrorHandling;
