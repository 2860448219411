"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const rebookingCosts = [
    {
        required: ["rebookingCosts"],
        properties: {
            rebookingCosts: {
                enum: [false],
            },
        },
    },
    {
        required: ["rebookingCosts", "rebookingCostsList"],
        properties: {
            rebookingCosts: {
                enum: [true],
            },
            rebookingCostsList: {
                type: "array",
                minItems: 1,
                title: "The rebookingCostsList Schema",
                items: {
                    type: "object",
                    properties: {
                        description: {
                            $ref: "#/$defs/nonEmptyString",
                        },
                        newYear: {
                            type: "string",
                            format: "year",
                        },
                        section: {
                            $ref: "#/$defs/nonEmptyString",
                        },
                        acquisitionYear: {
                            type: "string",
                            format: "year",
                        },
                        explanation: {
                            $ref: "#/$defs/nonEmptyString",
                        },
                        amount: {
                            type: "number",
                        },
                    },
                },
            },
        },
    },
];
exports.default = rebookingCosts;
