import React, { FC } from "react";
import { Row, Col } from "react-bootstrap";

const EmailHelp: FC = (props) => {
  return (
    <Row>
      <Col className="text-center">
        <p className="mt-3">
          Need help? Email&nbsp;
          <a
            className="text-white"
            href="mailto:hello@taxscribe.com"
          >
            <strong>hello@taxscribe.com</strong>
          </a>
          .
        </p>
      </Col>
    </Row>
  );
};

export default EmailHelp;
